
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Link} from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { Box,Button, Typography  } from '@material-ui/core';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiTableContainer-root': {
			width: '95%'
		},
		'& .MuiTableCell-head': {
			fontWeight: '600'
		}
	},
	table: {
	    minWidth: 650,
	    marginRight: 10 
	  }
}))

  


function TeamMembers() {
  let history = useHistory();
	const classes = useStyles();
  const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
  const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
  const [teamName, setTeamName] = useState(null);
  const [memberlist, setMemberList] = useState([]);
  const classess = {
    'invitememberbutton': true,
    'classes.button': true,
  };

  useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setTeamName(response.teamname)
                  //setTeamId(response.teamid)
                  const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                      body: JSON.stringify({ teamid: teaminfo  })
                  };
                  fetch(APIURL+'team/memberlist', requestOptions)
                      .then(response => response.json())
                      .then(response => {
                            //const result = Object.values(response.members);
                            setMemberList(response.members)

                            //this.state = { response };
                      })
                })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

  function redirect(){
    history.push('/add-member');
  }
  function editRedirect(userId){
    //history.push('/edit-member/'+userId);
    window.location.replace('/edit-member/'+userId);
  }

const columns = [
  { field: 'id', headerName: 'ID', width: 80, hide: true },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'email', headerName: 'Email Address', width: 250 },
  { field: 'permissions', headerName: 'Permissions', width: 350,
      renderCell: (params) =>  (
       <Tooltip title={params.getValue('permissions')} >
        <span>{params.getValue('permissions')}</span>
        </Tooltip>
      ),
   },
  {
    field: 'action',
    headerName: 'Action',
    width: 150,
    sortable: false,
    renderCell: (params) => {
      const onEdit = (e) => {
        e.stopPropagation();
        editRedirect(params.getValue('id')); //window.location.href = '/edit-member/'+params.getValue('id') ;
        //return alert(params.getValue('id'));
      };
      const onDelete = (e) => {
        e.stopPropagation();
        //return alert(params.getValue('id'));
      };

      return <div><Button onClick={onEdit }> <Edit /> </ Button> <Button onClick={onDelete}> <Delete /> </ Button></div>;
    },
  }
  
];


  return (
  	<div>
  	<div className="mainWrapper">
			<TopNav />
			<Box pl={4} pr={4}  pt={4}  pb={3} >
				<Box >
					<Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
						<Link color="inherit" href="/" >
						{teamName}
						</Link>
						<Typography color="textPrimary">
						Team Members
						</Typography>
					</Breadcrumbs>
				</Box>
        <Box>
        <Grid container spacing={0}>
          <Grid style={{border:'1px solid red;'}} item xs={6}>
            <Box>
              <Typography className={'teammemberheading'} variant="h4" gutterBottom>
              Team Members
              </Typography>
            </Box>
          </Grid>
          <Grid style={{border:'1px solid red;'}} item xs={6}>
            <Box display="flex" flexDirection="row-reverse">
            <Button onClick={redirect} variant="contained" className={classess} startIcon={<AddIcon />}>
            Invite Member
            </Button>
          </Box>
          </Grid>
        </Grid>
        </Box>
    				<Box>
    					<div style={{ height: 400, width: '100%' }}>
    				      <DataGrid rows={memberlist} columns={columns} pageSize={5} checkboxSelection  />
    				    </div>
    				</Box>
	      	</Box>
		</div>
	</div>
  	
  );
}

export default TeamMembers;
