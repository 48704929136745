
import {Link} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Breadcrumbs } from '@material-ui/core';
import TopNav from './../Common/TopBar/TopNav.js';
import SideBar from './../Common/SideBar/index.js';
import { Box,  Typography  } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTableContainer-root': {
            width: '95%'
        },
        '& .MuiTableCell-head': {
            fontWeight: '600'
        }
    },
    channelList: {
        color: '#000000',
        fontSize: 14,
        lineHeight: 25
    },
}))

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'fullName', headerName: 'Channel name', width: 200 },
  
];

const rows = [
  { id: 1, fullName: 'Channel 1', firstName: 'Channel', age: 35 },
  { id: 2, fullName: 'Channel 2', firstName: 'Channel', age: 35 },
  { id: 3, fullName: 'Channel 3', firstName: 'Channel', age: 35 },
  { id: 4, fullName: 'Channel 4', firstName: 'Channel', age: 35 },
  { id: 5, fullName: 'Channel 5', firstName: 'Channel', age: 35 },
];

function Channel({match}) {
	const classes = useStyles();
  return (
    <div>
        <SideBar />
        <div className="mainWrapper">
            <TopNav />
            <Box pl={5} mt={5} >
                <Box >
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
                        <Link color="inherit" href="/" >
                        Tommy Johanessen
                        </Link>
                        <Typography color="textPrimary">
                        Channels
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Typography variant="h4" gutterBottom>
                Channels
                </Typography>
                <Box>
                    <div style={{ height: 400, width: '95%' }}>
                      <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
                    </div>
                </Box>
            </Box>
        </div>
    </div>
    
  );
}

export default Channel;
