
import {React,useState} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography  } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
	tagLine: {
		
		fontSize: 14,
	},
	nextButton: {
      backgroundColor: '#33344A',
      width: '100px',
      color: '#FFFFFF',
      
    }
}))

 

function CreateTeam() {
	//console.log("Session: " + Session.get("sessionuser"));
	let history = useHistory();
	const classes = useStyles();
	const theme = createMuiTheme({
	typography: {
		fontFamily: 'Normal',

	}});

	const userinfo  = localStorage.getItem('userinfo');
	if(userinfo==null){
		history.push('/logout');
	}
	let userjson = JSON.parse(userinfo);
	//console.log(userjson);

const [state, setState] = useState({
    teamname: ""
  });

  const handleInputChange = event => {
  	if(event.keyCode=='13'){
      postData(event);
    }
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
    
		async function postData(event){
			 event.preventDefault();
			let response = await fetch(APIURL+'team/create',{
				"method": "POST",
				"headers": {
				"Accept": "application/json",
				"Content-type": "application/json"
			},
				"body" : JSON.stringify({
				name: state.teamname,
				userid: userjson[0].id
			})
		});

          const json = await response.json();
          if(json.http_code=='400'){
            jQuery('#alert').show();
            jQuery('#alert .MuiAlert-message').html(json.message);
          } else {
          	jQuery('#alert').hide();
          	jQuery('#successalert').show();
    		jQuery('#successalert .MuiAlert-message').html(json.message);
    		localStorage.setItem('teaminfo',JSON.stringify(json.teaminfo));
  			window.setTimeout(() => {
            	window.location.href = 'channel/'+json.redirect;
          	}, 2000);
          }

      	}

  return (
  	<div>
  		<div className="mainWrapper">
			<TopNav />
			<Box   mt={5}  pl={4} pr={4}  pb={3}  >
			<form onSubmit={postData} method="post">
				<ThemeProvider theme={theme}>
		      	<Typography component="div" >
					<Box fontWeight="fontWeightBold" fontSize="h3.fontSize" m={1}>
					What's the name of your team?
					</Box>
					
					<Box fontWeight="fontWeightRegular" m={1}>
			        This will be the name of your {process.env.REACT_APP_BASE_NAME} team. Choose something that your team will recognize.
			      	</Box>
					<Box>
					<TextField onChange={handleInputChange} name="teamname" id="outlined-basic" className={'invitememberfield'} label="Team Name" variant="outlined" />
					</Box>
					<Box mt={1}>
			      	<Button onClick={postData} variant="contained" className={classes.nextButton}>
			      	Next
			      	</Button>
			      	</Box>
			        
		      	</Typography>
		      	</ThemeProvider>
		      	</form>
		      	<br />
		      	<Alert id="alert" severity="error" style={{display:"none",'width':'400px'}} >
              
              	</Alert>
              	<Alert id="successalert" severity="success" style={{display:"none",'width':'540px'}} >
              
              	</Alert>
	      	</Box>
		</div>
	</div>
  	
  );
}

export default CreateTeam;
