import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    '& .MuiMenu-list': {
      backgroundColor:'#000 !important'
    }
  },
  'MuiMenu-list': {
    backgroundColor: '#000'
  },
  singUpContainer: {
    height: '100%',
    textAlign: "center",
     width:'100%'
  },
  singUpLogo: {
    height: 85,

  },
  singUpHeading: {
    fontSize: 40,
    fontWeight: '900',
    color: '#723FBA'
  },
  singUpTagLine: {
    fontSize: 16,
  },
  textFieldRoot: {
      width: '100%'
    },
    continueButton: {
      backgroundColor: '#723FBA',
      width: '100%',
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 18,
      textTransform: 'none'
    },
    marginAutoItem: {
    margin: '0px auto',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/user/nicknice)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  links: {
    color: '#33344A'
  },
  resendLink: {
    marginTop: '14px',
  position: 'absolute',
  marginLeft: '6px',
  display:'none'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 
function TeamSelection () {

  /*const userinfo  = localStorage.getItem('userinfo'); // {localStorage.getItem('userinfo')=='undefined' ? '' : JSON.parse(localStorage.getItem('userinfo'))} ;
  
  if(userinfo!==null){
    console.log(JSON.parse(userinfo));
    const userjson = JSON.parse(userinfo);
    if(userjson[0].id){
     // window.location.href = '/dashboard';
    }
    //window.location.href = '/channel/general';
  }*/
const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
console.log('userinfo',userinfo);
const classes = useStyles();
//const history = useHistory();


const [team, setTeam] = useState('');
const [defaultChannel, setDefaultChannel] = useState('');
//console.log(age);
const [userTeams, setUserTeams] = useState([]);
const handleChange = (event) => {
  setTeam(event.target.value);
  console.log(event.target.value);
  localStorage.setItem('teaminfo',JSON.stringify(event.target.value));
  console.log(JSON.parse(localStorage.getItem('teaminfo')));
    const trequestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
      body: JSON.stringify({ teamid: event.target.value  })
    };
    fetch(APIURL+'team/defaultchannel', trequestOptions)
      .then(response => response.json())
      .then(response => {
        setDefaultChannel(response.defaultchannel);
    })
  //localStorage.setItem('teaminfo',JSON.stringify(json.teaminfo));
};


  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
      body: JSON.stringify({ userid: userinfo[0].id  })
    };
    fetch(APIURL+'team/listing', requestOptions)
      .then(response => response.json())
      .then(response => {
        setUserTeams(response.teamlist);
    })
  }, []);


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          
          <Box  alignItems="center" className={classes.marginAutoItem}>
            <div className={classes.singUpContainer}>
                <img alt="{process.env.REACT_APP_BASE_NAME} Logo" className={classes.singUpLogo} src="/large.png" />
              <div>
                <h1 className={classes.singUpHeading}>Select team</h1>
              </div>
              <Form id="login-form" className="login-form" method="POST">
              
              <div>
                <Select
                    value={team}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{backgroundColor:'#723FBA', width: '100%', color: '#FFF', padding: '7px', textAlign: 'left', borderRadius: '4px'}}
                  >
                    <MenuItem value="" disabled>Select Team</MenuItem>
                    {userTeams.map((val,key) => {
                    return <MenuItem value={val.teamid}>
                    {val.name}
                    </MenuItem>
                    })}
                    
                  </Select>
              </div>
              <br />
              <div>
                
                
              </div>
              <Button onClick={() => { window.location.href = '/dashboard' }} variant="contained" className={classes.continueButton} >
                Proceed
                </Button>
                <br />
              
              </Form>
              
            </div>
            </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default TeamSelection;