
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import { Breadcrumbs,Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import jQuery from 'jquery'; 
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import Delete from '@material-ui/icons/Delete';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Share from '@material-ui/icons/Share';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import {useHistory,NavLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Twitter, Facebook, Pinterest, Linkedin } from 'react-social-sharing';
import { ToastContainer, toast } from 'react-toastify';
import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { SRLWrapper } from "simple-react-lightbox";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import CropIcon from '@material-ui/icons/Crop';
import EditIcon from '@material-ui/icons/Edit';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import FolderIcon from '@material-ui/icons/Folder';
import Folder from './Folder.js';
  
const useStyles = makeStyles(theme => ({
  root:{
    '& .MuiLinearProgress-root': {
      height:'6px'
    }
  },
  filterSection: {
    height: '23px',
    paddingLeft:'8px',
  },
  breadCrumbBox: {
    width: '78%',
    float: "left",
  },
  channellink: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '1px'
  },
  channelicon: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '4px'
  },
  subBar: {
    height:'60px', 
    borderBottom:'1px solid #D2D2D2'
  },
  uploadBar: {
    height:'90px', 
    borderBottom:'1px solid #D2D2D2'
  },
  statsBar: {
    height:'30px', 
    borderBottom:'1px solid #D2D2D2'
  },
  folderBar: {
    height:'25px', 
    borderBottom:'1px solid #D2D2D2'
  },
  breadCrumb: {
    float: "left",
    fontSize:13,
    lineHeight:'58px',
    color: '#000000',
    fontWeight: 'bold',
      '& p': {
        fontSize:16,
        color: '#16163c'
      },
      '& li': {
        fontSize:16,
        color: '#16163c'
      }
  },
  numberImages:{
    fontWeight: '700',
    fontSize:13,
    color: '#16163c'
  },
  addTeamMateButton: {
    backgroundColor: '#7B83EB',
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 12
      
  },
  dropzone: {
    float: "right",
  },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    draganddrop : {
      fontSize:26,
      color:'#33344A',
    },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  progressbar: {
    
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[900],
    textTransform: 'uppercase',
    width: '25px !important'
  },
  editorTextField: {
    width: '90%',
    padding: '7px',
    border: 'none',
    borderRadius: '3px',
    boxShadow: '0px 0px 2px #FFF',
    outline: 'none'
  },
  editorTextLabel: {
    fontSize: '15px'
  },
  cirProgress: {
    color:'#FFFFFF !important',
    margin: '10px 0 0 10px',
    height: '30px !important',
    width: '30px !important'
  }, 
  moveMenuItem: {
    fontSize:'13px', 
    fontWeight: 'bold',
    '&:hover':{
      backgroundColor:'#464775',
      color: '#FFF'
    }
  }, editorSideBar: {
    marginLeft :'-25px',
    '& li': {
      width: '107px',
      listStyle: 'none',
      marginTop:'10px'
    }, 
    '& button': {
      lineHeight: '28px',
      display: 'flex',
      width: '100%',
      backgroundColor: 'transparent',
      color: '#ffffffd6',
      border: '1px solid #ffffffd6',
      borderRadius: '7px',
      cursor: 'pointer'
    }, 
    '& .active': {
      backgroundColor:'#84848438'
    }
  }, 
  cropRanges:{
    marginLeft :'-40px',
    marginTop :'5px',
    '& li': {
      width: '107px',
      listStyle: 'none',
      marginTop:'10px',
      display: 'inline-block',
      marginRight: '10px'
    }, 
    '& button': {
      lineHeight: '28px',
      width: '100%',
      backgroundColor: 'transparent',
      color: '#ffffffd6',
      border: '1px solid #ffffffd6',
      borderRadius: '7px'
    }
  }, 
  doneButton:{
    backgroundColor: '#FFD843',
    border:'none',
    borderRadius: '18px',
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: '#FFC343',
      cursor: 'pointer' 
    }
  }

}))

function ChannelDetails({match}) {
  //console.log(match);
  const onMouseOverEVent = (divId) => {
      jQuery('#'+divId).show();
  }
   const onMouseOutEVent = (divId) => {
      jQuery('#'+divId).hide();
  }

    const Progress = () => (
      <span><div style={{float: 'left'}}>
      <CircularProgress className={classes.cirProgress} /></div></span>
    );


  var gifloaded = 1;
  const [opend, setOpen] = React.useState(false);
  const [channelName, setChannelName] = React.useState(null);
  const [bucketName, setBucketName] = React.useState(null);
  const [folderName, setFolderName] = React.useState(null);
  const [subFolderName, setSubFolderName] = React.useState(null);
  const [bucketSlugForBread, setbucketSlugForBread] = React.useState(null);
  const [folderSlug, setFolderSlug] = React.useState(null);
  const [subFolderSlug, setSubFolderSlug] = React.useState(null);


  const [channelIDForChildren, setChannelID] = React.useState();
  const theme = useTheme();
  const [showProgress, setShowProgress] = React.useState(false);
  const [showCropProgress, setShowCropProgress] = React.useState(false);
  const [showGifProgress, setShowGifProgress] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [teamId, setTeamId] = useState(null);
  const [teamName, setTeamName] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [currentassetid, setAssetID] = useState(null);
  const [currentasseturl, setAssetUrl] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [assetlisting, setAssetListing] = useState([]);
  const [channellisting, setChannelList] = useState([]);
  const [userAppsListing, setUserAppsList] = useState([]);
  const [directorylisting, setDirectoryList] = useState([]);
  const [bucketSlugForUrl, setBucketSlug] = useState();
  const [state, setState] = useState({
      isPaneTileId: '',
      isPaneOpen: false,
      isPaneOpenLeft: false,
    });
  

     const notify = () => toast.dark("Item moved to trash", {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
     const copynotify = () => toast.dark("Item copied to your channel", {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
     const movenotify = () => toast.success("Item moved to another channel", {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
const handleDialogClickOpen = (asseturl) => {
    setOpen(true);
    setAssetUrl(asseturl);
  };
let history = useHistory();
  const handleDialogClose = () => {
    setOpen(false);
  };

  const userinfo  = JSON.parse(localStorage.getItem('userinfo'));

    useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id  })
        };
        fetch('https://api.digiroom.io/v1/channel/list', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setChannelList(response.channels)
                  //this.state = { response };
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    
    useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ slug: match.params.name, userid: userinfo[0].id  })
        };
        fetch('https://api.digiroom.io/v1/channel/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setChannelName(response.channelDetails[0].name)
                  setBucketName(response.bukcetDetail[0].name)
                  setFolderName(response.folderDetail[0].name)
                  setbucketSlugForBread(response.bukcetDetail[0].slug)
                  setFolderSlug(response.folderDetail[0].slug)
                  setSubFolderName(response.subFolderDetail[0].name)
                  setSubFolderSlug(response.subFolderDetail[0].slug)
                  setChannelID(response.channelDetails[0].id)

                    const requestOptionss = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                        body: JSON.stringify({ userid: userinfo[0].id, bucket: response.channelDetails[0].id  })
                    };
                    fetch('https://api.digiroom.io/v1/directory/list', requestOptionss)
                      .then(response => response.json())
                      .then(response => {
                            setDirectoryList(response.directories)
                            setBucketSlug(response.bucketSlug)
                            //this.state = { response };
                      })
                  //this.state = { response };
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [match.params.name]);


  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
      body: JSON.stringify({ channel: match.params.name, userid: userinfo[0].id  })
    };
    fetch('https://api.digiroom.io/v1/assets/listing', requestOptions)
      .then(response => response.json())
      .then(response => {
        setAssetListing(response.assets);
    })
  }, [match.params.name]);

  useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id })
        };
        fetch('https://api.digiroom.io/v1/team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                setTeamName(response.teamname)
                setTeamId(response.teamid)
                setUserRole(response.userrole)
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);
    //const routevar = <Redirect push to="/channel/tree-house" />
    //console.log('channelIDForChildren: '+channelIDForChildren);
    

    function downloadAsset(assetUrl,assetName){
      //console.log(assetUrl);
        const configg = {
            headers: { 'Access-Control-Allow-Origin' : '*' },
            
        };
        try {
      fetch(assetUrl, configg)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = assetName;
        document.body.appendChild(a);
        a.click();
      })
    } catch(e){
      console.error(e);
    }
      
    }


  

    function deleteAsset(assetId){
      const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ assetid: assetId,userid: userinfo[0].id, channel: match.params.name })
        };
        fetch('https://api.digiroom.io/v1/assets/delete', requestOptions)
        .then(response => response.json())
        .then(response => setAssetListing(response.assets))
        .then(response => notify())
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id  })
        };
        fetch('https://api.digiroom.io/v1/application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  //this.state = { response };
        })
   },[])

   useEffect(() => {
     return history.listen((location) => { 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id  })
        };
        fetch('https://api.digiroom.io/v1/application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  //this.state = { response };
            })
      }) 
   },[history])
   
   useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id  })
        };
        fetch('https://api.digiroom.io/v1/application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  //this.state = { response };
            })
   },[])


  const ITEM_HEIGHT = 36;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEll, setAnchorEll] = React.useState(null);
  const open = Boolean(anchorEl);
  const openl = Boolean(setAnchorEll);
  const handleClick = (event) => {
    //console.log('logged: ',event);
    setAnchorEl(event.currentTarget);
    setAssetID(event.currentTarget.value);
  };

  const openEditor = (event) => {
    jQuery('#grid_previewpan').animate({
            bottom: '0'
        },1000);
  }
  const handleTextFamily = () => {
    const elem = jQuery("#draggable span");
    elem[0].style.setProperty('font-family', jQuery('#text_fontfamily').val(), 'important');


  }
  const handleTextColor = () => {
    jQuery('#draggable').css('color','#'+jQuery('#text_fontcolor').val());

  }
  const handleTextFontSize = () => {
    jQuery('#draggable').css('font-size',jQuery('#text_fontsize').val()+'pt');
    //jQuery('#width').val(jQuery('#preview_img_src').width());

  }
  const handleTextDrag = () => {
    jQuery('#xcoord').val('200');
    jQuery('#ycoord').val('50');
    jQuery('#draggable').css('left','200px')
    jQuery('#draggable').css('top','50px')
    console.log(jQuery('#draggable').css('left'));
    jQuery('#draggable span').text(jQuery('#text_field').val()); 
    jQuery('.ui-widget-content').show();
    jQuery('#width').val(jQuery('#preview_img_src').width());
    jQuery('#height').val(jQuery('#preview_img_src').height());
    jQuery('#url').val(jQuery('#preview_img_src').attr('src'));
  }
  const handleCloses = () => {
    setAnchorEl(null);
  };
  const handleClose = (event) => {
  //console.log(event.currentTarget.dataset.id);
  //console.log(event.currentTarget.value);

      const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ assetid:currentassetid, channelid:event.currentTarget.value, channel:match.params.name })
        };
        fetch('https://api.digiroom.io/v1/assets/move', requestOptions)
        .then(response => response.json())
        .then(response => setAssetListing(response.assets))
        .then(response => setAnchorEl(null))
        .then(response => { //downloadAsset(response.url,response.message)
          movenotify();
        })


    //setAnchorEl(null);
  };

  const submitWatermarkData = (channelID) => {
    const watermarkUrl      = jQuery('#watermark_url').val();
    const originalUrl       = jQuery('#original_url').val();
    const xCoordWatermark   = jQuery('#xcoord_watermark').val();
    const yCoordWatermark   = jQuery('#ycoord_watermark').val();
    const originalWidth     = jQuery('#original_width').val();
    const originalHeight    = jQuery('#original_height').val();
    const watermarkWidth    = jQuery('#watermark_width').val();
    const originalHieght    = jQuery('#watermark_height').val();

    const requestOptions    = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ watermark_url:watermarkUrl, original_url: originalUrl, xcoord_watermark:xCoordWatermark, ycoord_watermark:yCoordWatermark, 
          original_width:originalWidth, original_height:originalHeight, watermark_width:watermarkWidth, watermark_height:originalHieght,
          userid: userinfo[0].id, channelid: channelID, assetid: state.isPaneTileId   })
      };
      fetch('https://api.digiroom.io/v1/editor/watermarkimage', requestOptions)
      .then(response => response.json())
      .then(response => { //downloadAsset(response.url,response.message)
        copynotify();
      })
  }

  const submitTextData  = (channelID) => {
      //console.log(channelID);
      //notify();
      //return;
      const textWidth        = jQuery('#width').val();
      const textHeight       = jQuery('#height').val();
      const textXCoord       = jQuery('#xcoord').val();
      const textYCoord       = jQuery('#ycoord').val();
      const textImgUrl       = jQuery('#url').val();
      const assetTitle       = jQuery('#preview_title').val();
      const textContent      = jQuery('#text_field').val();
      const textFontSize     = jQuery('#text_fontsize').val();
      const textColor        = jQuery('#text_fontcolor').val();
      const textFontFamily   = jQuery('#text_fontfamily').val();
      const requestOptions   = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ width: textWidth, height: textHeight, xcoord: textXCoord, ycoord: textYCoord, url: textImgUrl, content: textContent,
          fontsize: textFontSize, color: textColor, family: textFontFamily, userid: userinfo[0].id, channelid: channelID, assetid: state.isPaneTileId })
      };
      fetch('https://api.digiroom.io/v1/editor/textimage', requestOptions)
      .then(response => response.json())
      .then(response => { //downloadAsset(response.url,response.message)
        copynotify();
      })


    //setAnchorEl(null);
  };

  const submitGifData = (channelID) => {
      setShowGifProgress(true);
      const layername   = jQuery('#layername').val();
      const requestOptions   = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ id:btoa(state.isPaneTileId),layer:btoa(layername), url:btoa(jQuery('#preview_img_src').attr('src')),time:Math.random(), userid: userinfo[0].id, channelid: channelID, assetid: state.isPaneTileId })
      };
      fetch('https://api.digiroom.io/v1/editor/animateimage', requestOptions)
      .then(response => response.json())
      .then(response => { //downloadAsset(response.url,response.message)
        copynotify();
        setShowGifProgress(false);
      })


    //setAnchorEl(null);
  };

  jQuery( function() {
      jQuery('#draggable').hide(); 
      jQuery('#draggable2').hide(); 
      jQuery('#remove_text').click(function(e){
        jQuery('#draggable').hide(); 
      });
      jQuery('#remove_watermark').click(function(e){
        jQuery('#draggable2').hide(); 
      });

    /*jQuery('.add_button').click(function(e){
      jQuery('.ui-widget-content').show();
      jQuery('.ui-widget-content').css('top',150).css('left',250) ;
      jQuery('.text-tools').show(); 
    });*/
   /* jQuery( "#resizeableimage" ).resizable({
      containment: '#image_container',
      stop: function( event, ui ) {
          //jQuery('#xcoord_watermark_width').val(jQuery("#resizeableimage").width());
          //jQuery('#ycoord_watermark_height').val(jQuery("#resizeableimage").height());
 
      } 
    });*/
      jQuery( "#draggable2" ).draggable({
        containment: '#image_container',

        start: function(event, ui) {
          //console.log(jQuery('#preview_img_src').width());
          jQuery('#watermark_width').val(jQuery("#resizeableimage").width());
              jQuery('#watermark_height').val(jQuery("#resizeableimage").height());

        },
        drag: function(event, ui) {
              var poss = ui.position;
              jQuery('#xcoord_watermark').val(poss.left);
              jQuery('#ycoord_watermark').val(poss.top);

        }
      });

     jQuery( "#resizeableimage" ).resizable({
       containment: '#image_container',
        stop: function( event, ui ) {
            jQuery('#watermark_width').val(jQuery("#resizeableimage").width());
            jQuery('#watermark_height').val(jQuery("#resizeableimage").height());
   
        } 
     });

      jQuery( "#draggable" ).draggable({
      containment: '#image_container',
      create: function( event, ui ) {
           console.log('create');

      },
      start: function(event, ui) {
        
        console.log('start');
        //console.log(jQuery('#preview_img_src').width());
      },
      drag: function(event, ui) {
            var pos = ui.position;
            jQuery('#xcoord').val(pos.left);
            jQuery('#ycoord').val(pos.top);
            jQuery('#width').val(jQuery('#preview_img_src').width());
            jQuery('#height').val(jQuery('#preview_img_src').height());
        }
    });
  });

  const handleTextPane = () => {

    jQuery('.add-text-icon').toggleClass('active');
    jQuery(".add-image-icon").removeClass('active');
    jQuery(".show-info-icon").removeClass('active');
    jQuery("#mySidenav03").removeClass("open");
    jQuery("#mySidenav02").removeClass("open");
    jQuery("#mySidenav01").toggleClass("open"); 

  }

  const handleImagePane = () => {

    jQuery('.add-image-icon').toggleClass('active');
    jQuery(".add-text-icon").removeClass('active');
    jQuery(".show-info-icon").removeClass('active');
    jQuery("#mySidenav01").removeClass("open");
    jQuery("#mySidenav03").removeClass("open");
    jQuery("#mySidenav02").toggleClass("open");   

  }

  const handleDescriptionPane = () => {

   jQuery('.show-info-icon').toggleClass('active');
    jQuery(".add-text-icon").removeClass('active');
    jQuery(".add-image-icon").removeClass('active');
    jQuery("#mySidenav01").removeClass("open");
    jQuery("#mySidenav02").removeClass("open");
    jQuery("#mySidenav03").toggleClass("open");  

  }

  const handleClosePane = () => {

   jQuery(".add-text-icon").removeClass('active');
    jQuery(".add-image-icon").removeClass('active');
    jQuery(".show-info-icon").removeClass('active');
    jQuery("#mySidenav01").removeClass("open");
    jQuery("#mySidenav02").removeClass("open");
    jQuery("#mySidenav03").removeClass("open");

  }

  var size;
        var dataid;  
      jQuery(document).ready( function(e) {

      
        /*jQuery('.imageMainCard').hover( function() {
            jQuery(this).find('.cardHeader').css('display','block !important');
        })*/
         
        jQuery(".facebook_crop_ad").click(function(){
            jQuery('#gif_preview_src').css('width','').css('height','').css('display','none');
            var ratio = jQuery(this).attr('id').replace('_','/');
            //dataid = jQuery(this).attr('data-id');
            var boxheight = jQuery('#orignal_img_src').width();
            jQuery('#preview_img_src').Jcrop({
                boxWidth: boxheight,
                setSelect: [10, 10, 200, 200],
                allowResize: true,
                aspectRatio: eval(ratio),
                bgOpacity: 0.2,
                onSelect: function(c){
                  //console.log(c);
                    size = {x:c.x,y:c.y,w:c.w,h:c.h};
                    jQuery("#crop").css("visibility", "visible");     
                }
            });
        });



        
    })

const submitCropData  = (channelID) => {
        //jQuery(".downloadcrop").click(function(){
            //console.log(e.keyCode);
            setShowCropProgress(true);
            var imageObject;
            var img = jQuery("#preview_img_src").attr('src');
            try {
              imageObject         = new Image();
              imageObject.src     = img;
              var originalWidth   = imageObject.width;
              var originalHeight  = imageObject.height;
              
              var previewWidth    = jQuery("#preview_img_src").width();
              var previewHeight   = jQuery("#preview_img_src").height();
              
              var differWidth     = originalWidth/previewWidth;
              var differHeight    = originalHeight/previewHeight;
              
              var finalXCoord     = eval(differWidth*size.x);
              var finalYCoord     = eval(differHeight*size.y);
              
              var finalWidth      = eval(differWidth*size.w);
              var finalHeight     = eval(differHeight*size.h);
              
              const requestOptions   = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                  body: JSON.stringify({ x:finalXCoord, y:finalYCoord, w:finalWidth, h:finalHeight, img:img, channelid:channelID, userid: userinfo[0].id, assetid:state.isPaneTileId })
              };
              fetch('https://api.digiroom.io/v1/editor/cropimage', requestOptions)
              .then(response => response.json())
              .then(response => { //downloadAsset(response.url,response.message)
                copynotify();
                setShowCropProgress(false);
              })

              //console.log('wp-content/themes/flatsome-child/image-crop.php?x='+finalXCoord+'&y='+finalYCoord+'&w='+finalWidth+'&h='+finalHeight+'&img='+img+'&dataid='+dataid);
              //window.location.href = 'https://stocky.ai/wp-content/themes/flatsome-child/image-crop.php?x='+finalXCoord+'&y='+finalYCoord+'&w='+finalWidth+'&h='+finalHeight+'&img='+img+'&dataid='+dataid;
            } catch (e) {
             console.error(e) 
            }
        };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,video/*',
    multiple: true,
    onDrop: acceptedFiles => {
      setShowProgress(true);
      const files = acceptedFiles.map(file => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload=(e)=>{
          //console.log(file.name);
          const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
                  body: JSON.stringify({ 
                    file:     e.target.result,
                    filetype: file.type,
                    filesize: file.size,
                    filename: file.name,
                    team:     teamId,
                    userid:   userinfo[0].id,
                    channel:  match.params.name
                  })
              };

          fetch('https://api.digiroom.io/v1/channel/uploadmedia', requestOptions)
          .then(response => response.json())
          .then(response => setAssetListing(response.assets))
          .then(response => setShowProgress(false))

                //console.log(response);
                
            
        } 
      });
      
    }
    
  });

  function search(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].name === nameKey) {
            return myArray[i];
        }
    }
  }
  
  var resultObject = search("Stocky Editor", userAppsListing);
  //const editorEnabled = //userAppsListing.find(x => x.appid === 'Editor').name;
  //console.log(resultObject);
  //var result = Object.keys(userAppsListing).map((key) => [Number(key), userAppsListing[key]]);

  //  console.log(result);
  
  function applyGif(valuess){
    if(jQuery('#preview_img_src').data('Jcrop')){
      jQuery('#preview_img_src').width('');
      jQuery('#preview_img_src').height('');
      jQuery('#preview_img_src').data('Jcrop').destroy();
    }
    var width = jQuery('#preview_img_src').width();
    var height = jQuery('#preview_img_src').height();
  
    var randomnumber = Math.random();
    jQuery('#layername').val(valuess)
    jQuery('#preview_img_src').css('position','absolute');
    jQuery('#gif_preview_src').css('width',width+'px').css('height',height+'px').css('display','inherit').css('position','absolute');
    jQuery('#gif_preview_src').attr('src','https://s3.amazonaws.com/files.stocky.ai/uploads/2021/04/'+valuess+'.gif');

    /*if(gifloaded==1){
      gifloaded = 0;
      console.log(gifloaded);
      var htmltoinsert = '<img id="gif_preview_src" src="http://stocky.ai/wp-content/themes/flatsome-child/assets/gif/medium/'+valuess+'.gif" style="mix-blend-mode:screen; width:'+width+'px; height:'+height+'px;">';
      jQuery('#original_preview_box').append(htmltoinsert);
      
      jQuery('#preview_img_src').css('position','absolute');
    } else {
      jQuery('#gif_preview_src').css('width',width+'px').css('height',height+'px');
      jQuery('#gif_preview_src').attr('src','http://stocky.ai/wp-content/themes/flatsome-child/assets/gif/medium/'+valuess+'.gif');
    }*/
   // jQuery('.downloadgif').attr('href','http://stocky.ai/gifdown.php?id='+btoa(state.isPaneTileId)+'&layer='+btoa(valuess)+'&url='+btoa(jQuery('#preview_img_src').attr('src'))+'&time='+randomnumber);
  };

  function handleAfterOpen(){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
      body: JSON.stringify({ 
        assetid: state.isPaneTileId,
        channel: match.params.name, 
        userid: userinfo[0].id 
      })
    };

    fetch('https://api.digiroom.io/v1/asset/detail', requestOptions)
    .then(response => response.json())
    .then(response => {
      //console.log(response.presigned_url);
      jQuery('#preview_img_src').attr('src',response.assetdetail[0].presigned_url);
      jQuery('#preview_title').html(response.assetdetail[0].name);
      jQuery('#preview_type').html(response.assetdetail[0].file_extension);
      jQuery('#preview_filesize').html(bytesToSize(response.assetdetail[0].file_size));
      const theImage = new Image();
      theImage.src = response.assetdetail[0].url;
      jQuery('#preview_resolution').html(theImage.width+' x '+theImage.height);
      //jQuery('#preview_description').html(longdesc);
    })
    
    //jQuery('#preview_img_src').attr('src',response.url); 
    ////.delay(600).queue(function() {jQuery(this).attr('src','https://teams-1618404603.s3.amazonaws.com/general/black-dog-on-gray-asphalt-road-during-daytime-1621334081.jpg'); })
  }

  function bytesToSize(bytes) {
     var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
     if (bytes == 0) return '0 Byte';
     var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
     return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }
  const options = {
    buttons: {
      showThumbnailsButton: false,
      showNextButton: false,
          showPrevButton: false,
          showAutoplayButton: false,
          showDownloadButton: false,
      },
      thumbnails: {
        showThumbnails: false,
      },
  }

  function openPane(tileId){

    //console.log(imgsrc);
    if(jQuery('#preview_img_src').data('Jcrop')){
      jQuery('#preview_img_src').width('');
      jQuery('#preview_img_src').height('');
      jQuery('#preview_img_src').data('Jcrop').destroy();
    }
    setState({ isPaneOpen: true, isPaneTileId: tileId });
    
    //setState({ isPaneTileUrl: imgsrc });
    //jQuery('#preview_img_src').attr('src',imgsrc);
  }

  const handleFileSelector = (event) => {
    //console.log(event.target.files[0]);
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload=(e)=>{
     // console.log(e.target.result);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
          body: JSON.stringify({ 
            file: e.target.result,
            filetype: file.type,
            filesize: file.size,
            filename: file.name,
          })
      };

      fetch('https://api.digiroom.io/v1/editor/uploadwatermark', requestOptions)
      .then(response => response.json())
      .then(response => {
        jQuery('#draggable2').show();
        jQuery('#draggable2 .ui-wrapper').css('height','auto').css('width','auto');
        jQuery('#resizeableimage').css('height','auto').css('width','auto');
        jQuery('#resizeableimage').attr('src', response.message);
        jQuery('#watermark_url').val(jQuery('#resizeableimage').attr('src'));
        jQuery('#original_url').val(jQuery('#preview_img_src').attr('src'));
        jQuery('#original_width').val(jQuery('#preview_img_src').width());
        jQuery('#original_height').val(jQuery('#preview_img_src').height());
        //console.log(response.message);
      })
    }

    //console.log(fd);
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
    //   body: JSON.stringify({ 
    //     image: event.target.files[0],
    //   })
    // };
    // fetch('https://api.digiroom.io/v1/editor/uploadwatermark', requestOptions)
    // .then(response => response.json())
    // .then(response => {
    //   console.log(response);
    // })
          
  }
  return (
    <div >
      <div className="mainWrapper">
      <TopNav />
      <ToastContainer />
      <Box display="flex" flexDirection="row"  className="bradecrumbbox">
        <Box width="100%">
          <Box className={classes.subBar} pl={1}  pr={1}  >
            <Box>
              <Grid container spacing={0}>
                <Grid style={{border:'0px solid red;'}} item xs={8}>
                  <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
                    <Typography className="breadcrumbMainHeading" >
                    <b>{teamName}</b>
                    </Typography>
                    <NavLink to={"/channel/"+bucketSlugForBread} ><Typography  className="breadcrumbSubHeading">
                    <b>{bucketName} </b>
                    </Typography></NavLink>
                    {folderName ? <NavLink to={"/channel/"+bucketSlugForBread+"/"+folderSlug} ><Typography  className="breadcrumbSubHeading">
                    <b>{folderName} </b>
                    </Typography></NavLink> : ''}
                    {subFolderName ? <NavLink to={"/channel/"+bucketSlugForBread+"/"+folderSlug+"/"+subFolderSlug} ><Typography  className="breadcrumbSubHeading">
                    <b>{subFolderName} </b>
                    </Typography></NavLink> : ''}
                  </Breadcrumbs>
                </Grid>
                <Grid className={classes.root}  style={{border:'0px solid red'}} item xs={4}>
                  <Box pt={3}>
                    { showProgress ? <LinearProgress className={classes.progressbar} /> : null }
                    
                  </Box>
                </Grid>
              </Grid>
            </Box> 
          </Box>
          <Box className={classes.uploadBar} pl={1}  pr={1} p={1} >

            <Box {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Typography className={classes.draganddrop}>
                <div class='uploadmediatext'>
                  &nbsp;Drop files here or click to upload
                </div>
              <div style={{float:'right'}}>
                <img src="/uploadicon.svg"  style={{fill:'#191925',fontSize:'30px', width: '36px', height: '42px'}} />
              </div>
              </Typography>
            </Box>
          </Box>
          {directorylisting ? <Box className={classes.folderBar}>
            {directorylisting.map((value) => (
              <div><NavLink exact  to={bucketSlugForUrl+"/"+value.slug}  activeClassName="channel-active" >
                <div className={classes.channelicon} style={{marginLeft: '20px'}}><FolderIcon style={{ fontSize: 15 }}  /></div>
                <div className={classes.channellink} >{value.slug}</div>
              </NavLink></div>
              
            ))}
            </Box>
          : '' }
          <div style={{clear: 'both'}}></div>
          <Box display="flex" className={classes.filterSection}  >
            <Box width="50%" fontWeight="fontWeightBold" >
              <Typography variant="subtitle2" className={classes.numberImages}  ><b>{channelName} ({assetlisting.length} Files)</b></Typography>
            </Box>
          </Box>

          
          
          
          
          <Box pl={1}  pr={1} id="assetsBox">
            <Box  style={{maxHeight:window.innerHeight-272, overflow:'auto',  scrollbarWidth: 'thin'}}>
    
                {assetlisting.map((tile) => (
                  <div className="cardParentDiv">

                <Card className="imageMainCard" >
                  <CardHeader
                    className="cardHeader" 
                    avatar={
                      <Avatar aria-label="recipe" className="imgAvatar">
                        {tile.name.substring(1,0)}
                      </Avatar>
                    }
                    
                    title={<Typography className="headerTitle">{tile.name.substring(14,0)}</Typography>}
                    subheader={<Typography className="headerDate">{tile.uploaded_at}</Typography>}
                  />
                  <div style={{backgroundColor:'#000'}}>
                  <SRLWrapper  options={options}>
                  <a href={tile.presigned_url} >
                    <img style={{width:'100%', height: '200px', objectFit: 'cover'}} src={tile.presigned_url} className="cardImage" />
                  </a>
                   </SRLWrapper>
                   </div>

                  
                  
                  <CardActions disableSpacing className="imageActions">
                    
                     {userRole=='2' ? <Tooltip title="Delete" placement="top"><IconButton aria-label="add to favorites"> <Delete onClick={() => deleteAsset(tile.id)} /> </IconButton></Tooltip> : '' }
                    <Tooltip title="Share">
                    <IconButton aria-label="share">
                      <Share value={tile.id}  onClick={() => handleDialogClickOpen(tile.presigned_url)} />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Download" placement="top">
                    <IconButton aria-label="share">
                      <CloudDownload onClick={() => downloadAsset(tile.presigned_url,tile.name)}  />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Move" placement="top">
                    <IconButton aria-label="more"
                      aria-controls={"long-menu"}
                      aria-haspopup="true"
                      value={tile.id}
                      onClick={handleClick}>
                      <ExitToAppIcon />
                      
                    </IconButton>
                    </Tooltip>
                    
                    {resultObject ? <Tooltip title="Editor" placement="top">
                    <IconButton aria-label="more"
                      aria-controls={"long-menu"}
                      aria-haspopup="true"
                      value={tile.id}
                      onClick={() => openPane(tile.id) }>
                      <PictureInPictureAltIcon />
                    </IconButton>
                    </Tooltip> : '' }
                    

                  </CardActions>
                  
                </Card>
                </div>
                  ))}
                    <Menu
                        id={"long-menu"}
                        anchorEl={anchorEl}
                        onClose={handleCloses}
                        keepMounted
                        open={open}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            
                            border: '1px solid #CCC'
                          },
                        }}
                      >

                        {channellisting.map((val,key) => {
                        return <div><MenuItem disabled={val.id == channelIDForChildren} className={classes.moveMenuItem} value={val.id} onClick={handleClose}>
                        {val.name}
                        </MenuItem>
                        <Folder assetID={currentassetid} matchParam={match.params.name} currentChannel={channelIDForChildren} bucketID={val.id} /> </div>

                      })}
                      
                    </Menu>

                
            
              <Dialog
                fullScreen={fullScreen}
                open={opend}
                onClose={handleDialogClose}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"Share Your Image"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Twitter  link={currentasseturl} />
                    <Pinterest  link={currentasseturl} />
                    <Facebook  link={currentasseturl} />
                    <Linkedin link={currentasseturl} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  
                  <Button onClick={handleDialogClose} color="primary" autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
                </Box>

              
            </Box>
        </Box>
      </Box>
      
    </div>
    <SlidingPane
    closeIcon={<KeyboardArrowDownIcon />}
    className="some-custom-class"
    overlayClassName="some-custom-overlay-class"
    isOpen={state.isPaneOpen}
    title=" "
    hideHeader="false"
    from="bottom"
    width="100%"
    subtitle=" "
    onAfterOpen={handleAfterOpen}
    onRequestClose={() => {
      // triggered on "<" on left top click or on outside click
      setState({ isPaneOpen: false });
    }}
    >
    <div style={{height: 'auto'}}>
    <div style={{border: '1px solid #353535', margin: '10px', height: '520px', borderRadius: '7px', backgroundColor: '#06060680' }}>
      <Grid container style={{height:'100%'}}>
        <Grid xs={2}>
          <ul className={classes.editorSideBar}>
            <li ><button id="crop_button" onClick={()=> { jQuery('#crop_button').toggleClass('active'); jQuery('#crop_range').fadeToggle(500);  }}><CropIcon style={{fontSize: '21px', padding:'3px'}} />&nbsp;&nbsp;<span>Crop</span></button></li>
            <li><button id="text_button" onClick={()=> { jQuery('#text_button').toggleClass('active'); jQuery('#text_range').fadeToggle(500);  }}><EditIcon style={{fontSize: '21px', padding:'3px'}} />&nbsp;&nbsp;<span>Annotate</span></button></li>
            <li><button><BrandingWatermarkIcon style={{fontSize: '21px', padding:'3px'}} />&nbsp;&nbsp;<span>Sticker</span></button></li>
            <li><button><EditIcon style={{fontSize: '21px', padding:'3px'}} />&nbsp;&nbsp;<span>Animate</span></button></li>
          </ul>

        </Grid>
        <Grid xs={3}>
          <div id="crop_range" style={{display:'none'}}>
            <ul  className={classes.cropRanges} >
              <li><button id="1.91_1" className="facebook_crop_ad">1.9:1</button></li>
              <li><button id="1_1" className="facebook_crop_ad">1:1</button></li>
              <li><button id="9_16" className="facebook_crop_ad">9:16</button></li>
            </ul>
            <div> 
              <div className="textCopyToButton" style={{float:'left'}} onMouseOver={() => { onMouseOverEVent('channelListForCropped')}} onMouseLeave={() => { onMouseOutEVent('channelListForCropped')}}>
                <div style={{height: '40px', lineHeight: '40px', cursor: 'pointer'}}>
                  <div style={{float: 'left', paddingLeft: '11px', fontWeight: 'bold'}}>Save Cropped </div>
                  <div style={{float:'right', padding: '6px', height: '28px', borderLeft: '1px solid #d7d0ce2e'}}><ArrowDropDownIcon /></div>
                </div>
                <div id="channelListForCropped" >
                  <ul className="toggleChannelList">
                  {channellisting.map((val,key) => {
                    return <li onClick={() => {submitCropData(val.id)}} >
                     {val.name}
                    </li>
                  })}
                  </ul>
                </div>
              </div>
              { showCropProgress ? <Progress /> : null }
            </div>
            <button className={classes.doneButton}>Done</button>
          </div>
          <div id="text_range" className="fm-textedit-box" style={{display:'none'}}>
            <div class="text-field">
                <label>Text</label>
                <input onKeyUp={handleTextDrag} onChange={handleTextDrag} id="text_field" name="text" className={classes.editorTextField} type="text" />
            </div>
            <div class="text-field">
                <label>Font</label>
                <select name="size" id="text_fontfamily" name="fontfamily" className={classes.editorTextField} onChange={handleTextFamily}>
                    <option>Select Font</option>
                    <option value="'Amita', cursive" className="AmitaFont">Amita</option>
                    <option value="'Bebas Neue', cursive" className="BebasNeueFont">Bebas Neue</option>
                    <option value="'Great Vibes', cursive" className="GreatVibesFont">Great Vibes</option>
                    <option value="'Lobster', cursive" className="LobsterFont">Lobster</option>
                    <option value="'Roboto', sans-serif" className="RobotoFont">Roboto</option>
                    <option value="'Nunito', sans-serif" className="NunitoFont">Nunito</option>
                    <option value="'Yellowtail', cursive" className="YellowTailFont">Yellowtail</option>
                    <option value="'Pacifico', cursive" className="PacificoFont">Pacifico</option>
                    <option value="'Permanent Marker', cursive" className="PermanentMarkerFont">Permanent Marker</option>
                </select>
              
            </div>
            <div class="text-field">
                <label>Font Size</label>
                <select name="size" id="text_fontsize" className={classes.editorTextField} onChange={handleTextFontSize}>
                  <option value="">Font Size</option>
                  <option value="12">X-Small</option>
                  <option value="14">Small</option>
                  <option value="20">Medium</option>
                  <option value="28">Large</option>
                  <option value="38">X-Large</option>
                </select>
            </div>
            <div class="text-field">
                <label>Text Color</label>
                <select name="size" className={classes.editorTextField} id="text_fontcolor" name="color" onChange={handleTextColor}>
                  <option value="000000">Select Color</option>
                  <option value="FF0000">Red</option>
                  <option value="008000">Green</option>
                  <option value="FFFF00">Yellow</option>
                  <option value="00FFFF">Cyan</option>
                  <option value="000000">Black</option>
                  <option value="FFFFFF">White</option>
                </select>  

            </div>
            

            <button className={classes.doneButton}>Done</button>
          </div>
        </Grid>
        <Grid xs={7} style={{textAlign:'center'}}>
          <div className="preview_main_area col large-6">
        
          <div id="original_preview_box" align="center">
            <div className="text_image_area" id="image_container">
              <div id="draggable" className="ui-widget-content" style={{border:'1px solid white', top: '100', left:'100',zIndex: '601',  position:'absolute', color:'#960', cursor:'move', width:'auto', display:'inline-block'}}><div className="remove_button" id="remove_text">Remove</div><span>Text</span></div>
              
              <div id="draggable2" style={{border:'1px solid white', top: '100', left:'100',  position:'absolute', color:'#960', cursor:'move', width:'auto', display:'inline-block'}}><div className="remove_button" id="remove_watermark">Remove</div><img id="resizeableimage" src="#" style={{width:'auto', height: 'auto'}} /></div>
              <img id="preview_img_src" src=""  />
              <img id="gif_preview_src" style={{mixBlendMode:'screen'}} />
            </div>
            
          </div>
          <div class="clearboth"></div>
        </div>
        </Grid>
      </Grid>
    </div>
    </div>
    </SlidingPane>
  </div>

    
  );
}

export default ChannelDetails;
