import {React} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    '& .MuiButton-contained:hover': {
      backgroundColor:'#EC2E89 !important'
    }
  },
 
  singUpContainer: {
    height: '100%',
    textAlign: "center",
     width:'560px'
  },
  singUpLogo: {
    height: 75,

  },
  singUpHeading: {
    fontSize: 40,
    fontWeight: '900'
  },
  singUpTagLine: {
    fontSize: 16,
  },
  textFieldRoot: {
      width: '100%'
    },
    continueButton: {
      backgroundColor: '#D71371',
      width: '100%',
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 18,
      textTransform: 'none'
    },
    marginAutoItem: {
    margin: '0px auto',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/user/nicknice)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 
function Thanks () {


  const classes = useStyles();
  const history = useHistory();
  
   
    window.setTimeout(() => {
        history.push('/login');
        //history.push('/setpassword');
      }, 3000);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          
          <Box  alignItems="center" className={classes.marginAutoItem}>
              <br />
              <div className={classes.singUpContainer}>
               <img className={classes.singUpLogo} src="http://files.stocky.ai/uploads/2020/12/stocky-black.png" />
              <div>
                <h1 className={classes.singUpHeading}>Thank You</h1>
                <h2>You have successfully signed up with Stocky Team. You will be redirected to login page in a moment.</h2>
              </div>
              <br />
              <br />
              <div>
                
                
              </div>
              
            </div>
            </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default Thanks;