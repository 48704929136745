
import React,{useCallback, useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import { Breadcrumbs,Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import jQuery from 'jquery'; 
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import Delete from '@material-ui/icons/Delete';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Share from '@material-ui/icons/Share';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import {useHistory,NavLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Twitter, Facebook, Pinterest, Linkedin } from 'react-social-sharing';
import { ToastContainer, toast } from 'react-toastify';
import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt';
import GoogleVisionIcon from '@material-ui/icons/RemoveRedEye';
import Gif from '@material-ui/icons/Gif';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { SRLWrapper } from "simple-react-lightbox";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import FolderIcon from '@material-ui/icons/Folder';
import Folder from './Folder.js';
import AnimateFolder from './AnimateFolder.js';
import EditorFolder from './EditorFolder.js';
import AssetCount from '../Common/SideBar/AssetCount.js';
import AWS from 'aws-sdk';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



import { PinturaEditor } from 'react-pintura';
// pintura
import 'pintura/pintura.css';
import { getEditorDefaults, createDefaultImageWriter } from 'pintura';
const APIURL = process.env.REACT_APP_API_URL;
  
const useStyles = makeStyles(theme => ({
	root:{
		'& .MuiLinearProgress-root': {
			height:'6px'
		}
	},
	filterSection: {
    height: '23px',
		paddingLeft:'8px',
	},
	breadCrumbBox: {
		width: '78%',
		float: "left",
	},
  channellink: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '1px'
  },
  channelicon: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '4px'
  },
	subBar: {
		height:'60px', 
		borderBottom:'1px solid #D2D2D2'
	},
  uploadBar: {
    height:'90px', 
    borderBottom:'1px solid #D2D2D2'
  },
  statsBar: {
    height:'30px', 
    borderBottom:'1px solid #D2D2D2'
  },
  folderBar: {
    height:'25px', 
    borderBottom:'1px solid #D2D2D2'
  },
	breadCrumb: {
		float: "left",
		fontSize:13,
		lineHeight:'58px',
		color: '#000000',
		fontWeight: 'bold',
      '& p': {
        fontSize:16,
        color: '#16163c'
      },
      '& li': {
        fontSize:16,
        color: '#16163c'
      }
	},
	numberImages:{
		fontWeight: '700',
		fontSize:13,
    color: '#16163c'
	},
  numberPages:{
    fontWeight: '700',
    fontSize:15,
    color: '#16163c'
  },
  currentPage: {
    color: '#3f8aff',
    textDecoration: 'underline'
  },
  activePages: {
    cursor: 'pointer',
    '&:hover':{
     textDecoration: 'underline',
     color: '#3f8aff',
    }
  },
	addTeamMateButton: {
		backgroundColor: '#7B83EB',
   		color: '#FFFFFF',
   		fontWeight: '600',
   		fontSize: 12
   		
	},
	dropzone: {
		float: "right",
	},
	  paper: {
	    padding: theme.spacing(2),
	    textAlign: 'center',
	    color: theme.palette.text.secondary,
	  },
	  draganddrop : {
	  	fontSize:26,
	  	color:'#33344A',
	  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  progressbar: {
  	
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[900],
    textTransform: 'uppercase',
    width: '25px !important'
  },
  editorTextField: {
    width: '90%',
    padding: '7px',
    border: 'none',
    borderRadius: '3px',
    boxShadow: '0px 0px 2px #FFF',
    outline: 'none'
  },
  editorTextLabel: {
    fontSize: '15px'
  },
  cirProgress: {
    color:'#FFFFFF !important',
    margin: '10px 0 0 10px',
    height: '30px !important',
    width: '30px !important'
  }, 
  moveMenuItem: {
    fontSize:'13px', 
    fontWeight: 'bold',
    '&:hover':{
      backgroundColor: '#464775',
      color: '#FFFFFF'
    }
  },
  assetActions: {
    width: '14%'
  },
  TabTitle: {
    color: '#FFFFFF',
    ': selected': {
      color: '#FFFFFF'
    }
  }

}))

function ChannelDetails({match}) {
  const VERY_UNLIKELY = <div className="bar" style={{backgroundColor:'#A5D6A7', width:'20%'}}></div>;
  const UNLIKELY      = <div className="bar" style={{backgroundColor:'#81C784', width:'40%'}}></div>;
  const POSSIBLE      = <div className="bar" style={{backgroundColor:'#66BB6A', width:'60%'}}></div>;
  const LIKELY        = <div className="bar" style={{backgroundColor:'#4CAF50', width:'80%'}}></div>;
  const VERY_LIKELY   = <div className="bar" style={{backgroundColor:'#43A047', width:'100%'}}></div>;

  const VERY_UNLIKELY_TEXT = 'Very Unlikely';
  const UNLIKELY_TEXT      = 'Unlikely';
  const POSSIBLE_TEXT      = 'Possible';
  const LIKELY_TEXT        = 'Likely';
  const VERY_LIKELY_TEXT   = 'Very Likely';

  const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
  const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
  // get default properties
  const [OriginalImage, setOriginalImage]     = React.useState(null);
  const [accessKeyId, setAccessKeyId]         = React.useState(null);
  const [secretAccessKey, setSecretAccessKey] = React.useState(null);
  
  
  // Set up S3 connection
  const s3 = new AWS.S3({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey  
  });

  const [result, setResult] = useState('');
  //console.log(match);
  const onMouseOverEVent = (divId) => {
      jQuery('#'+divId).show();
  }
   const onMouseOutEVent = (divId) => {
      jQuery('#'+divId).hide();
  }

    const Progress = () => (
      <span><div style={{float: 'left'}}>
      <CircularProgress className={classes.cirProgress} /></div></span>
    );


  var gifloaded = 1;
  const [opend, setOpen] = React.useState(false);
  
  const [channelName, setChannelName] = React.useState(null);
  const [editorImage, setEditorImage] = useState(null);
  const [visiomMetaLabel, setVisionMetaLabel] = useState([]);
  const [visiomMetaFace, setVisionMetaFace] = useState([]);
  const [visiomMetaObject, setVisionMetaObject] = useState([]);
  const [visiomMetaColor, setVisionMetaColor] = useState([]);
  //console.log(visiomMetaColor);
 // console.log(visiomMetaLabel);
  const [bucketName, setBucketName] = React.useState(null);
  const [folderName, setFolderName] = React.useState(null);
  const [subFolderName, setSubFolderName] = React.useState(null);
  const [bucketSlugForBread, setbucketSlugForBread] = React.useState(null);
  const [folderSlug, setFolderSlug] = React.useState(null);
  const [subFolderSlug, setSubFolderSlug] = React.useState(null);

  const [assetCount, setAssetCount] = useState(null);
  const [totalPages, setPagesCount] = useState(null);
  const [currentNumber, setCurrentNumber] = useState(1);
  const imageperPage = 20; 

  //const currentNumber = 5;
 let startNumber = 1;
  if (currentNumber >= 3 && currentNumber <= totalPages - 2) {
    startNumber = currentNumber - 2;
  } else if (currentNumber > totalPages - 2) {
    startNumber = totalPages - 4;
  }
  const endNumber = Math.min(startNumber + 4, totalPages);
  const numbers = Array.from({ length: endNumber - startNumber + 1 }, (_, index) => startNumber + index);

  


  const [channelIDForChildren, setChannelID] = React.useState();
  //console.log(channelIDForChildren);
	const theme = useTheme();
  const [showProgress, setShowProgress] = React.useState(false);
  const [showCropProgress, setShowCropProgress] = React.useState(false);
  const [showGifProgress, setShowGifProgress] = React.useState(false);
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [teamId, setTeamId] = useState(null);
	const [teamName, setTeamName] = useState(null);
	const [userRole, setUserRole] = useState(null);
	const [currentassetid, setAssetID] = useState(null);
	const [currentasseturl, setAssetUrl] = useState(null);
	const [expanded, setExpanded] = React.useState(false);
	const [assetlisting, setAssetListing] = useState([]);
  const [channellisting, setChannelList] = useState([]);
  
  const [userPermissions, setUserPermissions] = useState([]);
	const [userAppsListing, setUserAppsList] = useState([]);
  const [directorylisting, setDirectoryList] = useState([]);
  const [bucketSlugForUrl, setBucketSlug] = useState();
	const [state, setState] = useState({
	    isPaneTileId: '',
      isGifPaneOpen: false,
      isVisionPaneOpen: false,
      isPaneOpen: false,
	    isPaneOpenLeft: false,
	  });
    //console.log(userPermissions.upload_media);
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
      console.log('newValue', newValue); 
      setValue(newValue);
    };
    function a11yProps(index) {
      return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
    }



    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      console.log(value, index);
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={'scrollable-auto-tabpanel-${index}'}
          aria-labelledby={'scrollable-auto-tab-${index}'}
          {...other}
        >
          {value === index && (
            <><Box p={3}>
              <Typography>{children}</Typography>
            </Box></>
          )}
        </div>
      );
    }


  useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ slug: match.params.name, userid: userinfo[0].id, teamid: teaminfo  })
        };

         

        fetch(APIURL+'channel/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  ///console.log(response.channel_auth);
                  if(response.channel_auth=='false'){
                    window.location.href = '/dashboard';
                  }
                  setChannelName(response.channelDetails[0].name)
                  setBucketName(response.bukcetDetail[0].name)
                  setFolderName(response.folderDetail[0].name)
                  setbucketSlugForBread(response.bukcetDetail[0].slug)
                  setFolderSlug(response.folderDetail[0].slug)
                  setSubFolderName(response.subFolderDetail[0].name)
                  setSubFolderSlug(response.subFolderDetail[0].slug)
                  setChannelID(response.channelDetails[0].id)
                  //console.log(response.authorizedChannel);
                   
                    const requestOptionss = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                      body: JSON.stringify({ userid: userinfo[0].id, channel: response.channelDetails[0].id  })
                    };
                    fetch(APIURL+'assets/count', requestOptionss)
                    .then(response => response.json())
                    .then(response => {
                      setAssetCount(response.count);
                      setPagesCount(Math.ceil(response.count / imageperPage));
                    })  
                    const requestOptionsss = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                        body: JSON.stringify({ userid: userinfo[0].id, bucket: response.channelDetails[0].id  })
                    };
                    //console.log('=channelDetails: '+response.channelDetails[0].id);

                    fetch(APIURL+'directory/list', requestOptionsss)
                      .then(response => response.json())
                      .then(response => {
                            setDirectoryList(response.directories)
                            //console.log(directorylisting);
                            setBucketSlug(response.bucketSlug)
                            //this.state = { response };
                    })
                  //this.state = { response };
            })

            
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [match.params.name]);
//console.log('OriginalImage: '+OriginalImage);
    const editorDefaults = getEditorDefaults({
      // Set a custom image writer
        imageWriter: createDefaultImageWriter({
            store: (state, options, onprogress) =>
                new Promise((resolve, reject) => {
                    // Get file object reference
                    const { dest } = state;
                    //console.log('uploading image to S3');
                    // Upload to S3
                    const httpUpload = s3.upload(
                        {
                            Bucket: jQuery('#bucketName').val(),
                            Key: jQuery('#targetfileName').val(),
                            Body: dest,
                            ContentType: dest.type,
                            //ACL: 'public-read',
                        },
                        (err, data) => {
                            jQuery('#bloburlofimage').val(data.Location);
                            jQuery('#keyofimage').val(data.Key);
                            const requestOptions   = {
                              method: 'POST',
                              //headers: { 'Content-Type': 'application/octet-stream',"Accept": "application/octet-stream" },
                              headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
                              body: JSON.stringify({
                                file:data.Location, 
                                teamid: teaminfo, 
                                key:data.Key , 
                                page:1 ,
                                filetype: 'image/jpeg', 
                                bucketName:jQuery('#bucketName').val(), 
                                channelid:jQuery('#channelID').val(), 
                                userid: userinfo[0].id, 
                                assetid:state.isPaneTileId })
                            };
                            fetch(APIURL+'editor/cropimage', requestOptions)
                              .then(response => response.json())

                              .then(response => { //downloadAsset(response.url,response.message)
                                setAssetListing(response.assets);
                                setAssetCount(response.count);
                                setPagesCount(Math.ceil(response.count / imageperPage));
                                //if(jQuery('#targettype').val()=="folder"){
                                  history.replace("/channel/"+jQuery('#bucketName').val()+"/"+jQuery('#folderName').val());
                               // }
                                
                                
                                //console.log(response);
                                copynotify();
                                setShowCropProgress(false);

                              }

                            )
                            // Test if returned error, if so, throw error in UI
                            if (err) return reject('Something went wrong.');

                            // Remember key (store is returned in editor 'process' event)
                            state.store = data.Key;

                            // Resolve with updated state
                            resolve(state);
                        }
                    );
                    console.log('uploaded image to S3');
                    //console.log(httpUpload);
                    // Show S3 upload progress in UI
                    httpUpload.on('httpUploadProgress', onprogress);
                }),
        }),
      locale: {
          labelButtonExport: 'Process',
      },
      
        stickers: [
          [
              // group label
              'Emoji',
              // group stickers
              ['🎉', '😄', '👍', '👎', '🍕','🌤','🌥','⭐️','☀️'],
          ],[
              // required group label
              'Numbers',
              // group stickers, can be an empty array
              [APIURL+'sticker-one.svg',APIURL+'sticker-two.svg',APIURL+'sticker-three.svg'],
          ],
          
        ]


    });
    function colorShower(color,colorRGB, percent){
      jQuery('#colorshower').html('<div style="text-transform:uppercase">'+color+', '+colorRGB+', '+percent+'</div><div style="background-color: '+color+'; height: 40px;">&nbsp;</div>');
    }
	   const notify = () => toast.dark("Item moved to trash", {
			position: "bottom-left",
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
     const copynotify = () => toast.dark("Item copied to your channel", {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    const animatenotify = () => toast.success("Your GIF image is being genertaed. Please check back after a while.", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
     const movenotify = () => toast.success("Item moved to another channel", {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
const handleDialogClickOpen = (asseturl) => {
    setOpen(true);
    setAssetUrl(asseturl);
  };
let history = useHistory();
  const handleDialogClose = () => {
    setOpen(false);
  };



 	  useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'channel/list', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setChannelList(response.channels)
                  //this.state = { response };
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

   

    useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'user/permissions', requestOptions)
            .then(response => response.json())
            .then(response => {
                 // console.log(response.permission)
                  setUserPermissions(response.permission)
                  //this.state = { response };
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);


    
    

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
      body: JSON.stringify({ channel: match.params.name, userid: userinfo[0].id, teamid: teaminfo, page: 1  })
    };
    fetch(APIURL+'assets/listing', requestOptions)
      .then(response => response.json())
      .then(response => {
        setAssetListing(response.assets);
        setCurrentNumber(1)
    })


  }, [match.params.name]);


	   useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
            
              const crequestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                  body: JSON.stringify({ teamid: response.teamid })
              };
              fetch(APIURL+'team/getawscredential', crequestOptions)
                  .then(cresponse => cresponse.json())
                  .then(cresponse => {
                    setAccessKeyId(cresponse.key)
                    setSecretAccessKey(cresponse.secret)
                  }
              )
    					setTeamName(response.teamname)
    					setTeamId(response.teamid)
    					setUserRole(response.userrole)
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);
    //const routevar = <Redirect push to="/channel/tree-house" />
    //console.log('channelIDForChildren: '+channelIDForChildren);
    function showAfterProcess(imageUrl){
      jQuery('#processedImageProgress').show();
      jQuery('#bloburlofimage').val(imageUrl);
      jQuery('#downloadPreviewSection').show();
      jQuery('#processedimage').attr('src',imageUrl);
      
      //jQuery('#afterProcessSection').show();
      //jQuery('#processedImageProgress').hide();
    }
    function generateFileName(){
      let d = new Date();
      let dformat = d.getHours()+'_'+d.getMinutes()+'_'+d.getSeconds();
      //return "DigiRoom_" + dformat + ".jpg";
      return jQuery('#keyofimage').val();
    }

    function downloadAsset(assetUrl,assetName){
    	   //console.log('assetUrl',assetUrl);
        const configg = {
            headers: { 'Access-Control-Allow-Origin' : '*' },
            
        };
        try {
      	fetch(assetUrl, configg)
      	.then(resp => resp.blob())
    		.then(blob => {
    			const url = window.URL.createObjectURL(blob);
          //console.log('url',url);
      		const a = document.createElement('a');
      		a.style.display = 'none';
  		    a.href = url;
  		    // the filename you want
  		    a.download = assetName;
  		    document.body.appendChild(a);
  		    a.click();
    		})
      } catch(e){
        console.error(e);
      }
    	
    }


    function RGBToHex(r,g,b) {
      r = r.toString(16);
      g = g.toString(16);
      b = b.toString(16);

      if (r.length == 1)
        r = "0" + r;
      if (g.length == 1)
        g = "0" + g;
      if (b.length == 1)
        b = "0" + b;

      return "#" + r + g + b;
    }

    function deleteAsset(assetId){
    	const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ assetid: assetId, userid: userinfo[0].id, teamid: teaminfo, channel: match.params.name, page: 1 })
        };
        fetch(APIURL+'assets/delete', requestOptions)
        .then(response => response.json())
        .then(response => {
          //console.log(response.count);
          setAssetListing(response.assets);
          setAssetCount(response.count);
          setPagesCount(Math.ceil(response.count / imageperPage));
        })
        .then(response => notify())
        
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  //this.state = { response };
        })
   },[])

   useEffect(() => {
     return history.listen((location) => { 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  //this.state = { response };
            })
      }) 
   },[history])
   
   useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  //this.state = { response };
            })
   },[])


	const ITEM_HEIGHT = 36;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorEll, setAnchorEll] = React.useState(null);
	const open = Boolean(anchorEl);
	const openl = Boolean(setAnchorEll);
	const handleClick = (event) => {
		//console.log('logged: ',event);
		setAnchorEl(event.currentTarget);
		setAssetID(event.currentTarget.value);
	};

	const openEditor = (event) => {
		jQuery('#grid_previewpan').animate({
            bottom: '0'
        },1000);
	}
  const handleTextFamily = () => {
    const elem = jQuery("#draggable span");
    elem[0].style.setProperty('font-family', jQuery('#text_fontfamily').val(), 'important');


  }
  const handleTextColor = () => {
    jQuery('#draggable').css('color','#'+jQuery('#text_fontcolor').val());

  }
  const handleTextFontSize = () => {
    jQuery('#draggable').css('font-size',jQuery('#text_fontsize').val()+'pt');
    //jQuery('#width').val(jQuery('#preview_img_src').width());

  }
	const handleTextDrag = () => {
    jQuery('#xcoord').val('200');
    jQuery('#ycoord').val('50');
    jQuery('#draggable').css('left','200px')
    jQuery('#draggable').css('top','50px')
    //console.log(jQuery('#draggable').css('left'));
    jQuery('#draggable span').text(jQuery('#text_field').val()); 
    jQuery('.ui-widget-content').show();
    jQuery('#width').val(jQuery('#preview_img_src').width());
    jQuery('#height').val(jQuery('#preview_img_src').height());
    jQuery('#url').val(jQuery('#preview_img_src').attr('src'));
  }
	const handleCloses = () => {
		setAnchorEl(null);
	};
	const handleClose = (event) => {
	//console.log(event.currentTarget.dataset.id);
	//console.log(event.currentTarget.value);
  		const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ assetid:currentassetid, teamid: teaminfo, channelid:event.currentTarget.value, channel:match.params.name, page: 1 })
        };
        fetch(APIURL+'assets/move', requestOptions)
        .then(response => response.json())
        .then(response => {
          setAssetListing(response.assets);
          setAssetCount(response.count);
          setPagesCount(Math.ceil(response.count / imageperPage));
        })
        .then(response => setAnchorEl(null))
        .then(response => { //downloadAsset(response.url,response.message)
          movenotify();
        })


    //setAnchorEl(null);
  };

  const submitWatermarkData = (channelID) => {
    const watermarkUrl      = jQuery('#watermark_url').val();
    const originalUrl       = jQuery('#original_url').val();
    const xCoordWatermark   = jQuery('#xcoord_watermark').val();
    const yCoordWatermark   = jQuery('#ycoord_watermark').val();
    const originalWidth     = jQuery('#original_width').val();
    const originalHeight    = jQuery('#original_height').val();
    const watermarkWidth    = jQuery('#watermark_width').val();
    const originalHieght    = jQuery('#watermark_height').val();

    const requestOptions    = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ watermark_url:watermarkUrl, original_url: originalUrl, xcoord_watermark:xCoordWatermark, ycoord_watermark:yCoordWatermark, 
          original_width:originalWidth, original_height:originalHeight, watermark_width:watermarkWidth, watermark_height:originalHieght,
          userid: userinfo[0].id, channelid: channelID, assetid: state.isPaneTileId   })
      };
      fetch(APIURL+'editor/watermarkimage', requestOptions)
      .then(response => response.json())
      .then(response => { //downloadAsset(response.url,response.message)
        copynotify();
      })
  }

  const submitTextData  = (channelID) => {
      //console.log(channelID);
      //notify();
      //return;
      const textWidth        = jQuery('#width').val();
      const textHeight       = jQuery('#height').val();
      const textXCoord       = jQuery('#xcoord').val();
      const textYCoord       = jQuery('#ycoord').val();
      const textImgUrl       = jQuery('#url').val();
      const assetTitle       = jQuery('#preview_title').val();
      const textContent      = jQuery('#text_field').val();
      const textFontSize     = jQuery('#text_fontsize').val();
      const textColor        = jQuery('#text_fontcolor').val();
      const textFontFamily   = jQuery('#text_fontfamily').val();
      const requestOptions   = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ width: textWidth, height: textHeight, xcoord: textXCoord, ycoord: textYCoord, url: textImgUrl, content: textContent,
          fontsize: textFontSize, color: textColor, family: textFontFamily, userid: userinfo[0].id, channelid: channelID, assetid: state.isPaneTileId })
      };
      fetch(APIURL+'editor/textimage', requestOptions)
      .then(response => response.json())
      .then(response => { //downloadAsset(response.url,response.message)
        copynotify();
      })


    //setAnchorEl(null);
  };

  const submitGifData = (channelID) => {
      //console.log(channelIDForChildren, channelID);
      //return;
      //document.getElementById('circularProgressBar').style.display = 'block'; 
      const layername   = jQuery('#layername').val();
      const requestOptions   = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({
            //sourcechannel: channelIDForChildren, 
            targetchannel: channelID,
            //bucket: channelID,
            assetid: state.isPaneTileId,
            url:btoa(jQuery('#preview_img_src').attr('src')),
            userid: userinfo[0].id,
            teamid: teaminfo,
            layer:btoa(layername),
            time:Math.random(),
            page: 1,


            /*folderId:'', 
            id:btoa(state.isPaneTileId),
            layer:btoa(layername), 
            url:btoa(jQuery('#preview_img_src').attr('src')),
            time:Math.random(), 
            userid: userinfo[0].id, 
            channelid: channelID, 
            assetid: state.isPaneTileId, 
            teamid: teaminfo*/ 
          })
      };
     // console.log(requestOptions); return;
      fetch(APIURL+'editor/animateimage', requestOptions)
      .then(response => response.json())
      .then(response => { //console.log(response); //downloadAsset(response.url,response.message)
        setAssetListing(response.assets);
        setAssetCount(response.count);
        setPagesCount(Math.ceil(response.count / imageperPage));
        animatenotify();
        document.getElementById('circularProgressBar').style.display = 'none'; 
      })


    //setAnchorEl(null);
  };

  jQuery( function() {
      jQuery('#draggable').hide(); 
      jQuery('#draggable2').hide(); 
      jQuery('#remove_text').click(function(e){
        jQuery('#draggable').hide(); 
      });
      jQuery('#remove_watermark').click(function(e){
        jQuery('#draggable2').hide(); 
      });

    /*jQuery('.add_button').click(function(e){
      jQuery('.ui-widget-content').show();
      jQuery('.ui-widget-content').css('top',150).css('left',250) ;
      jQuery('.text-tools').show(); 
    });*/
   /* jQuery( "#resizeableimage" ).resizable({
      containment: '#image_container',
      stop: function( event, ui ) {
          //jQuery('#xcoord_watermark_width').val(jQuery("#resizeableimage").width());
          //jQuery('#ycoord_watermark_height').val(jQuery("#resizeableimage").height());
 
      } 
    });*/
      jQuery( "#draggable2" ).draggable({
        containment: '#image_container',

        start: function(event, ui) {
          //console.log(jQuery('#preview_img_src').width());
          jQuery('#watermark_width').val(jQuery("#resizeableimage").width());
              jQuery('#watermark_height').val(jQuery("#resizeableimage").height());

        },
        drag: function(event, ui) {
              var poss = ui.position;
              jQuery('#xcoord_watermark').val(poss.left);
              jQuery('#ycoord_watermark').val(poss.top);

        }
      });

     jQuery( "#resizeableimage" ).resizable({
       containment: '#image_container',
        stop: function( event, ui ) {
            jQuery('#watermark_width').val(jQuery("#resizeableimage").width());
            jQuery('#watermark_height').val(jQuery("#resizeableimage").height());
   
        } 
     });

      jQuery( "#draggable" ).draggable({
      containment: '#image_container',
      create: function( event, ui ) {
          // console.log('create');

      },
      start: function(event, ui) {
        
        //console.log('start');
        //console.log(jQuery('#preview_img_src').width());
      },
      drag: function(event, ui) {
            var pos = ui.position;
            jQuery('#xcoord').val(pos.left);
            jQuery('#ycoord').val(pos.top);
            jQuery('#width').val(jQuery('#preview_img_src').width());
            jQuery('#height').val(jQuery('#preview_img_src').height());
        }
    });
  });

  const handleTextPane = () => {

    jQuery('.add-text-icon').toggleClass('active');
    jQuery(".add-image-icon").removeClass('active');
    jQuery(".show-info-icon").removeClass('active');
    jQuery("#mySidenav03").removeClass("open");
    jQuery("#mySidenav02").removeClass("open");
    jQuery("#mySidenav01").toggleClass("open"); 

  }

  const handleImagePane = () => {

    jQuery('.add-image-icon').toggleClass('active');
    jQuery(".add-text-icon").removeClass('active');
    jQuery(".show-info-icon").removeClass('active');
    jQuery("#mySidenav01").removeClass("open");
    jQuery("#mySidenav03").removeClass("open");
    jQuery("#mySidenav02").toggleClass("open");   

  }

  const handleDescriptionPane = () => {

   jQuery('.show-info-icon').toggleClass('active');
    jQuery(".add-text-icon").removeClass('active');
    jQuery(".add-image-icon").removeClass('active');
    jQuery("#mySidenav01").removeClass("open");
    jQuery("#mySidenav02").removeClass("open");
    jQuery("#mySidenav03").toggleClass("open");  

  }

  const handleClosePane = () => {

   jQuery(".add-text-icon").removeClass('active');
    jQuery(".add-image-icon").removeClass('active');
    jQuery(".show-info-icon").removeClass('active');
    jQuery("#mySidenav01").removeClass("open");
    jQuery("#mySidenav02").removeClass("open");
    jQuery("#mySidenav03").removeClass("open");

  }

  var size;
        var dataid;  
      jQuery(document).ready( function(e) {

      
        /*jQuery('.imageMainCard').hover( function() {
            jQuery(this).find('.cardHeader').css('display','block !important');
        })*/
    	   
        jQuery(".facebook_crop_ad").click(function(){
            jQuery('#gif_preview_src').css('width','').css('height','').css('display','none');
            var ratio = jQuery(this).attr('id').replace('_','/');
            dataid = jQuery(this).attr('data-id');
            var boxheight = jQuery('#orignal_img_src').width();
            jQuery('#preview_img_src').Jcrop({
                boxWidth: boxheight,
                setSelect: [0, 0, 400, 400],
                aspectRatio: eval(ratio),
                allowResize: true,
                bgOpacity: 0.4,
                onSelect: function(c){
                	   //console.info(c.w);
                     jQuery('#size_x').val(c.x);
                    jQuery('#size_y').val(c.y);
                    jQuery('#size_w').val(c.w);
                    jQuery('#size_h').val(c.h);
                    size = {x:c.x,y:c.y,w:c.w,h:c.h};
                    
                    jQuery("#crop").css("visibility", "visible");     
                }
            });
        });



        
    })

const submitCropData  = (channelID, channelSlug, assetId, type) => {
  setShowCropProgress(true);
  const requestOptions   = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
      body: JSON.stringify({ 
        targetchannel: channelID, 
        userid: userinfo[0].id,
        teamid: teaminfo,
        assetid: state.isPaneTileId,

      })
  };
  jQuery('#targettype').val(type);

  fetch(APIURL+'editor/editordata', requestOptions)
  .then(response => response.json())
  .then(response => { 
   // console.log(response);
    //downloadAsset(response.url,response.message)targettype
    jQuery('#bucketName').val(response.targetBucket);
    jQuery('#folderName').val(response.folderName);
    jQuery('#targetfileName').val(response.targetfileName);
  })
  //jQuery('#bucketName').val(channelSlug);
  jQuery('#folderId').val(channelSlug);
  jQuery('#channelID').val(channelID);
  jQuery('.PinturaButtonExport').click();
 // const bloburlofimage = jQuery('#bloburlofimage').val();
 // const keyofimage = jQuery('#keyofimage').val();

  
};

/*const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]; // Assuming single file upload
    console.log("file", file);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filetype', file.type);
    formData.append('filesize', file.size);
    formData.append('filename', file.name);
    formData.append('team', teaminfo);
    formData.append('userid', userinfo[0].id);
    formData.append('channel', '1-general-1696237429');

    // Replace 'your-upload-endpoint' with your server's upload endpoint
    const uploadUrl = APIURL+'channel/uploadmedia';

    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
       //console.log("eee", event);
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        setUploadProgress(percentage);
      }
    };

    xhr.open('POST', uploadUrl, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        console.log('Upload complete');
      }
    };

    xhr.send(formData);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });*/

  /*const [uploadProgress, setUploadProgress] = useState(0);
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*,video/*',
		multiple: true,
		onDrop: useCallback((acceptedFiles) => {
			setShowProgress(true);
			const files  = acceptedFiles.map(file => {
				let reader = new FileReader();
        console.log("reader", reader);
				reader.readAsDataURL(file);
        reader.onprogress = (event) => {
          console.log("eee", event);
            if (event.lengthComputable) {
              const percentage = (event.loaded / event.total) * 100;
              setUploadProgress(percentage);
            }
          };

				reader.onload = (e) => {
					//console.log("eee", e);
          
					const requestOptions = {
			            method: 'POST',
			            headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
			            body: JSON.stringify({ 
			            	file:     e.target.result,
			            	filetype: file.type,
			            	filesize: file.size,
			            	filename: file.name,
			            	team:     teaminfo,
			            	userid:   userinfo[0].id,
			            	channel:  match.params.name
			            })
			        };
					fetch(APIURL+'channel/uploadmedia', requestOptions)
					.then(response => response.json())
          .then(response => {
            console.log(response);
            setAssetListing(response.assets);
            setAssetCount(response.count);
          })
         
					.then(response => setShowProgress(false))

            		//console.log(response);
            		
			      
				} 
			});
			
		}, [])
		
	});*/
  const [uploadProgress, setUploadProgress] = useState(0);
const { getRootProps, getInputProps } = useDropzone({
  accept: 'image/*,video/*',
  multiple: true,
  onDrop: useCallback((acceptedFiles) => {
    setShowProgress(true);

    acceptedFiles.forEach(file => {
      let reader = new FileReader();
      console.log("match.params.name", match.params.name);
      reader.onload = (e) => {
        const jsonData = {
          file: e.target.result,
          filetype: file.type,
          filesize: file.size,
          filename: file.name,
          team: teaminfo,
          userid: userinfo[0].id,
          channel: match.params.name,
        };
        /*const formData = new FormData();
        formData.append('file', file);
        formData.append('filetype', file.type);
        formData.append('filesize', file.size);
        formData.append('filename', file.name);
        formData.append('team', teaminfo);
        formData.append('userid', userinfo[0].id);
        formData.append('channel', '1-general-1696237429');*/

        const xhr = new XMLHttpRequest();
        xhr.open('POST', APIURL + 'channel/uploadmedia', true);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentage = (event.loaded / event.total) * 100;
            console.log('Upload progress: ' + percentage + '%');
            setUploadProgress(percentage.toFixed(2));
            // Update the progress bar with the percentage (assuming you have an HTML progress element)
            //document.getElementById('progress-bar').value = percentage;
          }
        };

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            var responseJson = JSON.parse(xhr.response);
            console.log('Upload complete', );
            setShowProgress(false);
            setAssetListing(responseJson['assets']);
            setAssetCount(responseJson['count']);
            setPagesCount(Math.ceil(responseJson['count'] / imageperPage));
            
            setUploadProgress(0);
          }
        };

       xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(jsonData));
      };

      reader.readAsDataURL(file);
    });
  }, [])
});

	function search(nameKey, myArray){
		for (var i=0; i < myArray.length; i++) {
		    if (myArray[i].name === nameKey) {
		        return myArray[i];
		    }
		}
	}
	
	var resultObject = search("Stocky Editor", userAppsListing);
	//const editorEnabled = //userAppsListing.find(x => x.appid === 'Editor').name;
	//console.log(resultObject);
	//var result = Object.keys(userAppsListing).map((key) => [Number(key), userAppsListing[key]]);

	//	console.log(result);
	
	function applyGif(valuess){
   
		var width = jQuery('#preview_img_src').width();
		var height = jQuery('#preview_img_src').height();
	
    var randomnumber = Math.random();
    jQuery('#layername').val(valuess)
    jQuery('#preview_img_src').css('position','absolute');
    jQuery('#gif_preview_src').css('width',width+'px').css('height',height+'px');
    jQuery('#gif_preview_src').attr('src','https://s3.amazonaws.com/files.stocky.ai/uploads/2021/04/'+valuess+'.gif');

			};
    function handleGifAfterOpen(){
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
          body: JSON.stringify({ 
            assetid: state.isPaneTileId,
            channel: match.params.name, 
            userid: userinfo[0].id 
          })
      };



    

    fetch(APIURL+'asset/detail', requestOptions)
    .then(response => response.json())
    .then(response => {
      
      jQuery('#preview_img_src').attr('src',response.assetdetail[0].presigned_url);
     
    })
  }

  function handleVisionAfterOpen(){
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
          body: JSON.stringify({ 
            assetid: state.isPaneTileId,
            channel: match.params.name, 
            userid: userinfo[0].id 
          })
      };

    

    

    fetch(APIURL+'asset/detail', requestOptions)
    .then(response => response.json())
    .then(response => {
      
      jQuery('#preview_img_src').attr('src',response.assetdetail[0].presigned_url);

        const visionRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
            body: JSON.stringify({ 
              assetid: state.isPaneTileId
            })
        };


        fetch(APIURL+'asset/vision', visionRequestOptions)
        .then(response => response.json())
        .then(response => {
          //console.log(response.vision);
          //setVisionMeta(response.vision);
          var jsonObj = JSON.parse(response.vision);
          console.log(jsonObj); 
          setVisionMetaLabel(jsonObj.labelAnnotations);
          setVisionMetaFace(jsonObj.faceAnnotations);
          setVisionMetaObject(jsonObj.localizedObjectAnnotations);
          setVisionMetaColor(jsonObj.dominantColors);
          //var jsonColorObj = JSON.parse(response.vision.dominantColors);
          //console.log(jsonColorObj);
          //setVisionMetaColor(jsonColorObj);
        })

     
    })
  }

	function handleAfterOpen(){
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
			body: JSON.stringify({ 
        assetid: state.isPaneTileId,
        channel: match.params.name, 
        userid: userinfo[0].id 
			})
		};

    

		fetch(APIURL+'asset/detail', requestOptions)
		.then(response => response.json())
		.then(response => {
			//console.log(response.presigned_url);
      //setEditorImage(null);
      const myImageUrl = response.assetdetail[0].presigned_url;

      //setEditorImage(myImageUrl.replace('https','http'));
      setEditorImage(myImageUrl);
      jQuery('#preview_img_src').attr('src',response.assetdetail[0].presigned_url);
			jQuery('#preview_title').html(response.assetdetail[0].name);
			jQuery('#preview_type').html(response.assetdetail[0].file_extension);
			jQuery('#preview_filesize').html(bytesToSize(response.assetdetail[0].file_size));
			const theImage = new Image();
			theImage.src = response.assetdetail[0].url;
			jQuery('#preview_resolution').html(theImage.width+' x '+theImage.height);
			//jQuery('#preview_description').html(longdesc);
		})
		
		//jQuery('#preview_img_src').attr('src',response.url); 
		////.delay(600).queue(function() {jQuery(this).attr('src','https://teams-1618404603.s3.amazonaws.com/general/black-dog-on-gray-asphalt-road-during-daytime-1621334081.jpg'); })
	}

	function bytesToSize(bytes) {
	   var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	   if (bytes == 0) return '0 Byte';
	   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	   return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
	}
  const options = {
    buttons: {
      showThumbnailsButton: false,
      showNextButton: false,
          showPrevButton: false,
          showAutoplayButton: false,
          showDownloadButton: false,
      },
      thumbnails: {
        showThumbnails: false,
      },
  }

	function openPane(tileId){

		//console.log(imgsrc);
		if(jQuery('#preview_img_src').data('Jcrop')){
			jQuery('#preview_img_src').width('');
			jQuery('#preview_img_src').height('');
			jQuery('#preview_img_src').data('Jcrop').destroy();
		}
		setState({ isPaneOpen: true, isPaneTileId: tileId });
    
		//setState({ isPaneTileUrl: imgsrc });
		//jQuery('#preview_img_src').attr('src',imgsrc);
	}

  function openGifPane(tileId){

    //console.log('tileId',tileId);
    setAssetID(tileId);
    setState({ isGifPaneOpen: true, isPaneTileId: tileId });
    
    //setState({ isPaneTileUrl: imgsrc });
    //jQuery('#preview_img_src').attr('src',imgsrc);
  }

  function openVisionPane(tileId){

    //console.log('tileId',tileId);
    setAssetID(tileId);
    setState({ isVisionPaneOpen: true, isPaneTileId: tileId });
    
    //setState({ isPaneTileUrl: imgsrc });
    //jQuery('#preview_img_src').attr('src',imgsrc);
  }


  const handleFileSelector = (event) => {
    //console.log(event.target.files[0]);
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload=(e)=>{
     // console.log(e.target.result);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
          body: JSON.stringify({ 
            file: e.target.result,
            filetype: file.type,
            filesize: file.size,
            filename: file.name,
          })
      };

      fetch(APIURL+'editor/uploadwatermark', requestOptions)
      .then(response => response.json())
      .then(response => {
        jQuery('#draggable2').show();
        jQuery('#draggable2 .ui-wrapper').css('height','auto').css('width','auto');
        jQuery('#resizeableimage').css('height','auto').css('width','auto');
        jQuery('#resizeableimage').attr('src', response.message);
        jQuery('#watermark_url').val(jQuery('#resizeableimage').attr('src'));
        jQuery('#original_url').val(jQuery('#preview_img_src').attr('src'));
        jQuery('#original_width').val(jQuery('#preview_img_src').width());
        jQuery('#original_height').val(jQuery('#preview_img_src').height());
        //console.log(response.message);
      })
    }

    //console.log(fd);
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
    //   body: JSON.stringify({ 
    //     image: event.target.files[0],
    //   })
    // };
    // fetch(APIURL+'editor/uploadwatermark', requestOptions)
    // .then(response => response.json())
    // .then(response => {
    //   console.log(response);
    // })
          
  }

  function handleAssetScroll(number){
      setCurrentNumber(number)
      //jQuery('#unsplashPhotoDiv').css('background','rgba(0,0,0,0.6)').css('position','absolute');
      //const target = event.target;
      
      //
      
      var pagenumber = number; //jQuery('#assetpagenumber').val();
      const nextpagenumber = eval(jQuery('#assetpagenumber').val())+1;
     // if(target.scrollHeight - target.scrollTop === eval(target.clientHeight)){
        //jQuery('#asset_loading').show();
       // console.log("scrolled to last"+pagenumber);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ channel: match.params.name, userid: userinfo[0].id, teamid: teaminfo, page: pagenumber  })
        };
        fetch(APIURL+'assets/listing', requestOptions)
          .then(response => response.json())
          .then(response => {
           // const finalArray = assetlisting.concat(response.assets);
           // setAssetListing(finalArray);
          // jQuery('#assetpagenumber').val(nextpagenumber);
          // jQuery('#asset_loading').hide();
            setAssetListing(response.assets);
        })
       //const nextpagenumber = eval(jQuery('#assetpagenumber').val())+1;
        //jQuery('#assetpagenumber').val(eval(jQuery('#assetpagenumber').val())+1)

     // }
  }
  return (
  	<div >
  		<div className="mainWrapper">
			<TopNav />
		  <ToastContainer />
			<Box display="flex" flexDirection="row"  className="bradecrumbbox">
				<Box width="100%">
          <Box className={classes.subBar} pl={1}  pr={1}  >
            <Box>
              <Grid container spacing={0}>
                <Grid style={{border:'0px solid red;'}} item xs={8}>
                  <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
                    <Typography className="breadcrumbMainHeading" >
                    <b>{teamName}</b>
                    </Typography>
                    <NavLink to={"/channel/"+bucketSlugForBread} ><Typography  className="breadcrumbSubHeading">
                    <b>{bucketName} </b>
                    </Typography></NavLink>
                    {folderName ? <NavLink to={"/channel/"+bucketSlugForBread+"/"+folderSlug} ><Typography  className="breadcrumbSubHeading">
                    <b>{folderName} </b>
                    </Typography></NavLink> : ''}
                    {subFolderName ? <NavLink to={"/channel/"+bucketSlugForBread+"/"+folderSlug+"/"+subFolderSlug} ><Typography  className="breadcrumbSubHeading">
                    <b>{subFolderName} </b>
                    </Typography></NavLink> : ''}
                  </Breadcrumbs>
                </Grid>
                <Grid className={classes.root}  style={{border:'0px solid red'}} item xs={4}>
                  <Box pt={3}>
                   {showProgress ?
                    <>
                   <progress className="progressBar" value={uploadProgress} max="100"></progress>
                    <div>Uploading...{uploadProgress}%</div>
                    </>
                    : '' }

                    
                  </Box>
                </Grid>
              </Grid>
            </Box> 
          </Box>
          {userPermissions.upload_media==true ? 
          <Box className={classes.uploadBar} pl={1}  pr={1} p={1} >

            <Box {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Typography className={classes.draganddrop}>
                <div class='uploadmediatext'>
                  &nbsp;Drop files here or click to upload
                </div>
              <div style={{float:'right'}}>
                <img src="/uploadicon.svg"  style={{fill:'#191925',fontSize:'30px', width: '36px', height: '42px'}} />
              </div>
              </Typography>
            </Box>
          </Box> : '' }
          <Box>

          </Box>
          {directorylisting ? <Box className={classes.folderBar}>
            {directorylisting.map((value) => (
              <div><NavLink exact  to={bucketSlugForUrl+"/"+value.slug}  activeClassName="channel-active" >
                <div className={classes.channelicon} style={{marginLeft: '20px'}}><FolderIcon style={{ fontSize: 15 }}  /></div>
                <div className={classes.channellink} >{value.name} ({value.totalasset})</div>
              </NavLink></div>
              
            ))}
            </Box>
          : '' }
          <div style={{clear: 'both'}}></div>
          <Box display="flex" className={classes.filterSection}  >
            <Box width="50%" fontWeight="fontWeightBold" >
              <Typography variant="subtitle2" className={classes.numberImages}  ><b>{channelName} ({assetCount} Files)</b></Typography>
            </Box>
            <Box width="50%" fontWeight="fontWeightBold" align="right" style={{paddingRight: '20px'}} >
               <Typography variant="subtitle2" className={classes.numberPages}  >
               {totalPages > 1 ? 
                (
                  <>
                  {numbers.map((number, index) => (
                    <span key={index} style={{marginRight: '10px'}}>{number==currentNumber ? ( <u className={classes.currentPage}> {number+'     '} </u> ) : ( <span className={classes.activePages} onClick={() => handleAssetScroll(number) }>{number+'    '}</span>)} </span>
                  ))}
                    <>. . . </>
                  </>
                )
                

                : ''}
               </Typography>
              
            </Box>
          </Box>

					
					
					
					
					<Box pl={1}  pr={1} id="assetsBox">
            <input type="hidden" id="assetpagenumber" value={currentNumber}  />
						<Box  style={{maxHeight:window.innerHeight-272, overflow:'auto',  scrollbarWidth: 'thin'}}>
    
						    {assetlisting.map((tile) => (
						      <div className="cardParentDiv">

						    <Card className="imageMainCard" >
						      <CardHeader
                  className="cardHeader" 
                    avatar={
                      <Avatar aria-label="recipe" className="imgAvatar">
                        {tile.name.substring(1,0)}
                      </Avatar>
                    }
                    
                    title={<Typography className="headerTitle">{tile.name.substring(14,0)}</Typography>}
                    subheader={<Typography className="headerDate">{tile.uploaded_at}</Typography>}
                  />
                  <div style={{backgroundColor:'#000'}}>
                  <SRLWrapper  options={options}>
                  <a href={tile.presigned_url} >
                    <img style={{width:'100%', height: '200px', objectFit: 'cover'}} src={tile.presigned_url} className="cardImage" />
                  </a>
                   </SRLWrapper>
                   </div>

						      
						      
						      <CardActions disableSpacing className="imageActions">
						        
						        {userRole=='2' ? <Tooltip className={classes.assetActions} title="Delete" placement="top"><IconButton aria-label="add to favorites"> <Delete onClick={() => deleteAsset(tile.id)} /> </IconButton></Tooltip> : '' }
						        
                    {userPermissions.share_media==true ? <Tooltip className={classes.assetActions} title="Share" placement="top">
						        <IconButton aria-label="share">
						          <Share value={tile.id}  onClick={() => handleDialogClickOpen(tile.presigned_url)} />
						        </IconButton>
						        </Tooltip>  : ''}
						        
                    {userPermissions.download_media==true ? <Tooltip className={classes.assetActions} title="Download" placement="top">
						        <IconButton aria-label="share">
						          <CloudDownload onClick={() => downloadAsset(tile.url,tile.name)}  />
						        </IconButton>
						        </Tooltip>  : ''}
						        
                    {userPermissions.move_media==true ? <Tooltip className={classes.assetActions} title="Move" placement="top">
						        <IconButton aria-label="more"
							        aria-controls={"long-menu"}
							        aria-haspopup="true"
							        value={tile.id}
							        onClick={handleClick}>
						          <ExitToAppIcon />
						          
						        </IconButton>
						        </Tooltip>  : ''}
                    
						        {userPermissions.edit_media==true ? <Tooltip className={classes.assetActions} title="Editor" placement="top">
						        <IconButton aria-label="more"
							        aria-controls={"long-menu"}
							        aria-haspopup="true"
							        value={tile.id}
							        onClick={() => openPane(tile.id) }>
						          <PictureInPictureAltIcon />
						        </IconButton>
						        </Tooltip>  : ''}

						        {userPermissions.edit_media==true ? <Tooltip className={classes.assetActions} title="Gif" placement="top">
                    <IconButton style={{display:'none'}} aria-label="more"
                      aria-controls={"long-menu"}
                      aria-haspopup="true"
                      value={tile.id}
                      onClick={() => openGifPane(tile.id) }>
                        <Gif />
                    </IconButton>
                    </Tooltip>  : ''}

                    {userPermissions.google_vision==true ? 
                      tile.vision_meta == 'fetched' ?
                      <Tooltip className={classes.assetActions} title="Vision" placement="top">
                        <IconButton aria-label="more"
                          aria-controls={"long-menu"}
                          aria-haspopup="true"
                          value={tile.id}
                          onClick={() => openVisionPane(tile.id) }>
                          <img src="/vision-icon.png" /> 
                        </IconButton>
                      </Tooltip> 
                      : ''
                    : ''}

						      </CardActions>
						      
						    </Card>
						    </div>
						      ))}
                  
    						    <Menu
    						        id={"long-menu"}
    						        anchorEl={anchorEl}
    						        onClose={handleCloses}
    						        keepMounted
    						        open={open}
    						        PaperProps={{
    						          style: {
    						            maxHeight: ITEM_HEIGHT * 4.5,
    						            
                            border: '1px solid #CCC'
    						          },
    						        }}
    						      >

                        {channellisting.map((val,key) => {
                        return <div><MenuItem disabled={val.id == channelIDForChildren} className={classes.moveMenuItem} value={val.id} onClick={handleClose}>
                        {val.name}
                        </MenuItem>
                        <Folder assetID={currentassetid} matchParam={match.params.name} currentChannel={channelIDForChildren} bucketID={val.id} /> </div>

                      })}
    					        
    					      </Menu>

					      
			         
				      <Dialog
				        fullScreen={fullScreen}
				        open={opend}
				        onClose={handleDialogClose}
				        aria-labelledby="responsive-dialog-title">
				        <DialogTitle id="responsive-dialog-title">{"Share Your Image"}</DialogTitle>
				        <DialogContent>
				          <DialogContentText>
				          	<Twitter  link={currentasseturl} />
				          	<Pinterest  link={currentasseturl} />
				          	<Facebook  link={currentasseturl} />
				            <Linkedin link={currentasseturl} />
				          </DialogContentText>
				        </DialogContent>
				        <DialogActions>
				          
				          <Button onClick={handleDialogClose} color="primary" autoFocus>
				            Close
				          </Button>
				        </DialogActions>
				      </Dialog>
						    </Box>

			    		
			    	</Box>
				</Box>
			</Box>
			
		</div>
    <SlidingPane
    closeIcon={<KeyboardArrowDownIcon />}
    className="some-custom-class"
    overlayClassName="some-custom-overlay-class"
    isOpen={state.isGifPaneOpen}
    title=" "
    hideHeader="false"
    from="bottom"
    width="100%"
    subtitle=" "
    onAfterOpen={handleGifAfterOpen}
    onRequestClose={() => {
      // triggered on "<" on left top click or on outside click
      setState({ isGifPaneOpen: false });
    }}
    >
    <Grid container style={{height:'100%', padding:'10px'}}>
    <Grid xs={9} style={{textAlign: 'center'}}>
    <img id="preview_img_src" src="#" />
    <img src="" id="gif_preview_src" />
    </Grid>
    <Grid xs={3}>
      <div style={{float:'right', padding: '5px', cursor: 'pointer'}} >
        <img title="Close" onClick={()=> setState({ isGifPaneOpen: false }) } width="13" src="/close.png" />
      </div>
      <div id="gif_section" style={{padding: '0px 0px 0px 0px',display:'block '}}>
       <div class="col-inner"><div style={{fontSize:'25px', fontWeight: 'bold', marginTop: '5px', color: '#FFD843'}}>Add Animation</div></div>
       <div class="col-inner">
          <div class="row row-collapse align-center" id="row-1692646698">
                <div class="col medium-4 small-12 large-4" style={{width:'33.33%', float:'left'}}>
                  <div class="col-inner fm-btn fm-twinkle" align="center">
                      <a href="#" onClick={() => { applyGif('twinkle')}} ><span>Twinkle</span></a>
                    </div>
                </div>
                
                <div class="col medium-4 small-12 large-4" style={{width:'33.33%', float:'left'}}>
                  <div class="col-inner fm-btn fm-snow" align="center">
                      <a href="#"  onClick={() => { applyGif('snow')}}><span>Snow</span></a>
                    </div>
                </div>
                
                <div class="col medium-4 small-12 large-4" style={{width:'33.33%', float:'left'}}>
                  <div class="col-inner fm-btn fm-crystal" align="center">
                      <a href="#"  onClick={() => { applyGif('crystal')}} ><span>Crystal</span></a>
                    </div>
                </div>
                
                <div class="col medium-4 small-12 large-4" style={{width:'33.33%', float:'left'}}>
                  <div class="col-inner fm-btn fm-sparkling" align="center">
                      <a href="#"  onClick={() => { applyGif('sparkling')}} ><span>Sparkling</span></a>
                    </div>
                </div>
                
                <div class="col medium-4 small-12 large-4" style={{width:'33.33%', float:'left'}}>
                  <div class="col-inner fm-btn fm-hearts" align="center">
                      <a href="#" onClick={() => { applyGif('hearts')}}><span>Hearts</span></a>
                    </div>
                </div>
                
                <div>
                  <div style={{clear:'both'}}> </div>
                  <input type="text" name="layername" id="layername" />
                    <div className="textCopyToButton" style={{float:'left', width: '300px'}} onMouseOver={() => { onMouseOverEVent('channelListForGif')}} onMouseLeave={() => { onMouseOutEVent('channelListForGif')}}>
                      <div style={{height: '40px', lineHeight: '40px', cursor: 'pointer'}}>
                        <div style={{float: 'left', paddingLeft: '11px', fontWeight: 'bold'}}>Save Edited </div>
                        <div style={{float:'right', padding: '6px', height: '28px', borderLeft: '1px solid #d7d0ce2e'}}><ArrowDropDownIcon /></div>
                      </div>
                      <div id="channelListForGif" >
                        <ul className="toggleChannelList">
                          {channellisting.map((val,key) => {
                            return <div><li onClick={() => { submitGifData(val.id)}}>
                              {val.name}
                            </li>
                            <AnimateFolder assetID={currentassetid} matchParam={match.params.name} currentChannel={channelIDForChildren} bucketID={val.id} /> </div>
                          })}  
                        </ul>
                      </div>
                    
                  </div>
                </div>
                <div style={{display: 'none'}} id="circularProgressBar"><Progress /> </div>

             </div>
              
         </div>
    </div>
    </Grid>
    
    </Grid>


    </SlidingPane>

    <SlidingPane
    closeIcon={<KeyboardArrowDownIcon />}
    className="some-custom-class"
    overlayClassName="some-custom-overlay-class"
    isOpen={state.isVisionPaneOpen}
    title=" "
    hideHeader="false"
    from="bottom"
    width="100%"
    subtitle=" "
    onAfterOpen={handleVisionAfterOpen}
    onRequestClose={() => {
      setVisionMetaLabel();
      setVisionMetaFace();
      setVisionMetaObject();
      setVisionMetaColor();
      // triggered on "<" on left top click or on outside click
      setState({ isVisionPaneOpen: false });
      setValue(0);
    }}
    >
    <Grid container style={{height:'100%', padding:'10px'}}>
      <Grid xs={1} style={{textAlign: 'center'}}>
      </Grid>
      <Grid xs={4} style={{textAlign: 'right', paddingTop:'70px'}}>
         <img id="preview_img_src" src="#" style={{boxShadow: '1px 1px 5px #FFF'}} />
      </Grid>
      <Grid xs={1} style={{textAlign: 'center'}}>
      </Grid>
      <Grid xs={6} style={{color: '#FFFFFF'}}>

          <Tabs
            value={value}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            {visiomMetaLabel ? <Tab value={0} className={classes.TabTitle} label="Labels" {...a11yProps(0)} /> : ''}
            {visiomMetaFace ? <Tab value={1} className={classes.TabTitle} label="Faces" {...a11yProps(1)} /> : ''}
            {visiomMetaObject ? <Tab value={2} className={classes.TabTitle} label="Objects" {...a11yProps(2)} /> : ''}
            {visiomMetaColor ? <Tab value={3} className={classes.TabTitle} label="Colors" {...a11yProps(3)} /> : ''}
          </Tabs>
          {visiomMetaLabel ? 
            <TabPanel value={value} index={0}>
            
              <div style={{overflowX:'auto', height: '400px', padding:'10px', scrollbarWidth: 'thin', boxShadow:'0px 0px 6px #FFFFFF0F'}}>
              <div>
              
             {visiomMetaLabel.map((val) => {
              const ScoreVal = Math.round(val.score*100)+'%';
              var ScoreWidth = 'width';
              const customStyles = {
                  borderTop: '4px solid #3F8AFF',
                  clear: 'both',
                  width: ScoreVal
              } 
              return <div style={{height:'35px'}}>
              <div style={{float: 'left'}}>{val.description}</div>
              <div style={{float: 'right', textAlign: 'right'}}>{ScoreVal}</div>
              <div style={{width: '100%', backgroundColor: '#FFF', clear: 'both', height:'4px'}}><div style={{...customStyles}}>&nbsp;</div></div>
              </div>
             })}
            </div><div style={{clear: 'both'}}></div></div>
          </TabPanel>
           : ''}
           {visiomMetaFace ?
          <TabPanel value={value} index={1}>
              
            <div style={{overflowX:'auto', height: '400px', padding:'10px', scrollbarWidth: 'thin', boxShadow:'0px 0px 6px #FFFFFF0F'}}>
              <div>

              
             {visiomMetaFace.map((val, key) => {
              return <div>
              <div style={{fontSize: '20px', fontWeight: 'bold'}}>Face {key+1}</div>
              <div style={{height: '35px'}}>
                <div style={{float: 'left',width: '15%'}}>Joy</div>
                <div style={{float: 'left', width: '65%'}}>
                  <div className="meter">
                   {eval(val.joyLikelihood)}
                  </div>
                </div>
                <div style={{float: 'left', width: '15%', textAlign: 'left', fontSize: '12px', marginLeft: '5px'}}>{eval(val.joyLikelihood+'_TEXT')}</div>
              </div>
              <div style={{height: '35px'}}>
                <div style={{float: 'left',width: '15%'}}>Sorrow</div>
                <div style={{float: 'left', width: '65%'}}>
                  <div className="meter">
                  {eval(val.sorrowLikelihood)}
                  </div>
                </div>
                <div style={{float: 'left', width: '15%', textAlign: 'left', fontSize: '12px', marginLeft: '5px'}}>{eval(val.sorrowLikelihood+'_TEXT')}</div>
              </div>
              
              <div style={{height: '35px'}}>
                <div style={{float: 'left',width: '15%'}}>Anger</div>
                <div style={{float: 'left', width: '65%'}}>
                  <div className="meter">
                  {eval(val.angerLikelihood)}
                  </div>
                </div>
                <div style={{float: 'left', width: '15%', textAlign: 'left', fontSize: '12px', marginLeft: '5px'}}>{eval(val.angerLikelihood+'_TEXT')}</div>
              </div>
              <div style={{height: '35px'}}>
                <div style={{float: 'left',width: '15%'}}>Surprise</div>
                <div style={{float: 'left', width: '65%'}}>
                  <div className="meter">
                  {eval(val.surpriseLikelihood)}
                  </div>
                </div>
                <div style={{float: 'left', width: '15%', textAlign: 'left', fontSize: '12px', marginLeft: '5px'}}>{eval(val.surpriseLikelihood+'_TEXT')}</div>
              </div>
              <div style={{height: '35px'}}>
                <div style={{float: 'left',width: '15%'}}>Exposed</div>
                <div style={{float: 'left', width: '65%'}}>
                  <div className="meter">
                  {eval(val.underExposedLikelihood)}
                  </div>
                </div>
                <div style={{float: 'left', width: '15%', textAlign: 'left', fontSize: '12px', marginLeft: '5px'}}>{eval(val.underExposedLikelihood+'_TEXT')}</div>
              </div>
              <div style={{height: '35px'}}>
                <div style={{float: 'left',width: '15%'}}>Blurred</div>
                <div style={{float: 'left', width: '65%'}}>
                  <div className="meter">
                  {eval(val.blurredLikelihood)}
                  </div>
                </div>
                <div style={{float: 'left', width: '15%', textAlign: 'left', fontSize: '12px', marginLeft: '5px'}}>{eval(val.blurredLikelihood+'_TEXT')}</div>
              </div>
              <div style={{height: '35px'}}>
                <div style={{float: 'left',width: '15%'}}>Headwear</div>
                <div style={{float: 'left', width: '65%'}}>
                  <div className="meter">
                  {eval(val.headwearLikelihood)}
                  </div>
                </div>
                <div style={{float: 'left', width: '15%', textAlign: 'left', fontSize: '12px', marginLeft: '5px'}}>{eval(val.headwearLikelihood+'_TEXT')}</div>
              </div>
              <div style={{ clear: 'both'}}>&nbsp;</div>
              </div>
              
             })}
            </div><div style={{clear: 'both'}}></div></div>
            
          </TabPanel>  : '' }
           {visiomMetaObject ? 
            <TabPanel value={value} index={2}>
           
              <div style={{overflowX:'auto', height: '400px', padding:'10px', scrollbarWidth: 'thin', boxShadow:'0px 0px 6px #FFFFFF0F'}}>
              <div>
             {visiomMetaObject.map((val) => {
              const ScoreVal = Math.round(val.score*100)+'%';
              var ScoreWidth = 'width';
              const customStyles = {
                  borderTop: '4px solid #3F8AFF',
                  clear: 'both',
                  width: ScoreVal
              } 
              return <div style={{height:'35px'}}>
              <div style={{float: 'left'}}>{val.name}</div>
              <div style={{float: 'right', textAlign: 'right'}}>{ScoreVal}</div>
                <div style={{width: '100%', backgroundColor: '#FFF', clear: 'both', height:'4px'}}><div style={{...customStyles}}>&nbsp;</div></div>
              </div>
             })}
            </div><div style={{clear: 'both'}}></div></div>
          </TabPanel> : ''}
          {visiomMetaColor ? <TabPanel value={value} index={3}>
            
              <div style={{overflowX:'auto', height: '400px', padding:'10px', scrollbarWidth: 'thin', boxShadow:'0px 0px 6px #FFFFFF0F'}}>
              <div>
                  {visiomMetaColor.map((val) => {
                    const ScoreVal = Math.round(val.score*100)+'%';
                    const bgColor = 'rgb('+val.red+', '+val.green+','+val.blue+')';
                    const bgColorHex = RGBToHex(val.red, val.green, val.blue);

                    const customStyles = {
                      float: 'left',
                      backgroundColor: bgColor,
                      width: ScoreVal,
                      height: '300px'
                    } 
                    return <div onMouseOver={() => {colorShower(bgColorHex, bgColor, ScoreVal)}} title={bgColorHex+','+ScoreVal} style={{...customStyles}}>&nbsp;</div>
                  })}
                  <div style={{clear: 'both'}}></div>
                  <div id="colorshower">
                    
                    </div>
                </div>
                
                <div style={{clear: 'both'}}></div>
              </div>
          </TabPanel>  : ''} 

          
      </Grid>
    
    </Grid>


    </SlidingPane>
		<SlidingPane
		closeIcon={<KeyboardArrowDownIcon />}
		className="some-custom-class"
		overlayClassName="some-custom-overlay-class"
		isOpen={state.isPaneOpen}
		title=" "
		hideHeader="false"
		from="bottom"
		width="100%"
		subtitle=" "
		onAfterOpen={handleAfterOpen}
		onRequestClose={() => {
			// triggered on "<" on left top click or on outside click
			setState({ isPaneOpen: false });
		}}
		>
    
		<div style={{height: '100%'}}>
      <Grid container style={{height:'100%'}}>
        
        <Grid xs={9}>
          <div style={{padding: '10px'}}>
            <div style={{ height: '90vh' }}>
              <PinturaEditor
              {...editorDefaults}
              src={editorImage}

              onProcess={({ dest }) => showAfterProcess(URL.createObjectURL(dest))}
              
              />
            </div>
          </div>
        </Grid>
        <Grid xs={3}>
        <div style={{float:'right', padding: '5px', cursor: 'pointer'}} >
        <img title="Close" onClick={()=> setState({ isPaneOpen: false }) } width="13" src="/close.png" />
        </div>
        <div id="afterProcessSection" style={{clear: 'both'}}>
           
          <div className="textCopyToButton" style={{float:'left', width: '300px'}} onMouseOver={() => { onMouseOverEVent('channelListForCropped')}} onMouseLeave={() => { onMouseOutEVent('channelListForCropped')}}>
            <div style={{height: '40px', lineHeight: '40px', cursor: 'pointer'}}>
              <div style={{float: 'left', paddingLeft: '11px', fontWeight: 'bold'}}>Save to Channel</div>
              <div style={{float:'right', padding: '6px', height: '28px', borderLeft: '1px solid #d7d0ce2e'}}><ArrowDropDownIcon /></div>
            </div>
            <div id="channelListForCropped" >
              <ul className="toggleChannelList">
                {channellisting.map((val,key) => {
                return <div><li onClick={() => {submitCropData(val.id, val.slug, currentassetid, 'channel')}} >
                {val.name}
                </li>
                <EditorFolder assetID={state.isPaneTileId} matchParam={match.params.name} currentChannel={channelIDForChildren} bucketID={val.id} /></div>
                })}
              </ul>
            </div>
          </div>
          <div id="downloadPreviewSection" style={{paddingTop:'3px',display: 'none', clear:'both', width: '300px'}}>
            <br />
            <div style={{fontSize: '16px', color: '#ffd843'}}>Preview</div>
           
            <img src="" id="processedimage" style={{maxWidth: '100%', maxHeight: '350px', padding: '1px', backgroundColor: '#FFF', boxShadow: '0px 0px 4px #FFF'}} />
            <br />
            <Button id="downloadImageButton" onClick={() => {downloadAsset(jQuery('#bloburlofimage').val(),generateFileName())}} style={{backgroundColor: '#FFD843', width: '100%', borderRadius: '4x', color:' #111111d6 !important', fontWeight: 'bold'}}>
              Download
            </Button>
          </div>
          <input type="hidden" id="bucketName" />
          <input type="hidden" id="folderName" />
          <input type="hidden" id="channelID" />
          <input type="hidden" id="targetfileName" /> 
          <input type="hidden" id="bloburlofimage" /> 
          <input type="hidden" id="keyofimage" /> 
          <input type="hidden" id="targettype" /> 
          <div style={{clear: 'both'}}>
          { showCropProgress ? <Progress /> : null }
          </div>
        </div>
        </Grid>
        
      </Grid>
    </div>
		
		</SlidingPane>
	</div>

    
  );
}

export default ChannelDetails;
