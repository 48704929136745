import {React,useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    
  },
 
  singUpContainer: {
    height: '100%',
    textAlign: "center",
     width:'100%'
  },
  singUpLogo: {
    height: 66,

  },
  singUpHeading: {
    fontSize: 40,
    fontWeight: '900',
    color:'#723FBA'
  },
  singUpTagLine: {
    fontSize: 16,
    color: '#371B58'
  },
  textFieldRoot: {
      width: '100%'
    },
    continueButton: {
      backgroundColor: '#723FBA',
      width: '100%',
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 18,
      textTransform: 'none'
    },
    marginAutoItem: {
    margin: '0px auto',
  },
  image: {
    backgroundImage: 'url('+process.env.REACT_APP_BACKGROUNDS+')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  links: {
    color: '#371b58'
  },
  resendLink: {
    marginTop: '14px',
    position: 'absolute',
    marginLeft: '6px',
    display:'none',
     color: '#371b58'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 
function LoginSide () {

  const userinfo  = localStorage.getItem('userinfo'); // {localStorage.getItem('userinfo')=='undefined' ? '' : JSON.parse(localStorage.getItem('userinfo'))} ;
  //console.log(userinfo);
  if(userinfo!==null && userinfo!='undefined'){
    //console.log(JSON.parse(userinfo));
    const userjson = JSON.parse(userinfo);
    if(userjson[0].id){
      window.location.href = '/dashboard';
    }
    //window.location.href = '/channel/general';
  }

  const classes = useStyles();
  //const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: ""
  });


  const handleInputChange = (event) => {
    if(event.keyCode=='13'){
      postData(event);
    }
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const redirectFunc = () => {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ email: state.email })
      };
      fetch(APIURL+'user/resendcode', requestOptions)
      .then(response => response.json())
      .then(response => document.getElementById('linkclicking').click())
    }


      async function postData(event){
          event.preventDefault();
          let response = await fetch(APIURL+'user/login',{
           "method": "POST",
           "headers": {
              "Accept": "application/json",
              "Content-type": "application/json"
            },
            "body" : JSON.stringify({
              email: state.email,
              password: state.password
            })
          });

          const json = await response.json();
          if(json.http_code==='401'){
            jQuery('#alert').show();
            jQuery('#alert .MuiAlert-message').html(json.message);
            jQuery('#resendcodelink').show();
          } else if(json.http_code==='400'){
            jQuery('#alert').show();
            jQuery('#resendcodelink').hide();
            jQuery('#alert .MuiAlert-message').html(json.message);
          } else if(json.http_code==='200'){
            jQuery('#alert').hide();
            jQuery('#resendcodelink').hide();
            jQuery('#successalert').show();
            jQuery('#successalert .MuiAlert-message').html(json.message);
            console.log(json);
            //localStorage.setItem('usertoken','fajsasd977DSD7s87sahj3');
            localStorage.setItem('userinfo',JSON.stringify(json.userinfo));
            localStorage.setItem('teaminfo',JSON.stringify(json.teaminfo));
            window.setTimeout(() => {
              window.location.href = json.redirect;
              //history.push('/'+json.redirect);
            }, 3000);
          }
          
          

      }


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={6} md={6} className={classes.image} />
      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          
          <Box  alignItems="center" className={classes.marginAutoItem}>
            <div className={classes.singUpContainer}>
               <img alt="{process.env.REACT_APP_BASE_NAME} Logo" className={classes.singUpLogo} src="/large.png" />
              <div>
                <h1 className={classes.singUpHeading}>Sign in to {process.env.REACT_APP_BASE_NAME}</h1>
              </div>
              <Form onSubmit={postData} id="login-form" className="login-form" method="POST">
              <div>
                <TextField name="email" onKeyUp={handleInputChange} onChange={handleInputChange} className={classes.textFieldRoot} id="outlined-basic" label="Email" variant="outlined" />
              </div>
              <br />
              <div>
                <TextField label="Password" name="password" onKeyUp={handleInputChange} onChange={handleInputChange} type="password" className={classes.textFieldRoot} id="outlined-basic" variant="outlined" />
              </div>
              <br />
              <div>
                
                
              </div>
              <Button onClick={postData} variant="contained" className={classes.continueButton} >
                Sign in
                </Button>
                <br />
              <Link
                id="linkclicking" 
                to={{
                  pathname: "/verify",
                  state
                }}
              >&nbsp;
              </Link>
              </Form>
              <Link id="resendcodelink" to="#" onClick={redirectFunc} className={classes.resendLink} ><b>Resend Code</b></Link>
              <Alert id="alert" severity="error" style={{display:"none"}} >
              
              </Alert>
              <Alert id="successalert" severity="success" style={{display:"none"}} >
              
              </Alert>
              <Box alignItems="center" justifyContent="space-between" mt={5}>
              Not registered with {process.env.REACT_APP_BASE_NAME}?
              </Box>
              <Box alignItems="center" justifyContent="space-between" mt={1}>
              <Link to="/signup" className={classes.links}><b>Sign up</b></Link> to an existing workspace
              </Box>
              <Box alignItems="center" justifyContent="space-between" mt={1}>
              Forgot your password? <Link to="/forgot-password" className={classes.links}><b>Click here</b></Link>
              </Box>
            </div>
            </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default LoginSide;