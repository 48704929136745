import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxList = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };
  console.log(checkedItems);
  const data = [
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    { id: 3, name: 'Item 3' },
    { id: 4, name: 'Item 4' },
    { id: 5, name: 'Item 5' },
  ];

  useEffect(() => {
    // Set the checked items as needed
    setCheckedItems([2, 4]);
  }, []);

  return (
    <div>
      {data.map((item) => (
        <>
          <Checkbox
            checked={checkedItems.includes(item.id)}
            onChange={(event) => handleCheckboxChange(event, item.id)}
          />
          {item.name}
        </>
      ))}
    </div>
  );
};

export default CheckboxList;
