
import React,{useEffect,useState} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography  } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import { Link } from "react-router-dom";
import { Breadcrumbs } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import {BrowserRouter as Router,NavLink,Route} from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
	root: {
		'& MuiTypography-root': {
    	fontFamily: 'Normal'
    		
    	}
	},
	browseapps: {
		color: '#464775',
		fontSize: '15px'
	},
	browseappicon: {
		width:'20px',
		float:'left'
	},
	appName: {
    	fontWeight: 'bold',
		color: '#333232',
		fontSize: '26px',
		fontFamily: 'Normal'
    },
    post__content: {
    	'& img': {
    		width: "90%",
    		border: "1px solid #CCC"
    	}
    	
    }
    
}))

 const message = 'Truncation should be conditionally applicable on this long line of text as this is a much longer line than what the container can support.';
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'scrollable-auto-tabpanel-${index}'}
      aria-labelledby={'scrollable-auto-tab-${index}'}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ApplicationDetail({match}) {
	console.log(match);

	//console.log("Session: " + Session.get("sessionuser"));
	let history = useHistory();
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	  const handleChange = (event, newValue) => {
	    setValue(newValue);
	  };

	const [state, setState] = useState({
		teamname: "",
		teamdescription: ""
	});


	const Progress = () => (     <div><div>        <CircularProgress />      </div> </div>   );	
	const [showProgress, setShowProgress] = useState(false);
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const [teamId, setTeamId] = useState(null);
	const [teamName, setTeamName] = useState(null);
	const [appdetails, setAppDetails] = useState([]);
	const [appstatus, setAppStatus] = useState();
	function addApplication(applicationid){
		setShowProgress(true);
		const requestOptions = {
	        method: 'POST',
	        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
	        body: JSON.stringify({ appid: applicationid, userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/addtoteam', requestOptions)
            .then(response => response.json())
            .then(response => {
            	//const json = response.json();
			    if(response.http_code=='400'){
			      jQuery('#alert').show();
			      jQuery('#successalert').hide();
			      jQuery('#alert .MuiAlert-message').html(response.message);
			    } else {
			     // jQuery('#addtoteambutton').hide();
			    //  jQuery('#addedtoteambutton').show();
			      //jQuery('#successalert').show();
			      //jQuery('#successalert .MuiAlert-message').html(response.message);
			    }

			    window.setTimeout(() => {
			    	setShowProgress(false);
					history.push(response.redirect);
					//window.location.href = json.redirect;
				}, 2000);
                  //setAppDetails(response.appdetail, userid: userinfo[0].id)
                  //this.state = { response };
            })
	}
	useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ appid: match.params.appid, userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setAppDetails(response.appdetail)
                  setAppStatus(response.appstatus)
                  //this.state = { response };
            })
   	},[])

  return (
  	<div>
  		<div className="mainWrapper">
			<TopNav />
			<Box  mt={5}  pl={4} pr={4}  pb={3} >
				<Box>
					<NavLink className="browseapps"  to={"/apps/"} >
						<div><ArrowBackIosIcon className="browseappicon" /></div> 
						<div>Browse Apps</div>
					</NavLink>
				</Box>
				<Box  display="flex" mt={5} >
					{appdetails.map((val,key) => (
					<Grid container spacing={3}>
						<Grid style={{textAlign:'center'}} item xs={3}>
						<img src={'/'+val.logo} style={{maxWidth: '200px'}} />
						{appstatus==1 ? <Button id="addtoteambutton" className="addtoteambutton">Added to Team</Button> : <Button id="addtoteambutton" onClick={() => addApplication(val.id)} className="addtoteambutton">Add to Team</Button>}
						
						<Button id="addedtoteambutton" style={{display:'none'}} className="addtoteambutton">Added to Team</Button>
						{ showProgress ? <Progress /> : null }
						</Grid>
						<Grid item xs={9} >
							<Typography className={classes.appName}>{val.name}</Typography>
							<Tabs
					          value={value}
					          onChange={handleChange}
					          indicatorColor="primary"
					          textColor="primary"
					          variant="scrollable"
					          scrollButtons="auto"
					          aria-label="scrollable auto tabs example">
					          <Tab label="Description" {...a11yProps(0)} />
					          <Tab label="Help" {...a11yProps(1)} />
					        </Tabs>
						      <TabPanel value={value} index={0} style={{maxHeight:window.innerHeight-291, overflow:'auto', border: '0px solid red',  scrollbarWidth: 'thin'}}>
						      
								<article>
								  
								  <div className={classes.post__content} dangerouslySetInnerHTML={{__html: val.description}}></div>
								</article>
						      </TabPanel>
						      <TabPanel value={value} index={1} style={{maxHeight:window.innerHeight-291, overflow:'auto', border: '0px solid red',  scrollbarWidth: 'thin'}}>
						       <div className={classes.post__content} dangerouslySetInnerHTML={{__html: val.features}}></div>
						      </TabPanel>
						   
					  
					      
						</Grid>
					</Grid>
					))}
				</Box>
				<Box m={2} display="flex">
				<Alert id="successalert" severity="success" style={{display:"none",'width':'540px'}} >
              
              	</Alert>
              	</Box>
	      	</Box>
		</div>
	</div>
  	
  );
}

export default ApplicationDetail;
