import TopNav from './../Common/TopBar/TopNav.js';

function PageNotFound() {
  return (
  	<div>
		<div className="mainWrapper">
			
			<div className="App">
				<div style={{textAlign:'center'}}>
				<h1>Oops! Page not found!</h1>
	      		<h4>We are very sorry for inconvenience. It looks like you're trying to access a page that has deleted or never existed.</h4>
				</div>
	    	</div>
		</div>
	</div>
    
  );
}

export default PageNotFound;
