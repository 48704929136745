
import React,{useEffect,useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TopNav from './../../Common/TopBar/TopNav.js';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography,Checkbox  } from '@material-ui/core';
import {useHistory } from 'react-router-dom';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import jQuery from 'jquery'; 
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';

const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({ 
	saveButton: {
		backgroundColor: '#33344A',
		color: '#FFFFFF',
		marginRight: '10px'
	},
	buckets: {
		fontSize: '15px',
		backgroundColor: '#46477521',
		marginTop: '1px',
		float: 'left',
		width:'33%',
		margin: '1px'
	}
	
}))



 
function AmazonBuckets({match}) {
	const [bucketlisting, setBucketList] = useState([]);
	let history = useHistory();
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const classes = useStyles();
	
	 useEffect(() => {
    	// POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'application/getamazonbuckets', requestOptions)
            .then(response => response.json())
            .then(response => {
            	setBucketList(response.buckets)
            	//console.log(response.creddetails[0]);
            })

           
			//console.log(data.id);
			// empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);
	const importMediaCron = () => {
		setShowProgress(true);
		jQuery('#alert').hide();
		jQuery('#successalert').hide();
		const layername   = jQuery('#layername').val();
		const requestOptions   = {
		  method: 'POST',
		  headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
		  body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo, bucketsid: jQuery('#bucketsids').val() })
		};
		fetch(APIURL+'application/importmediacron', requestOptions)
		.then(response => response.json())
		.then(response => { //downloadAsset(response.url,response.message)
		//copynotify();
			if(response.http_code=='400'){
				jQuery('#alert').show();
				jQuery('#successalert').hide();
		        jQuery('#alert .MuiAlert-message').html(response.message);
		        setShowProgress(false);
			}

			if(response.http_code=='200'){
				jQuery('#successalert').show();
				jQuery('#alert').hide();
		        jQuery('#successalert .MuiAlert-message').html(response.message);
		        setShowProgress(false);
		        history.push('/app/Amazon/buckets');
			}
		})
	}	
	const importBucketMedia = () => {
		setShowProgress(true);
		jQuery('#alert').hide();
		jQuery('#successalert').hide();
      const layername   = jQuery('#layername').val();
      const requestOptions   = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo, bucketsid: jQuery('#bucketsids').val() })
      };
      fetch(APIURL+'application/importbucketmedia', requestOptions)
      .then(response => response.json())
      .then(response => { //downloadAsset(response.url,response.message)
        //copynotify();
        	if(response.http_code=='400'){
        		jQuery('#alert').show();
        		jQuery('#successalert').hide();
	            jQuery('#alert .MuiAlert-message').html(response.message);
	            setShowProgress(false);
        	}

        	if(response.http_code=='200'){
        		jQuery('#successalert').show();
        		jQuery('#alert').hide();
	            jQuery('#successalert .MuiAlert-message').html(response.message);
	            setShowProgress(false);
	            history.push('/app/Amazon/buckets');
        	}
      })


    //setAnchorEl(null);
  	};

  	const Progress = () => (
      <div>
        <CircularProgress />
      </div>
    );
    const [showProgress, setShowProgress] = React.useState(false);

	return (
		<div>
			<div className="mainWrapper">
				<TopNav />

				<Box  mt={3}  pl={3} pr={3} >
					 
					<h1>Amazon Buckets</h1>
					<div>
					<h4>Please select bucket you want to import.</h4>
					</div>
					<input type="hidden"  id="bucketsids" />
					<Box style={{maxHeight:window.innerHeight-260, overflow:'auto', scrollbarWidth: 'thin'}}>
					{bucketlisting.map((val,key) => (
						<div className={classes.buckets}>
						{val.imported_media=='yes' ? <Checkbox disabled checked color="primary" onChange={event => {
		                  if(event.target.checked){
		                    jQuery('#bucketsids').val(jQuery('#bucketsids').val()+","+event.target.value);
		                  } else {
		                    const updatedval = jQuery('#bucketsids').val().replace(","+event.target.value,'');
		                    jQuery('#bucketsids').val(updatedval);
		                  }
		                }}  value={val.id} name="channels" className="channelcheckboxes" /> : <Checkbox  color="primary" onChange={event => {
		                  if(event.target.checked){
		                    jQuery('#bucketsids').val(jQuery('#bucketsids').val()+","+event.target.value);
		                  } else {
		                    const updatedval = jQuery('#bucketsids').val().replace(","+event.target.value,'');
		                    jQuery('#bucketsids').val(updatedval);
		                  }
		                }}  value={val.id} name="channels" className="channelcheckboxes" />}
						
		                {val.bucket_name}</div>
						
					))}
					</Box>
					<br />
					<div style={{float: 'left'}}>
					<Box>
						<Button variant="contained"	color="secondary" size="large" className={classes.saveButton} onClick={importMediaCron} startIcon={<ImportExportIcon />}>
						Import Media
						</Button>
					</Box>
					</div>
					<div style={{float: 'left'}}>
					{ showProgress ? <Progress /> : null }
					</div>
					
					<div style={{float: 'left'}}>
          			<Alert id="alert" severity="error" style={{display:"none", width:'350px'}} >
              
              		</Alert>
              		<Alert id="successalert" severity="success" style={{display:"none", width:'550px'}} >
              			
              		</Alert>
              		</div>
				</Box>
			</div>
		</div>
	);
}

export default AmazonBuckets;
