
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import {NavLink,useHistory} from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import {Link} from 'react-router-dom';


import { TextField,Box,Button, Typography  } from '@material-ui/core';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
		root: {
			'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '25ch',
			},
			'& .MuiButton-root':{
				margin:'0px',
			}, 
			'& .MuiTypography-root' :{
				fontFamily: 'Normal',
				lineHeight: '28px',
				cursor: 'pointer'
			}, '& .MuiCircularProgress-root':{
				width:'30px',
				height: '30px',
				marginTop:'8px',
				marginLeft:'10px'
			}, '& .MuiCircularProgress-colorPrimary':{
				color: '#464775'
			}
		},
		saveButtonBox:{
			width:'99%',
			borderTop:'1px solid rgb(204, 204, 204)'
		},
		saveButton: {
			backgroundColor: '#33344A',
			width: '100px',
			color: '#FFFFFF',
		},
		avatarpreview: {
			width: '210px'
		},
		avatarDiv: {
			border: '1px solid #CCC',
			width: '200px',
			height: '200px'
		},
		dropzone: {

		},
		uploadDiv: {
			border: '1px solid #CCC',
			width: '200px',
			marginTop: '10px',
			borderRadius: '4px',
			textAlign: 'center',
			

		}
	  
}))

function TeamSettings() {
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const [teamId, setTeamId] = useState(null);
	const [teamName, setTeamName] = useState(null);
	const [teamDesc, setTeamDesc] = useState(null);
	const [teamPhoto, setTeamPhoto] = useState(null);
	let history = useHistory();
	const [useremail, setUserEmail] = useState({
	    team_description: "",
	    
	    picture: ""
	  });
	const classes = useStyles();
	const [showProgress, setShowProgress] = React.useState(false);
	const handleInputChange = (event) => {
		console.log(event.keyCode);
		if(event.keyCode=='13'){
	      postData(event);
	    }
		const { name, value } = event.target;
		//setTeamDesc(...prevState,value);
	    setTeamDesc(value)
	    
	}

useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setTeamId(response.teamid)
                  setTeamName(response.teamname)
                  setTeamDesc(response.teamdesc)
                  setTeamPhoto(response.teamlogo)
                })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

		const [files, setFiles] = useState([]);
	  const { getRootProps, getInputProps } = useDropzone({
	    accept: 'image/*',
	    multiple: false,
	    onDrop: acceptedFiles => {
			const files = acceptedFiles.map(file => {
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload=(e)=>{
					console.log(file.type);
					const requestOptions = {
			            method: 'POST',
			            headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
			            body: JSON.stringify({ 
			            	file: e.target.result,
			            	filetype: file.type,
			            	filesize: file.size,
			            	filename: file.name,
			            	team: teamId,
			            	userid: userinfo[0].id,
			            })
			        };
					fetch(APIURL+'team/uploadlogo', requestOptions)
					.then(response => response.json())
            		.then(response => setTeamPhoto(response.url))
				} 
					

			});
			setFiles(

		        acceptedFiles.map(file =>
		          Object.assign(file, {
		            preview: URL.createObjectURL(file),
		          }),
		        ),
		        jQuery('#previewimage').hide(),
		      );
			//setShowProgress(false);
		},

	  });

	  const thumbs = files.map(file => (
	    <img src={file.preview} style={{width:'192px', height:'192px',margin:'4px'}}  alt="" />
	  ));

	



	async function postData(event){
          event.preventDefault();
          jQuery('#successalert').hide();
          setShowProgress(true);
          let response = await fetch(APIURL+'team/updatesettings',{
           "method": "POST",
           "headers": {
              "Accept": "application/json",
              "Content-type": "application/json"
            },
            "body" : JSON.stringify({
              userid: userinfo[0].id,
              team: teamId,
              desc: teamDesc,
              logo: teamPhoto
            })
          });

          const json = await response.json();
          console.log(json.message);
          jQuery('#successalert').show();
          jQuery('#successalert .MuiAlert-message').html(json.message);
          setShowProgress(false);
          history.push('/team-settings');
	    }


    

  return (
  	<div className="bradecrumbbox">
  		<div className="mainWrapper">
			<TopNav />
			<Box pl={4} pr={4}  pt={4}  pb={3} >
				<Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
					<Link color="inherit" href="/" >
					{teamName}
					</Link>
					<Typography color="textPrimary">
					Settings
					</Typography>
				</Breadcrumbs>
		    	<form onSubmit={postData} className={classes.root} noValidate autoComplete="off">
		    	<Typography variant="h4" gutterBottom>
				Settings
				</Typography>
			    <Box style={{height:window.innerHeight-201, overflow:'auto',  scrollbarWidth: 'thin', width: '99%'}}>
				    
					<Grid container spacing={0}>
				    	<Grid style={{border:'1px solid red;'}} item xs={12} sm={6}>
				    		
				          	<div>
				          	<TextField
						    		disabled
						          id="outlined-disabled"
						          label="Team Name"
						          rows={4}
						          fullWidth
						          style={{width:450}}
						          defaultValue=" "
						          variant="outlined"
						          value={teamName}
						          
						          name="team_name"
						          InputLabelProps={{
						            shrink: true,
						            readOnly: true,
						          }}
						        />
						    	
					       	</div>
					       	<div>
					       		<TextField
						          id="outlined-multiline-static"
						          label="Team Description"
						          multiline
						          rows={4}
						          fullWidth
						          style={{width:450}}
						          variant="outlined"
						          value={teamDesc}
						           defaultValue=" "
									onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						           name="team_description"
						           InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	
					       	<div style={{display:'none'}}>
					       	<TextField
					       			id="hiddenfieldimg"
						          type="text"
						           style={{width:'95%'}}
						          value={teamPhoto}
						           defaultValue=" "
						           name="picture"
						           onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          
						        />
						        </div>
				        </Grid>
				        <Grid style={{border:'1px solid red;'}} item xs={12} sm={6}>
				        <Box className={classes.avatarpreview}>
					        <div className={classes.avatarDiv}>{thumbs}<img id="previewimage" style={{margin:'4px', width:'192px', height: '192px'}} src={teamPhoto} /></div>
							<div className={classes.uploadDiv}>
								<Box className={classes.dropzone} {...getRootProps()}>
								<input {...getInputProps()} />
								<Typography>Upload Logo</Typography>
								</Box>

							</div>
				        </Box>
				        </Grid>
				        
			    	</Grid>
			     	
					
			       	
				    
				    
				    
			     </Box> 
			     <Box className={classes.saveButtonBox}>
<					div style={{float:'left', paddingTop: '5px'}}>
				    	<Button
					        variant="contained"
					        color="secondary"
					        size="large"
					        className={classes.saveButton}
					        startIcon={<SaveIcon />}
					        onClick={postData}
					      >
					        Save
					      </Button>
					      
				    </div>
				    <div style={{float:'left', paddingTop: '5px'}}>
				    { showProgress ? <CircularProgress size={30} /> : null }
				    <Alert id="successalert" severity="success" style={{display:"none"}} >
              
              		</Alert>
				    </div>
			     </Box>
				

					
			    </form>
	      	</Box>
		</div>
	</div>
  	
  );
}

export default TeamSettings;
