
import React,{useEffect,useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography  } from '@material-ui/core';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import jQuery from 'jquery'; 
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InputAdornment from '@material-ui/core/InputAdornment';

const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({ 
	saveButton: {
		backgroundColor: '#394E6D',
		color: '#FFFFFF',
		marginRight: '10px'
	}
	
}))

 
function Amazon({match}) {
	let history = useHistory();
	const [bucketlisting, setBucketList] = useState([]);
	const [disable, setDisable] = React.useState(true);
	const [fieldDisable, setFieldDisable] = React.useState(true);
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const classes = useStyles();

	const [amazoncredentials, setAmazonCred] = useState({
	    access_key: "",
	    secret_access_key: ""
	    
	});
	const notify = () => toast.dark("Amazon credentials saved!", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const blankcredentials = () => toast.dark("Please insert credentials", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});


	const failedconnection = () => toast.error("Invalid credentials. Connection not established.", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const bucketimported = () => toast.success("Buckets Loaded successfully", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const successconnection = () => toast.success("The AWS connection established.", {
		position: "bottom-left",

		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const installedmessage = () => toast.success("Amazon S3 installed to your team", {
		position: "bottom-left",
		autoClose: 3000,
		delay: 1500,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const handleInputChange = (event) => {
		//console.log(event.keyCode);
		if(event.keyCode=='13'){
	      //postData(event);
	    }
		const { name, value } = event.target;
	    setAmazonCred(prevState => ({
	      ...prevState,
	      [name]: value
	    }));
	    
	}

	const Progress = () => (
      <div><div>
        <CircularProgress />
      </div></div>
    );
    const [showProgress, setShowProgress] = React.useState(false);
	 useEffect(() => {
    	// POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/getamazoncredentials', requestOptions)
            .then(response => response.json())
            .then(response => {
            	setAmazonCred(response.creddetails[0]);
            	if(response.creddetails[0].access_key==null){
            		setFieldDisable(false);
            	} else {

            		setDisable(false);	
            		setFieldDisable(true);	
            		//console.log("disabled: ", response.creddetails[0].access_key);
            	}
            	
            	
            })

    }, []);

	 function importBuckets(){
	 	setShowProgress(true);
	 	const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
			body: JSON.stringify({ 
				userid: userinfo[0].id,
				teamid: teaminfo,
				accesskey: amazoncredentials.access_key,
				secretaccesskey: amazoncredentials.secret_access_key
			})
		};

		fetch(APIURL+'application/importamazonbuckets', requestOptions)
		.then(response => response.json())
		.then(response => {
			if(response.http_code=='404'){
				failedconnection();
			} else {
				bucketimported();
				
				setTimeout(function () {
				  history.push('./Amazon/buckets');
				}, 3000);	
			}
			setShowProgress(false);
			//console.log(response.buckets); 
			//setBucketList(response.buckets);
			//console.log(bucketlisting);


		})
	 }

	 function editSecretKey(){
	 	setFieldDisable(false);	
	 	setDisable(true);	
	 }
	 function testConnection(){
	 	setShowProgress(true);
	 	const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
			body: JSON.stringify({ 
				userid: userinfo[0].id,
				teamid: teaminfo,
				accesskey: amazoncredentials.access_key,
				secretaccesskey: amazoncredentials.secret_access_key
			})
		};
		if(amazoncredentials.access_key=='' || amazoncredentials.secret_access_key==''){
			blankcredentials();
			setShowProgress(false);
			return;
		}
		fetch(APIURL+'application/testamazonconnection', requestOptions)
		.then(response => response.json())
		.then(response => {
			if(response.http_code=='404'){
				failedconnection();
			} else {
				successconnection();
				
			}
			setShowProgress(false);
		})
	 }

	 function postData(){
	 	setShowProgress(true);
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
			body: JSON.stringify({ 
				userid: userinfo[0].id,
				teamid: teaminfo,
				accesskey: amazoncredentials.access_key,
				secretaccesskey: amazoncredentials.secret_access_key
			})
		};

		fetch(APIURL+'application/saveamazoncredentials', requestOptions)
		.then(response => response.json())
		.then(response => {
			if(response.http_code=='404'){
				blankcredentials();
				//failedconnection();
			} else if(response.http_code=='422'){
				//blankcredentials();
				failedconnection();
			} else {
				
				notify();
				installedmessage();
				setDisable(false);	
				setFieldDisable(true);
				window.setTimeout(() => {
					history.push(response.redirect);
					//window.location.href = json.redirect;
				}, 2000);
			}
			setShowProgress(false);
			
		})
	}

	
    
				    
	return (
		<div>
			<Box>
				<ToastContainer style={{width:'350px'}} />
				<h1>Amazon</h1>
					<Box>
					<Grid container spacing={0}>
				    	<Grid style={{border:'1px solid red;'}} item xs={12} sm={7}>
							<div>
						    	<TextField
						          id="outlined-multiline-static"
						          label="Access Key ID"
						          rows={4}
						          disabled={fieldDisable}
						          fullWidth
						          style={{width:'100%'}}
						          variant="outlined"
						          value={amazoncredentials.access_key}
						           defaultValue=" "
						           name="access_key"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						          InputProps={{
							          startAdornment: (
							            <InputAdornment position="start">
							              <VpnKeyIcon />
							            </InputAdornment>
							          ),
							        }}
						        />

					       	</div>
					       	
					       	<br />
					       	<div>
						    	<TextField
						    		type="password"
						          id="outlined-multiline-static"
						          label="Secret Access Key"
						          rows={4}
						          disabled={fieldDisable}
						          fullWidth
						          style={{width:'100%'}}
						          variant="outlined"
						          value={amazoncredentials.secret_access_key}
						           defaultValue=" "
						           name="secret_access_key"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						          InputProps={{
							          startAdornment: (
							            <InputAdornment position="start">
							              <VpnKeyIcon />
							            </InputAdornment>
							          ),
							        }}
						        />
					       	</div>
					    </Grid>
				        
			    	</Grid>
			    	</Box> 
			    	<Box>
			    		<div style={{float:'left', paddingTop: '15px'}}>
				    	<Button
					        variant="contained"
					        color="secondary"
					        size="large"
					        disabled={fieldDisable}
					        className={classes.saveButton}
					        startIcon={<SaveIcon />}
					        onClick={postData}
					      >
					        Save
					      </Button>
					      
				    	</div>
				    	<div>
						    <div style={{float:'left', paddingTop: '15px'}}>
							    <Button variant="contained" color="secondary" disabled={fieldDisable} id="testConnectionButton" size="large" className={classes.saveButton} startIcon={<SettingsInputAntennaIcon />} onClick={testConnection}>
							    Test Connection
							    </Button>
						    </div>
						    <div style={{float:'left', paddingTop: '15px'}}>
							    <Button variant="contained" color="secondary" size="large" disabled={disable} className={classes.saveButton} startIcon={<ImportExportIcon />} onClick={importBuckets}>
							    Load Buckets
							    </Button>
							</div>
							<div style={{float:'left', paddingTop: '15px'}}>
							    <Button variant="contained" color="secondary" size="large" disabled={disable} className={classes.saveButton} startIcon={<EditIcon />} onClick={editSecretKey}>
							    Edit
							    </Button>
							</div>
						</div>
				    	
				    	
			    	</Box> 
			    	<Box>
			    	
			    	</Box>
			    	
			</Box>
					<br style={{clear: 'both'}} /><br />
              
          			{ showProgress ? <Progress /> : null }
		</div>
	);
}

export default Amazon;
