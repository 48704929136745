
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import { Breadcrumbs } from '@material-ui/core';
import { Button,Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForever from '@material-ui/icons/DeleteForever';
import RestoreIcon from '@material-ui/icons/Restore';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import {useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const APIURL = process.env.REACT_APP_API_URL;

  
const useStyles = makeStyles(theme => ({
	root:{
		'& .MuiLinearProgress-root': {
			height:'6px'
		}
	},
	filterSection: {
		padding:'8px'
	},
	breadCrumbBox: {
		width: '78%',
		float: "left",
	},
	subBar: {
		height:'65px', 
		borderBottom:'1px solid #EAEAEA'
	},
	breadCrumb: {
		float: "left",
		fontSize:13,
		lineHeight:'58px',
		color: '#000000',
		fontWeight: 'bold'
	},
	numberImages:{
		fontFamily:'Normal',
		fontWeight: '700',
		fontSize:13
	},
	addTeamMateButton: {
		backgroundColor: '#7B83EB',
   		color: '#FFFFFF',
   		fontWeight: '600',
   		fontSize: 12
   		
	},
	dropzone: {
		float: "right",
	},
	  paper: {
	    padding: theme.spacing(2),
	    textAlign: 'center',
	    color: theme.palette.text.secondary,
	  },
	  draganddrop : {
	  	fontSize:26,
	  	color:'#33344A',
	  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  progressbar: {
  	
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[900],
    textTransform: 'uppercase',
    width: '25px !important'
  },
  emptyTrash: {
  	backgroundColor: '#e4002b !important',
	color: '#FFF',
	
	margin: '10px',
	textTransform: 'none',
	width: '143px',
	height: '41px'
  }

}))

function Trash() {
	//console.log(match.path);
	const [showProgress, setShowProgress] = React.useState(false);
	const classes = useStyles();
	const [teamId, setTeamId] = useState(null);
	const [teamName, setTeamName] = useState(null);
	const [userRole, setUserRole] = useState(null);
	const [expanded, setExpanded] = React.useState(false);
	const [opendialog, setOpenDialog] = React.useState(false);
	  const [assetlisting, setAssetListing] = useState([]);
	 /// console.log('assetlisting: '+assetlisting);
	  const handleExpandClick = () => {
	    setExpanded(!expanded);
	  };

	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const emptyTrashAgree = () => {
		setShowProgress(true);
    	const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, assetid: 'all', teamid: teaminfo })
        };
		fetch(APIURL+'assets/permanentdelete', requestOptions)
        .then(response => response.json())
        .then(response => {
        	setAssetListing(response.assets);
        	setShowProgress(false);
        })
		setOpenDialog(false);
	}
	useEffect(() => {
      // POST request using fetch inside useEffect React hook
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({userid: userinfo[0].id, teamid: teaminfo   })
      };
      fetch(APIURL+'assets/deleted', requestOptions)
          .then(response => response.json())
          .then(response => {
                //console.log(response.assets)
                setAssetListing(response.assets)
          })
          //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

	useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
					setTeamName(response.teamname)
					setTeamId(response.teamid)
					setUserRole(response.userrole)
                })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);
    //const routevar = <Redirect push to="/channel/tree-house" />

    function restoreAsset(assetId){
    	const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ assetid: assetId, teamid: teaminfo })
        };
        fetch(APIURL+'assets/restore', requestOptions)
        .then(response => response.json())
        .then(response => setAssetListing(response.assets))
    }

    function permanentDeleteAsset(assetId){
    	setShowProgress(true);
    	const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, assetid: assetId, teamid: teaminfo })
        };
        fetch(APIURL+'assets/permanentdelete', requestOptions)
        .then(response => response.json())
        .then(response => {
        	setAssetListing(response.assets);
        	setShowProgress(false);
        })
    }

  return (
  	<div >
  		<div className="mainWrapper">
			<TopNav />
			
			<Box display="flex" flexDirection="row"  className="bradecrumbbox">
				<Box width="100%">
					<Box className={classes.subBar}  pl={1}  pr={1}  >
						<Grid container spacing={0}>
							<Grid style={{border:'1px solid red;'}} item xs={6}>
							<Box component="span" m={1}>
								<Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
									<Typography className="breadcrumbMainHeading" >
									<b>{teamName}</b>
									</Typography>
									<Typography  className="breadcrumbSubHeading">
									<b>Trash</b>
									</Typography>
								</Breadcrumbs>
								<Button onClick={() => setOpenDialog(true)} className={classes.emptyTrash}>Empty Trash</Button>
							</Box>
							<Dialog
						        className="trashDialogClass"
						        open={opendialog}
						        onClose={() => setOpenDialog(false)}
						        aria-labelledby="responsive-dialog-title">
						        <DialogTitle id="responsive-dialog-title">Empty Trash Confirmation</DialogTitle>
						        <DialogContent>
						          <DialogContentText>
						          	This action will affect all {assetlisting.length} items in Trash. Are you sure you want to continue?
						          </DialogContentText>
						        </DialogContent>
						        <DialogActions>
						          <Button onClick={() => setOpenDialog(false)}>Disagree</Button>
						          <Button onClick={() => emptyTrashAgree()} autoFocus>
						            Agree
						          </Button>
						          
						        </DialogActions>
						      </Dialog>
							</Grid>
							<Grid className={classes.root}  style={{border:'1px solid red;'}} item xs={3}>
								<Box pt={3}>
									{ showProgress ? <LinearProgress className={classes.progressbar} /> : null }
									
								</Box>
							</Grid>
							<Grid style={{border:'1px solid red;'}} item xs={3}>
							<Box pt={'2px'} display="flex" flexDirection="row-reverse" bgcolor="background.paper">
								
								<Box justify="flex-end" right=""  >
									
							      
							      
							    </Box>
			    			</Box>
							</Grid>
						</Grid>
						
						
					</Box>
					
					<Box display="flex" className={classes.filterSection}  >
						<Box width="50%" fontWeight="fontWeightBold" >
							<Typography variant="subtitle2" className={classes.numberImages}  ><b>{assetlisting.length} images in trash</b></Typography>
						</Box>
						
					</Box>
					
					<Box pl={1}  pr={1} id="assetsBox">
						<Box  style={{maxHeight:window.innerHeight-160, overflow:'auto',  scrollbarWidth: 'thin'}}>
    
						    {assetlisting.map((tile) => (
						      <div className="cardParentDiv">
						    <Card >
						      <CardHeader
						      	className="cardHeader" 
						        avatar={
						          <Avatar aria-label="recipe" className="imgAvatar">
						            {tile.name.substring(1,0)}
						          </Avatar>
						        }
						        action={
						          <IconButton aria-label="settings">
						            <MoreVertIcon />
						          </IconButton>
						        }
						        title={tile.name.substring(14,0)}
						        subheader={tile.uploaded_at}
						      />
						      <CardMedia
						        className={classes.media}
						        image={tile.presigned_url}
						        title={tile.name}
						      />
						      
						      <CardActions disableSpacing>
						        
						         {userRole=='2' ? <Tooltip title="Delete Permanently"><IconButton aria-label="add to favorites"> <DeleteForever onClick={() => permanentDeleteAsset(tile.id)} /> </IconButton></Tooltip>: '' }
						        <Tooltip title="Restore">
						        <IconButton aria-label="restore">
						          <RestoreIcon onClick={() => restoreAsset(tile.id)}  />
						        </IconButton>
						        </Tooltip>
						      </CardActions>
						      
						    </Card>
						    </div>
						      ))}
						    
						    </Box>

			    		
			    	</Box>
				</Box>
			</Box>
			
		</div>
	</div>

    
  );
}

export default Trash;
