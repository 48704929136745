
import React,{useEffect} from 'react';
//import ReactDOM from 'react-dom';
import './App.css';
import './Jcrop.css';
import './Jcrop.js';
import './jquery-ui.js';

import './jquery-ui.css';
import './Responsive.css';
//import TopNav from './components/Common/TopBar/TopNav.js';
import SideBar from './components/Common/SideBar/index.js';

//import About from './components/Pages/About.js';
//import Shop from './components/Pages/Shop.js';
import Profile from './components/Pages/Profile.js';
import Dashboard from './components/Pages/Dashboard.js';
import NotFound from './components/Pages/404.js';
import ForgotPassword from './components/Auth/ForgotPassword.js';
import SignUp from './components/Auth/SignUp.js';
import Login from './components/Auth/Login.js';
import TeamSelection from './components/Auth/TeamSelection.js';
import Logout from './components/Auth/Logout.js';
import SetPassword from './components/Auth/SetPassword.js';
import AcceptInvite from './components/Auth/AcceptInvite.js';
import Verify from './components/Auth/Verify.js';
import Thanks from './components/Auth/Thanks.js';
import TeamName from './components/Pages/TeamName.js';
import CreateTeam from './components/Pages/CreateTeam.js';
import Trash from './components/Pages/Trash.js';
import AddApplication from './components/Apps/AddApplication.js';
import ApplicationDetail from './components/Apps/ApplicationDetail.js';
import ApplicationHome from './components/Apps/ApplicationHome.js';
import AmazonBuckets from './components/Apps/Amazon/Buckets.js';
import FEEDIND from './components/Apps/FEED/ind.js';
//import MainWrapper from './components/MainWrapper.js';
import Channel from './components/Channels/index.js';
import AddChannel from './components/Channels/AddChannel.js';
import AddFolder from './components/Channels/AddFolder.js';
import TeamMembers from './components/Pages/TeamMembers.js';
import TeamMemberss from './components/Pages/TeamMemberss.js';
import AddMember from './components/Pages/AddMember.js';
import EditMember from './components/Pages/EditMember.js';
import TeamSettings from './components/Pages/TeamSettings.js';
import ChannelDetails from './components/Channels/ChannelDetails.js';
import DefaultImageEditor from './components/Channels/DefaultImageEditor.js';
import ModalImageEditor from './components/Channels/ModalImageEditor.js';
import ChannelDetailsBack from './components/Channels/ChannelDetailsBack.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Infinite from './components/Apps/Editor/infinite.js';
import Googlevision from './components/Pages/Googlevision.js';

//import { useResizeDetector } from 'react-resize-detector';

const isLoggedIn    = localStorage.getItem('userinfo'); // ? true : false;
const isTeamDefined = localStorage.getItem('teaminfo'); // ? true : false;
//console.log('isTeamDefined', isTeamDefined);
function App() {

  return ( 
    <Router>
    <div className="App">
      {isLoggedIn==null ? '' : isLoggedIn=='undefined' ? '' : isTeamDefined=='' ? '' : isTeamDefined=='undefined' ? '' : <SideBar /> }
      <Switch>
      <Route exact path="/(logout)" component={Logout} />
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/(signup)" component={SignUp} />
      <Route exact path="/(forgot-password)" component={ForgotPassword} />
      <Route exact path="/(login)" component={Login} />
      <Route exact path="/(select-team)" component={TeamSelection} />
      
      <Route exact path="/(setpassword)" component={SetPassword} />
      <Route exact path="/(setpassword)/:hash" component={SetPassword} />
      <Route exact path="/(acceptinvite)/:hash" component={AcceptInvite} />
      <Route exact path="/(verify)" component={Verify} />
      <Route exact path="/(verify)/:token" component={Verify} />
      <Route exact path="/(thanks)" component={Thanks} />
      <Route exact path="/(dashboard)" component={Dashboard} />
      <Route exact path="/(profile)" component={Profile} />

      <Route exact path="/(channels)" component={Channel} />
      <Route exact path="/(add-channel)" component={AddChannel} />
      <Route exact path="/(add-folder)" component={AddFolder} />
      <Route exact path="/(channel)/:name" component={ChannelDetails} />
      <Route exact path="/(channel)/:channel/:name" component={ChannelDetails} />
      <Route exact path="/defaulteditor" component={DefaultImageEditor} />
      <Route exact path="/modaleditor" component={ModalImageEditor} />
      <Route exact path="/(channel)/:bucket/:channel/:name" component={ChannelDetails} />
      <Route exact path="/(channelbackup)/:name" component={ChannelDetailsBack} />
      <Route exact path="/trash" component={Trash} />

      <Route exact path="/(create-new-team)" component={CreateTeam} />
      <Route exact path="/(setup-team-name)" component={TeamName} />
      <Route exact path="/(team-settings)" component={TeamSettings} />
      <Route exact path="/(team-members)" component={TeamMembers} />
      <Route exact path="/(team-memberss)" component={TeamMemberss} />
      <Route exact path="/(add-member)" component={AddMember} />
      <Route exact path="/(edit-member)/:id" component={EditMember} />

      <Route exact path="/(apps)" component={AddApplication} />

      <Route exact path="/(apps)/:appid" component={ApplicationDetail} />
      <Route exact path="/(app)/:appid" component={ApplicationHome} />
      <Route exact path="/(app)/:appid/buckets" component={AmazonBuckets} />

      <Route exact path="/(pimind)" component={FEEDIND} />
      <Route exact path="/(infinite)" component={Infinite} />
      <Route exact path="/(googlevision)" component={Googlevision} />
        <Route component={NotFound} />
      </Switch>
    </div>
    </Router>
  );

}




export default App;
