
import React,{useEffect,useState,Component} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import Unsplash from './../Apps/Unsplash/index.js';
import Amazon from './../Apps/Amazon/index.js';
import Editor from './../Apps/Editor/index.js';
import Vision from './../Apps/Vision/index.js';
import FEED from './../Apps/FEED/index.js';
import PageNotFound from './../Pages/404.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography  } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import jQuery from 'jquery'; 
const APIURL = process.env.REACT_APP_API_URL;


const useStyles = makeStyles(theme => ({
	root: {
		'& MuiTypography-root': {
    	fontFamily: 'Normal'
    		
    	}
	},
	browseapps: {
		color: '#464775',
		fontSize: '15px'
	},
	browseappicon: {
		width:'20px',
		float:'left'
	},
	appName: {
    	fontWeight: 'bold',
		color: '#333232',
		fontSize: '26px',
		fontFamily: 'Normal'
    },
    
}))

 
function ApplicationHome({match}) {
	//console.log(match.params.appid);
	let history = useHistory();
	const classes = useStyles();
	
	
	const [userPermissions, setUserPermissions] = useState([]);
	const [appdetails, setAppDetails] = useState([]);
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const AppName = match.params.appid;
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	
	useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'user/permissions', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserPermissions(response.permission)
            })
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ appid: match.params.appid, userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                //setAppDetails(response.appdetail);
                //console.log(response.appdetail[0].slug);
				const appSlug = response.appdetail[0].slug;
				const appPermission = userPermissions[appSlug];
				console.log("appPermission", appPermission);
				if(appPermission==false){
					history.push('/dashboard');
				}
				
            })
   	},[])


	//console.log(AppName);
	return (
		<div>
			<div className="mainWrapper">
				<TopNav />

				<Box  mt={5}  pl={4} pr={4} >
				{AppName=='Unsplash' ? <Unsplash /> : AppName=='Editor' ?  <Editor /> : AppName=='Vision' ?  <Vision /> : AppName=='Amazon' ?  <Amazon />  : AppName=='FEED' ?  <FEED /> : <PageNotFound />  }
					 
					
				</Box>
			</div>
		</div>

	);
}

export default ApplicationHome;
