
import React,{useState,useEffect} from 'react';
import { Box, Typography,TextField} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {NavLink,useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuIcon from '@material-ui/icons/Menu';
import jQuery from 'jquery'; 
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
    searchTextField:{
    '& .MuiOutlinedInput-input':{
      fontSize:14,
      padding:'10px !important',
      color: '#FFFFFF'
    },
    '& .MuiOutlinedInput-adornedStart' :{
      backgroundColor: '#0000003d',
      color:'#FFFFFF',
      height: '30px'
    },
    marginTop:'7px',
    
    
  },
    userInfo: {
        paddingTop: 24,
        transition: 'all 0.1s ease',
        height: 75,
        cursor: "pointer",
        clear: "both",
        opacity: 1,
        '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
          height: 0,
          paddingTop: 0,
          opacity: 0,
          transition: 'all 0.3s ease',
        },
  },
  userTitle: {
    color: "#FFFFFF",
    marginBottom:1,
    fontFamily: 'Normal',
    cursor:'pointer',
    width: '34px !important',
      height: '34px !important',
    '& img' :{
      width: '26px !important',
      height: '26px !important',
      borderRadius: '3px'
    }
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "#FFFFFF",
    fontFamily: 'Normal'
  },
  popUpOver: {
    right:"130px !important",
    fontFamily: 'Normal'
  }, 
  li: {
    fontFamily: 'Normal'
  }
}));

function TopNav() {
    if(window.innerWidth<1024){
    jQuery('.mainWrapper .MuiBox-root, #assetsBox, .bradecrumbbox').click(function() {
      jQuery('.sideBarWrapper').hide();
    });
    jQuery(".sideBarWrapper").click(function(e) {
    //e.stopPropagation(); // This is the preferred method.
    return;        // This should not be used unless you do not want
                         // any click events registering inside the div
    });
    }
    
   let history = useHistory();
  const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
  //console.log(userinfo);
  if(userinfo==null){
    window.location.href = '/logout';
  }


 const [useremail, setUserEmail] = useState({
      email: "",
      name: "",
      phone_number: "",
      mobile_number: "",
      designation: "",
      address: "",
      picture: ""
    });
 useEffect(() => {
      // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id })
        };
        fetch(APIURL+'user/details', requestOptions)
            .then(response => response.json())
            .then(response => {
              setUserEmail(response.userdetails[0])
            })
           
      //console.log(data.id);
      // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);


   const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();

    function toggleSideBar(){
      jQuery('.sideBarWrapper').toggle();
    }
    
    async function postData(){
      let response = await fetch(APIURL+'user/logincheck',{
        "method": "POST",
        "headers": {
        "Accept": "application/json",
        "Content-type": "application/json"
      },
        "body" : JSON.stringify({
            email: userinfo[0].email,
            userid: userinfo[0].id
          })
      });

      const json = await response.json();
      if(json.http_code=='400'){
        history.push('/logout');
      } else if(json.http_code=='200'){
        if(json.userhashtoken=='XXXX-LOGGEDOUT-XXXX'){
          history.push('/logout');
        }
      }

    }

    useEffect(() => {
      postData();
    }, []);
    
  return (
    <div className="topBar">
      <MenuIcon style={{marginLeft:'15px'}} id="menuIconBurger" onClick={toggleSideBar} /> 
    	<Box className="topBarBoxLogo"  pl={1}>
        <NavLink to="/dashboard"><img src="/lightlogo.png" style={{width: '125px', paddingLeft: '5px'}} /></NavLink>
      </Box>
      <Box className="topBarBoxSearch" >
        <TextField
                  className={classes.searchTextField}
                  id="input-with-icon-textfield"
                  fullWidth
                  placeholder="Search by name or category (e.g. productivity, sales)"
              margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
      </Box>
      <Box className="topBarBoxIcon" justifyContent="flex-end" id="topBarPicture"  display="flex"  >
          <Avatar  onClick={handleClick}  width="24" className={classes.userTitle}  src={useremail.picture} />
        </Box> 
        <Menu
        id="simple-menu"
        className={classes.popUpOver}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        >
        <NavLink to="/profile"><MenuItem><PersonIcon /> &nbsp;  Profile</MenuItem></NavLink>
        <NavLink to="/logout"><MenuItem><ExitToAppIcon /> &nbsp;  Logout</MenuItem></NavLink>
      </Menu>
    </div>
  );
}

export default TopNav;
