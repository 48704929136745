
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import { Breadcrumbs } from '@material-ui/core';
import { Button,Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForever from '@material-ui/icons/DeleteForever';
import RestoreIcon from '@material-ui/icons/Restore';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import {useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import vision from '@google-cloud/vision';


  
const useStyles = makeStyles(theme => ({
	root:{
		'& .MuiLinearProgress-root': {
			height:'6px'
		}
	},
	filterSection: {
		padding:'8px'
	},
	breadCrumbBox: {
		width: '78%',
		float: "left",
	},
	subBar: {
		height:'65px', 
		borderBottom:'1px solid #EAEAEA'
	},
	breadCrumb: {
		float: "left",
		fontSize:13,
		lineHeight:'58px',
		color: '#000000',
		fontWeight: 'bold'
	},
	numberImages:{
		fontFamily:'Normal',
		fontWeight: '700',
		fontSize:13
	},
	addTeamMateButton: {
		backgroundColor: '#7B83EB',
   		color: '#FFFFFF',
   		fontWeight: '600',
   		fontSize: 12
   		
	},
	dropzone: {
		float: "right",
	},
	  paper: {
	    padding: theme.spacing(2),
	    textAlign: 'center',
	    color: theme.palette.text.secondary,
	  },
	  draganddrop : {
	  	fontSize:26,
	  	color:'#33344A',
	  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  progressbar: {
  	
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[900],
    textTransform: 'uppercase',
    width: '25px !important'
  },
  emptyTrash: {
  	backgroundColor: '#e4002b !important',
	color: '#FFF',
	
	margin: '10px',
	textTransform: 'none',
	width: '143px',
	height: '41px'
  }

}))

function Googlevision() {
	/*function detectFaces(){
		const vision = require('@google-cloud/vision');

		// Creates a client
		const client = new vision.ImageAnnotatorClient({
			keyFileName: 'GoogleKeyFile.json'
		});

		
		const fileName = 'girl.jpg';

		const [result] = client.faceDetection(fileName);
		const faces = result.faceAnnotations;
		console.log('Faces:');
		faces.forEach((face, i) => {
		  console.log(`  Face #${i + 1}:`);
		  console.log(`    Joy: ${face.joyLikelihood}`);
		  console.log(`    Anger: ${face.angerLikelihood}`);
		  console.log(`    Sorrow: ${face.sorrowLikelihood}`);
		  console.log(`    Surprise: ${face.surpriseLikelihood}`);
		});
	}*/
	useEffect(() => {
		//const vision = require('@google-cloud/vision');

  		// Creates a client
	 	 /*const client = new vision.ImageAnnotatorClient();
	 	 console.log(client);*/
		//detectFaces('https://static01.nyt.com/images/2019/10/02/video/02-still-for-america-room-loop/02-still-for-america-room-loop-superJumbo.jpg');
	},[])
	
  return (
  	<div >
  		
	</div>

    
  );
}

export default Googlevision;
