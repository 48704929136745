
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import SideBar from './../Common/SideBar/index.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Link} from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { DataGrid } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import { NavLink,useHistory } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { TextField,Box,Button, List, ListItem, Typography,Checkbox  } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTableContainer-root': {
      width: '95%'
    },
    '& .MuiTableCell-head': {
      fontWeight: '600'
    }
  },
  table: {
      minWidth: 650,
      marginRight: 10 
    },
     button: {
    backgroundColor: '#33344A',
    color: '#FFFFFF'
  }
}))


const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 250 },
  { field: 'email', headerName: 'Email Address', width: 280 },
  {
    field: 'phone_number',
    headerName: 'Phone',
    type: 'number',
    width: 150,
  },
  /*{
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
  },*/
];

/*
const rows = [
  { id: 1, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 2, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 3, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 4, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 5, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 6, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 7, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 8, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 9, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 10, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 11, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
  { id: 12, name: 'Snow', email: 'Jon@jon.com', phone_number: 35 },
];*/

function TeamMembers() {
  let history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState('Controlled');
  const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
  const [teamName, setTeamName] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [memberlist, setMemberList] = useState([]);
  useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id })
        };
        fetch('https://api.digiroom.io/v1/team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setTeamName(response.teamname)
                  //setTeamId(response.teamid)
                  const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                      body: JSON.stringify({ teamid: response.teamid  })
                  };
                  fetch('https://api.digiroom.io/v1/team/memberlist', requestOptions)
                      .then(response => response.json())
                      .then(response => {
                            //const result = Object.values(response.members);
                            setMemberList(response.members)

                            //this.state = { response };
                      })
                })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

  function redirect(){
    history.push('/add-member');
  }

  return (
    <div>
    <div className="mainWrapper">
      <TopNav />
      <Box pl={4} pr={4}  pt={4}  pb={3} >
        <Box >
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
            <Link color="inherit" href="/" >
            {teamName}
            </Link>
            <Typography color="textPrimary">
            Team Members
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box>
        <Grid container spacing={0}>
          <Grid style={{border:'1px solid red;'}} item xs={6}>
            <Box>
              <Typography variant="h4" gutterBottom>
              Team Members
              </Typography>
            </Box>
          </Grid>
          <Grid style={{border:'1px solid red;'}} item xs={6}>
            <Box display="flex" flexDirection="row-reverse">
            <Button onClick={redirect} variant="contained" className={classes.button} startIcon={<AddIcon />}>
            Invite Member
            </Button>
          </Box>
          </Grid>
        </Grid>
        </Box>
            <Box>
              <div style={{ height: 400, width: '100%' }}>
                  <DataGrid rows={memberlist} columns={columns} pageSize={5} checkboxSelection />
                </div>
            </Box>
          </Box>
    </div>
  </div>
    
  );
}

export default TeamMembers;
