
import React,{useEffect,useState} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography  } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import { Link } from "react-router-dom";
import { Breadcrumbs } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import {BrowserRouter as Router,NavLink,Route} from 'react-router-dom';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
	root: {
		'& MuiTypography-root': {
    	fontFamily: 'Normal'
    		
    	}
	},
	tagLine: {
		
		fontSize: 14,
	},
	createButton: {
      backgroundColor: '#33344A',
      width: '100px',
      color: '#FFFFFF',
      
    }, 
    paper: {
    	padding: '15px'
    }, 
    appName: {
    	fontWeight: 'bold',
		color: '#333232',
		fontSize: '17px',
		fontFamily: 'Normal'
    },
    appDescription: {
    	fontSize: '13px',
    	fontFamily: 'Normal'
    } 
    
}))

/* const message = `Truncation should be conditionally applicable on this long line of text
 as this is a much longer line than what the container can support. `;*/

function AddApplication(props) {
	//console.log("Session: " + Session.get("sessionuser"));
	let history 	= useHistory();
	const classes 	= useStyles();


	const [state, setState] = useState({
		teamname: "",
		teamdescription: ""
	});

	
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const [teamId, setTeamId] = useState(null);
	const [teamName, setTeamName] = useState(null);
	const [userAppsListing, setUserAppsList] = useState([]);
	const [appsListing, setAppsList] = useState([]);
	//const [appsListingIds, setAppsListId] = useState([]);
	const [appInstalled, setAppInstalled] = useState([]);
	//console.log(appsListingIds);
    useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setTeamId(response.teamid)
                  setTeamName(response.teamname)

                })
    }, []);

     /*useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id  })
        };
        fetch('https://api.digiroom.io/application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  
                  //this.state = { response };
            })
   },[])*/

     useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'application/allapps', requestOptions)
            .then(response => response.json())
            .then(response => {
            	
                  setAppsList(response.apps)
                  setAppInstalled(response.installedapps)
            })
   },[])
     
  return (
  	<div>
  		<div className="mainWrapper">
			<TopNav />
			<Box  mt={5}  pl={4} pr={4}  pb={3} >
				<Box >
					<Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
						<Link color="inherit" href="/" >
						{teamName}
						</Link>
						<Typography color="textPrimary">
						Applications
						</Typography>
					</Breadcrumbs>
				</Box>
				<Box className={classes.root}>
					<Typography variant="h4" gutterBottom>
					Applicaitons
					</Typography>

					<Box>
						<Grid container spacing={3}>
        					{appsListing.map((val,key) => {
	        					return <Grid item sm={4} xs={12} >
									<Paper className={classes.paper}>
										<Grid container wrap="nowrap" spacing={2}>
											<Grid item>
												<img width="50" height="50" style={{padding: '10px'}} src={"/"+val.logo} />
											</Grid>
											<Grid item xs={12}>
												<Typography className={classes.appName}>{val.name}</Typography>
												<Typography className={classes.appDescription}>{val.description.substr(0, 60)}</Typography>
											</Grid>
											
										</Grid>
										<Grid xs={12}>
											<NavLink to={"/apps/"+val.appid} activeClassName="channel-active" >
											{appInstalled.includes(val.id)==true ? <Typography className="addAppButton" style={{backgroundColor:'#AAAAAA', color:'#000000'}}  variant="outlined">Installed</Typography> : <Typography className="addAppButton"  style={{backgroundColor:'#394E6D', color:'#FFFFFF'}} variant="outlined">Add</Typography>}

											
											</NavLink>
										
										</Grid>
									</Paper>
								</Grid>
							 })}
							
						</Grid>
					</Box>
				</Box>
	      	</Box>
		</div>
	</div>
  	
  );
}

export default AddApplication;
