
import React,{useContext,useState,useEffect} from 'react';
import { Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {BrowserRouter as Router,NavLink,Route,useHistory} from 'react-router-dom';
const APIURL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(theme => ({
    
    arrow: {
      color:'#FFFFFF !important',  
      cursor:'pointer'
    }
    
}));

function AssetCount(bucketId) {
	console.log('bucketId: '+bucketId);
	let history = useHistory();
	const userinfoItem  = localStorage.getItem('userinfo'); ;
	if(userinfoItem==null){
		history.push('/login');
	}
	const [assetCount, setAssetCount] = useState(null);
	const userinfo  = JSON.parse(userinfoItem);
	useEffect(() => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
			body: JSON.stringify({ userid: userinfo[0].id, channel: bucketId.channelId  })
		};
		fetch(APIURL+'assets/count', requestOptions)
		.then(response => response.json())
		.then(response => {
			setAssetCount(response.count);
		})       
   },[])
	return (
		<span><span>
			({assetCount})
		</span></span>
	)

}

export default AssetCount;
