import React,{useEffect,useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
const APIURL = process.env.REACT_APP_API_URL;


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    '& .MuiInputBase-input': {
       textAlign:'center',
       fontSize:25
      },
      '& .MuiCircularProgress-root': {
        /*display: "none"*/
      }, '& ..MuiCircularProgress-colorPrimary':{
        color: '#464775'
      }
  },
  singUpContainer: {
    height: '100%',
    textAlign: "center",
     width:'100%'
  },
  singUpLogo: {
    height: 85,

  },
  verifyHeading: {
    color: '#723FBA',
    fontSize: 30,
    fontWeight: '900'
  },
  verifyTagLine: {
    fontSize: 16,
  },
  textFieldRoot: {
      width: '14%',
      marginRight: 5,
      
    },
    verifyTextBox: {
      border: '1px solid #C6C6C6',
      borderRadius: '4px',
      width: '15%',
      height: '70px',
      fontSize: '40px',
      textAlign: 'center',
      outline: 'none',
      marginLeft: '4px'
    },
    continueButton: {
      backgroundColor: '#D71371',
      width: 400,
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 18,
      textTransform: 'none'
    },
    marginAutoItem: {
    margin: '0px auto',
   },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/user/nicknice)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

    
const Verify = props => {
  //console.log(props.match.params.token);
  let history               = useHistory();
  useEffect(() => {
        const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
        if(userinfo){
          window.location.href = '/dashboard';
        }
    }, []);

  //const { email } = (props.location && props.location.state) || {};
  //console.log(email);

  const classes = useStyles();
  const verifyToken = props.match.params.token;
  useEffect(() => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
        body: JSON.stringify({ token: verifyToken })
    };
    fetch(APIURL+'user/verifytoken', requestOptions)
    .then(response => response.json())
    .then(response => {
          setEmailAddress(response.email);
    })
  }, [verifyToken]);

  const Progress = () => (     <div><div>        <CircularProgress />      </div> </div>   );
  const [showProgress, setShowProgress] = React.useState(false);

  const [emailaddress, setEmailAddress] = useState(null);
  const [code1,setCode1] = useState("");
  const [code2,setCode2] = useState("");
  const [code3,setCode3] = useState("");
  const [code4,setCode4] = useState("");
  const [code5,setCode5] = useState("");
  const [code6,setCode6] = useState("");
 
 
 function ResendCode(){
    setShowProgress(true);

    jQuery('#successalert').hide()
    jQuery('#alert').hide()
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
        body: JSON.stringify({ email: emailaddress })
    };
    fetch(APIURL+'user/resendcode', requestOptions)
    .then(response => response.json())
    .then(response => {
            jQuery('#alert').hide()
            jQuery('#resendcodelink').hide()
            jQuery('#successalert').show()
            jQuery('#successalert .MuiAlert-message').html(response.message)
            setShowProgress(false)
        })
 }

 useEffect(() => {
   

    const handlePaste = (e) => {
      
      e.preventDefault();
      const form = e.target.form;
      const pastedData = e.clipboardData.getData('text/plain');
      const pastedArray = pastedData.split('').slice(0, 6);
      pastedArray.forEach((digit, index) => {
         form.elements[index].value = digit;
         console.log(index, digit)
      });
      setShowProgress(true);
      jQuery('#alert').hide();
      jQuery('#successalert').hide()
      jQuery('#keywrongalert').hide();
      async function postAuthKey(){
          const response = await fetch(APIURL+'user/validate_email',{
            "method": "POST",
            "headers": {
              "Accept": "application/json",
              "Content-type": "application/json"
            },
            "body" : JSON.stringify({
              code: pastedData,
              email: document.getElementById('emailid').value
            })
          });

        const json = await response.json();
        
         if(json.http_code=='200') {
          jQuery('#keywrongalert').hide();
            window.setTimeout(() => {
              setShowProgress(false);
              //redirectFunc();
              history.push('/setpassword/'+json.password_token);
            }, 3000);

         } else {
          setShowProgress(false);
          if(json.http_code==='400'){
            jQuery('#alert').show();
            jQuery('#alert .MuiAlert-message').html(json.message);
          }
          //jQuery('#keywrongalert').show();
         }
      }
      postAuthKey();
    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);


  function handleEnter(event) {
    //console.log(event.keyCode);

    event.preventDefault();
    // alert(code1);
    const code6 = jQuery('#code6value').val();
    //alert(code1+code2+code3+code4+code5+code6);
    const form = event.target.form;
    
      const index = Array.prototype.indexOf.call(form, event.target);
      if(event.keyCode=='8'){
        //console.log(index);
        if(index>0){
         form.elements[index - 1].focus();
        }
        //event.stopPropagation();
      } else {
          if(index==5){
            setShowProgress(true);
            jQuery('#alert').hide();
            jQuery('#successalert').hide()
            jQuery('#keywrongalert').hide();
            async function postAuthKey(){
                const response = await fetch(APIURL+'user/validate_email',{
                  "method": "POST",
                  "headers": {
                    "Accept": "application/json",
                    "Content-type": "application/json"
                  },
                  "body" : JSON.stringify({
                    code: code1+code2+code3+code4+code5+code6,
                    email: emailaddress
                  })
                });

              const json = await response.json();
              
               if(json.http_code=='200') {
                jQuery('#keywrongalert').hide();
                  window.setTimeout(() => {
                    setShowProgress(false);
                    //redirectFunc();
                    history.push('/setpassword/'+json.password_token);
                  }, 3000);

               } else {
                setShowProgress(false);
                if(json.http_code==='400'){
                  jQuery('#alert').show();
                  jQuery('#alert .MuiAlert-message').html(json.message);
                }
                //jQuery('#keywrongalert').show();
               }
            }
            postAuthKey();
            
       
       
        } else {
          form.elements[index + 1].focus();
        }
      }
      
      //event.preventDefault();
  }
  

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          
          <Box  alignItems="center" className={classes.marginAutoItem}>
            <div className={classes.singUpContainer}>
              <br /><br />
              <img className={classes.singUpLogo} alt={process.env.REACT_APP_BASE_NAME} Logo src="/large.png" />
              {(emailaddress=='not found') ?  
                <div>
                  <h1 className={classes.verifyHeading}>Check your email for code</h1>
                  <h3 className={classes.verifyTagLine}>It seems you accessed invalid URL. <br />Please check back your email inbox for valid URL.</h3>
                </div>
               :
              <div>
              <div>
                <h1 className={classes.verifyHeading}>Check your email for code</h1>
              <h3 className={classes.verifyTagLine}>We have emailed you a 6 character verification code to {emailaddress}. <br />Please enter that code below to proceed.</h3>
              </div>
              <Form className="verify-form"  method="POST">
              <div>
                <input key="0"  className="verifyTextBox" maxlength="1" onChange={(event) => { setCode1(event.target.value) }} type="text" tabindex="1" size="1" onKeyUp={handleEnter}  />
                <input key="1"  className="verifyTextBox" maxlength="1" onChange={(event) => { setCode2(event.target.value) }} type="text" tabindex="2" size="1" onKeyUp={handleEnter}  />
                <input key="2"  className="verifyTextBox" maxlength="1" onChange={(event) => { setCode3(event.target.value) }} type="text" tabindex="3" size="1" onKeyUp={handleEnter}  />
                <input key="3"  className="verifyTextBox" maxlength="1" onChange={(event) => { setCode4(event.target.value) }} type="text" tabindex="4" size="1" onKeyUp={handleEnter}  />
                <input key="4"  className="verifyTextBox" maxlength="1" onChange={(event) => { setCode5(event.target.value) }} type="text" tabindex="5" size="1" onKeyUp={handleEnter}  />
                <input key="5"  className="verifyTextBox" maxlength="1" id="code6value" type="text" tabindex="6" size="1" onKeyUp={handleEnter}  />
                <input type="hidden" id="emailid" name="email" value={emailaddress} />
              </div>
              <br />
              
              { showProgress ? <Progress /> : null }
              
              </Form>
              
              <Alert id="alert" severity="error" style={{display:"none"}} >
              
              </Alert>
              <Alert id="successalert" severity="success" style={{display:"none"}} >
              
              </Alert>
              <Alert id="keywrongalert" severity="error" style={{display:"none"}} >
              Invalid or expired code. Please try again.
              </Alert>
              <Box alignItems="center" justifyContent="space-between" mt={5}>
              Can't find your code? Please check your spam folder or <b style={{cursor: 'pointer', color: '#371b58'}} onClick={ResendCode}>click here</b> to resend.
              </Box>
              </div>
              }
            </div>
            </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default Verify;