import React,{useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import { Form } from "react-bootstrap";
const APIURL = process.env.REACT_APP_API_URL;


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
    
  },
 
  singUpContainer: {
    height: '100%',
    textAlign: "center",
     width:'100%'
  },
  singUpLogo: {
    height: 85,

  },
  singUpHeading: {
    fontSize: 40,
    fontWeight: '900',
    color:'#723FBA'
  },
  links: {
    color: '#371b58'
  },
  singUpTagLine: {
    fontSize: 16,
  },
  textFieldRoot: {
      width: '100%'
    },
    continueButton: {
      backgroundColor: '#723FBA',
      width: '100%',
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 18,
      textTransform: 'none'
    },
    marginAutoItem: {
    margin: '0px auto',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/user/nicknice)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
    theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


const SetPasswordSide = props => {

  //console.log(props.location.state);
    const [emailaddress ,setEmailAddress] = useState();
    const [password ,setPassword] = useState();
    useEffect(() => {
        //const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
        //if(userinfo){
        //  window.location.href = '/dashboard';
       // }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ token: props.match.params.hash })
        };
        fetch(APIURL+'user/emailbytoken', requestOptions)
          .then(response => response.json())
          .then(response => {
            if(response.email=='notfound'){
              window.location = process.env.REACT_APP_BASE_URL
            }
            setEmailAddress(response.email);
            setPassword(response.password);
            console.log(response.password);
            //return;
            //if(response.password!=null ){
                const requestArg = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                  body: JSON.stringify({ email: response.email })
                };
                fetch(APIURL+'user/acceptinvite', requestArg)
                  .then(uresponse => uresponse.json())
                  .then(uresponse => {
                    if(uresponse.http_code=='200'){
                      //console.log(uresponse.message);
                      //window.location = process.env.REACT_APP_BASE_URL+'login'
                    }
                    //setEmailAddress(response.email);
                    
                }) 
            //}
            
        })

    }, []);
     const Progress = () => (
      <div><div>
        <CircularProgress />
      </div></div>
    );
    const [showProgress, setShowProgress] = React.useState(false);
    //const emailaddress  = (props.location.pathname=='/setpassword') ? props.location.state.emailaddress :   setemail; 
    ////(props.location && props.location.state) || {};
    

  const classes = useStyles();
  const [state, setState] = useState({
    password: "",
    cpassword: ""
  });

  const handleInputChange = (event) => {
    if(event.keyCode=='13'){
      postData(event);
    }
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  

    async function postData(event){
        event.preventDefault();
        
        let response = await fetch(APIURL+'user/setpassword',{
            "method": "POST",
           "headers": {
              "Accept": "application/json",
              "Content-type": "application/json"
            },
            "body" : JSON.stringify({
              password: state.password,
              cpassword: state.cpassword,
              email: emailaddress,
              token: props.match.params.hash
            })
          });

          const json = await response.json();
          if(json.http_code=='200'){
            setShowProgress(true);
            let loginresponse = await fetch(APIURL+'user/login',{
             "method": "POST",
             "headers": {
                "Accept": "application/json",
                "Content-type": "application/json"
              },
              "body" : JSON.stringify({
                email: emailaddress,
                password: state.password
              })
            });

            const loginjson = await loginresponse.json();
            if(loginjson.http_code=='400'){
              jQuery('#alert').show();
              jQuery('#alert .MuiAlert-message').html(loginjson.message);
            } else if(loginjson.http_code=='200'){
              jQuery('#alert').hide();
              
              //jQuery('#successalert').show();
              //jQuery('#successalert .MuiAlert-message').html(loginjson.message);
              //localStorage.setItem('usertoken','fajsasd977DSD7s87sahj3');
              localStorage.setItem('userinfo',JSON.stringify(loginjson.userinfo));
              localStorage.setItem('teaminfo',JSON.stringify(loginjson.teaminfo));
              window.setTimeout(() => {
                setShowProgress(false);
                window.location.href = loginjson.redirect;
                //history.push('/'+json.redirect);
              }, 3000);
            }
            //history.push('/thanks');
          } else if(json.http_code=='400'){ 
            jQuery('#alert').show();
            jQuery('#alert .MuiAlert-message').html(json.message);
          } /*else if(json.http_code=='408'){ 
            jQuery('#paswordempty').show();
            jQuery('#paswordmismatch').hide();
             jQuery('#paswordlength').hide();
           } else if(json.http_code=='411'){ 
            jQuery('#paswordlength').show();
            jQuery('#paswordempty').hide();
            jQuery('#paswordmismatch').hide();
          }*/

          
          

      }
    ///console.log('emailaddress: '+emailaddress);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          
          <Box  alignItems="center" className={classes.marginAutoItem}>
            <div className={classes.singUpContainer}>
               <img className={classes.singUpLogo} src="/large.png" />
              <div>
                <h1 className={classes.singUpHeading}>Set your password</h1>
              </div>
              <Form onSubmit={postData} className="register-form" method="POST">
              <div>
                <TextField onKeyUp={handleInputChange} onChange={handleInputChange}  label="Password" name="password" type="password" className={classes.textFieldRoot} id="outlined-basic" variant="outlined" />
              </div>
              <br />
              <div>
                <TextField onKeyUp={handleInputChange} onChange={handleInputChange}  label="Re-enter Password" name="cpassword" type="password" className={classes.textFieldRoot} id="outlined-basic" variant="outlined" />
              </div>
              <br />
              <div>
                
                
              </div>
              <Button onClick = {postData} variant="contained" className={classes.continueButton} >
                Submit
                </Button>
                <br /><br />

              { showProgress ? <Progress /> : null }
              <Link

                id="linkclicking" 
                to={{
                  pathname: "/verify",
                  state
                }}
              >&nbsp;
              </Link>
              </Form>
              <Alert id="alert" severity="error" style={{display:"none"}} >
              
              </Alert>
              <Alert id="successalert" severity="success" style={{display:"none"}} >
              
              </Alert>
              
              <Box alignItems="center" justifyContent="space-between" mt={5}>
              Already Using {process.env.REACT_APP_BASE_NAME}?
              </Box>
              <Box alignItems="center" justifyContent="space-between" mt={1}>
                <Link to="/login" className={classes.links}><b>Sign in</b></Link> to an existing team
              </Box>
            </div>
            </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default SetPasswordSide;