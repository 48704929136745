
const APIURL = process.env.REACT_APP_API_URL;
//const API_URL = "http://127.0.0.1:8000/"


//service for all subscription/user purposes
class AppService {
   
   PIMCredValidate(pimcredentials) {
        console.log(pimcredentials);
    }



}

export default new AppService();