
import React,{useEffect,useState} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography  } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import { Link } from "react-router-dom";
import { Breadcrumbs } from '@material-ui/core';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
	tagLine: {
		
		fontSize: 14,
	},
	createButton: {
      backgroundColor: '#33344A',
      width: '100px',
      color: '#FFFFFF',
      
    }
}))

 

function AddChannel(props) {
	//console.log("Session: " + Session.get("sessionuser"));
	let history = useHistory();
	const classes = useStyles();

	const theme = createMuiTheme({
	typography: {
		fontFamily: 'Normal',
	}});

	const [state, setState] = useState({
		teamname: "",
		teamdescription: ""
	});

	const handleInputChange = (event) => {
		jQuery('#character_limit').html(15-jQuery('#channelNameField').val().length);
		
		//console.log(jQuery('#channelNameField').val().length);
		if(event.keyCode=='13'){
			postData(event);
		}
		const { name, value } = event.target;
		setState(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const [teamId, setTeamId] = useState(null);
	const [teamName, setTeamName] = useState(null);
 

    useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setTeamId(response.teamid)
                  setTeamName(response.teamname)

                })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

	async function postData(event){
		event.preventDefault();
		let response = await fetch(APIURL+'channel/create',
			{
			"method": "POST",
			"headers": {
						"Accept": "application/json",
						"Content-type": "application/json"
						},
			"body" : JSON.stringify(
			{
				name: state.teamname,
				description: state.teamdescription,
				userid: userinfo[0].id,
				teamid: teamId
			}
			)


	});
	
      const json = await response.json();
      //sideBarRenderer(true);
      if(json.http_code=='400'){
		jQuery('#alert').show();
		jQuery('#successalert').hide();
		jQuery('#alert .MuiAlert-message').html(json.message);
      } else {
      	;
      	jQuery('#alert').hide();
      	jQuery('#successalert').show();
		jQuery('#successalert .MuiAlert-message').html(json.message);
		//console.log(props);
		//document.getElementById('linkclicking').click();
		
		//jQuery('.MuiCollapse-wrapperInner div.MuiList-root').append('<div class="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button" tabindex="0" role="button" aria-disabled="false"><a class="channel-active" href="'+json.redirect+'" aria-current="page"><div class="makeStyles-channellink-11"><svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style="font-size: 15px;"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path></svg></div><div class="makeStyles-channellink-11">'+json.channelname+'</div></a><span class="MuiTouchRipple-root"></span></div>');
		//ReactDOM.render(<SideBar />,document.getElementById('root'));

		window.setTimeout(() => {
			history.push(json.redirect);
			//window.location.href = json.redirect;
		}, 2000);
      }

  	}

  return (
  	<div>
  		<div className="mainWrapper">
			<TopNav />
			<Box  mt={5}  pl={4} pr={4}  pb={3} >
			<Box >
				<Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
					<Link color="inherit" href="/" >
					{teamName}
					</Link>
					<Typography color="textPrimary">
					Create channel
					</Typography>
				</Breadcrumbs>
			</Box>
			<form onSubmit={postData} method="post">
				<ThemeProvider theme={theme}>
		      	<Typography component="div" >
					<Box fontWeight="fontWeightBold" fontSize="h4.fontSize" mt={1}>
					Create a channel
					</Box>
					
					<Box fontWeight="fontWeightRegular" mt={1} mb={1}>
			        Channels are main buckets where your team uploads assets. They're best when organized around specific assets.
			        </Box>
					<Box>
					<TextField required inputProps={{ maxLength: 15 }} className={'invitememberfield '} onKeyUp={handleInputChange} name="teamname" id="channelNameField" style = {{width: 450}} label="Channel Name" variant="outlined" /> <span style={{lineHeight: '50px', fontSize: '13px'}}>(<span id="character_limit">15</span> characters remaining)</span>
					</Box>

					<Box mt={1} >
					<TextField onKeyUp={handleInputChange}  className={'invitememberfield'}  name="teamdescription" id="outlined-basic" style = {{width: 450}} label="Description" variant="outlined" />
					</Box>
					<Box mt={1} >
					<Button  onClick={postData} variant="contained" className={classes.createButton} >
			      	Create
			      	</Button>
			        </Box>
			        <Link
		                id="linkclicking" 
		                to={{
		                  pathname: "/channel/general",
		                  state
		                }}
		              >&nbsp;
		              </Link>
		      	</Typography>
		      	</ThemeProvider>
		      	</form>
		      	<br />
		      	<Alert id="alert" severity="error" style={{display:"none",'width':'400px'}} >
              
              	</Alert>
              	<Alert id="successalert" severity="success" style={{display:"none",'width':'540px'}} >
              
              	</Alert>
	      	</Box>
		</div>
	</div>
  	
  );
}

export default AddChannel;
