
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import {NavLink,useHistory} from 'react-router-dom';


import { TextField,Box,Button, Typography  } from '@material-ui/core';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({
		root: {
			'& .MuiTextField-root': {
			margin: theme.spacing(1),
			width: '25ch',
			},
			'& .MuiButton-root':{
				margin:'0px',
			}, 
			'& .MuiTypography-root' :{
				fontFamily: 'Normal',
				lineHeight: '28px',
				cursor: 'pointer'
			}, '& .MuiCircularProgress-root':{
				width:'30px',
				height: '30px',
				marginTop:'8px',
				marginLeft:'10px'
			}, '& .MuiCircularProgress-colorPrimary':{
				color: '#464775'
			}
		},
		saveButtonBox:{
			width:'99%',
			borderTop:'1px solid rgb(204, 204, 204)'
		},
		saveButton: {
			backgroundColor: '#394E6D',
			width: '100px',
			color: '#FFFFFF',
		},
		avatarpreview: {
			width: '210px'
		},
		avatarDiv: {
			border: '1px solid #CCC',
			width: '200px',
			height: '200px'
		},
		dropzone: {

		},
		uploadDiv: {
			border: '1px solid #CCC',
			width: '200px',
			marginTop: '10px',
			borderRadius: '4px',
			textAlign: 'center',
			

		}
	  
}))

function Profile() {
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	//const [teamId, setTeamId] = useState(null);
	let history = useHistory();
	const [useremail, setUserEmail] = useState({
	    email: "",
	    name: "",
	    phone_number: "",
	    mobile_number: "",
	    designation: "",
	    address: "",
	    picture: ""
	  });
	const classes = useStyles();
	const [showProgress, setShowProgress] = React.useState(false);
	const handleInputChange = (event) => {
		//console.log(event.keyCode);
		if(event.keyCode=='13'){
	      postData(event);
	    }
		const { name, value } = event.target;
	    setUserEmail(prevState => ({
	      ...prevState,
	      [name]: value
	    }));
	    
	}

useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ teamid: teaminfo, userid: userinfo[0].id })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  //setTeamId(response.teamid)
                })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

		const [files, setFiles] = useState([]);
	  const { getRootProps, getInputProps } = useDropzone({
	    accept: 'image/*',
	    multiple: false,
	    onDrop: acceptedFiles => {
			const files = acceptedFiles.map(file => {
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload=(e)=>{
					console.log(file.type);
					const requestOptions = {
			            method: 'POST',
			            headers: { 'Content-Type': 'multipart/form-data',"Accept": "multipart/form-data" },
			            body: JSON.stringify({ 
			            	file: e.target.result,
			            	filetype: file.type,
			            	filesize: file.size,
			            	filename: file.name,
			            	teamid: teaminfo,
			            	userid: userinfo[0].id,
			            })
			        };
			        jQuery('#topBarPicture').html('');
			        jQuery('#topBarPicture').html('<div onClick={handleClick} class="MuiAvatar-root MuiAvatar-circle makeStyles-userTitle-35" width="32"><img src="'+e.target.result+'" class="MuiAvatar-img" /></div>');
					fetch(APIURL+'user/uploadpicture', requestOptions)
					.then(response => response.json())
					
            		.then(response => setUserEmail(prevState => ({
				      ...prevState,
				      ['picture']: response.url,
				      

				    })))
				} 
					

			});
			setFiles(

		        acceptedFiles.map(file =>
		          Object.assign(file, {
		            preview: URL.createObjectURL(file),
		          }),
		        ),
		        jQuery('#previewimage').hide(),
		      );
			//setShowProgress(false);
		},

	  });

	  const thumbs = files.map(file => (
	    <img src={file.preview} style={{width:'192px', height:'192px',margin:'4px'}}  alt="" />
	  ));

	



	async function postData(event){
          event.preventDefault();
          jQuery('#successalert').hide();
          setShowProgress(true);
          let response = await fetch("https://api.digiroom.io/v1/user/updateprofile",{
           "method": "POST",
           "headers": {
              "Accept": "application/json",
              "Content-type": "application/json"
            },
            "body" : JSON.stringify({
              userid: userinfo[0].id,
              userdata: useremail
            })
          });

          const json = await response.json();
          console.log(json.message);
          jQuery('#successalert').show();
          jQuery('#successalert .MuiAlert-message').html(json.message);
          setShowProgress(false);
          history.push('/profile');
	    }


    useEffect(() => {
    	// POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id })
        };
        fetch(APIURL+'user/details', requestOptions)
            .then(response => response.json())
            .then(response => {
            	setUserEmail(response.userdetails[0])
            })
           
			//console.log(data.id);
			// empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

  return (
  	<div className="bradecrumbbox">
  		<div className="mainWrapper">
			<TopNav />
			<Box pl={4} pr={4}  pt={4}  pb={3} >
		    	<form onSubmit={postData} className={classes.root} noValidate autoComplete="off">
		    	<Typography variant="h4" gutterBottom>
				Profile
				</Typography>
			    <Box style={{maxHeight:window.innerHeight-190, overflow:'auto',  scrollbarWidth: 'thin', width: '99%', minHeight:'400px'}}>
				    
					<Grid container spacing={0}>
				    	<Grid style={{border:'1px solid red;'}} item xs={12} sm={6}>
				    		
				          	<div>
						    	<TextField
						    		disabled
						          id="outlined-disabled"
						          label="Email"
						          rows={4}
						          fullWidth
						          style={{width:'95%'}}
						          defaultValue=" "
						          variant="outlined"
						          value={useremail.email}
						          
						          name="email"
						          InputLabelProps={{
						            shrink: true,
						            readOnly: true,
						          }}
						        />
					       	</div>
					       	<div>
					       		<TextField
						          id="outlined-multiline-static"
						          label="Full Name"
						          rows={4}
						          fullWidth
						          style={{width:'95%'}}
						          variant="outlined"
						          value={useremail.name}
						           defaultValue=" "
						           name="name"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	<div>
					       		<TextField
						          id="outlined-multiline-static"
						          label="Phone"
						          rows={4}
						          fullWidth
						          style={{width:'95%'}}
						          variant="outlined"
						          value={useremail.phone_number}
						           defaultValue=" "
						           name="phone_number"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	<div>
					       		<TextField
						          id="outlined-multiline-static"
						          label="Mobile"
						          rows={4}
						          fullWidth
						          style={{width:'95%'}}
						          variant="outlined"
						          value={useremail.mobile_number}
						           defaultValue=" "
						           name="mobile_number"
						          onChange={handleInputChange}
						          onKeyUp={handleInputChange} 
						          InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	<div>
					       		<TextField
						          id="outlined-multiline-static"
						          label="Designation"
						          rows={4}
						          fullWidth
						          style={{width:'95%'}}
						          variant="outlined"
						          value={useremail.designation}
						           defaultValue=" "
						           name="designation"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	<div>
					       		<TextField
						          id="outlined-multiline-static"
						          label="Address"
						          rows={4}
						          fullWidth
						          style={{width:'95%'}}
						          variant="outlined"
						          value={useremail.address}
						           defaultValue=" "
						           name="address"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	<div style={{display:'none'}}>
					       	<TextField
					       			id="hiddenfieldimg"
						          type="text"
						           style={{width:'95%'}}
						          value={useremail.picture}
						           defaultValue=" "
						           name="picture"
						           onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          
						        />
						        </div>
				        </Grid>
				        <Grid style={{border:'1px solid red;'}} item xs={12} sm={6}>
				        <Box className={classes.avatarpreview}>
					        <div className={classes.avatarDiv}>{thumbs}<img id="previewimage" style={{margin:'4px', width:'192px', height: '192px'}} src={useremail.picture} /></div>
							<div className={classes.uploadDiv}>
								<Box className={classes.dropzone} {...getRootProps()}>
								<input {...getInputProps()} />
								<Typography>Upload an Image</Typography>
								</Box>

							</div>
				        </Box>
				        </Grid>
				        
			    	</Grid>
			     	
					
			       	
				    
				    
				    
			     </Box> 
			     <Box className={classes.saveButtonBox}>
				<div style={{float:'left', paddingTop: '5px'}}>
				    	<Button
					        variant="contained"
					        color="secondary"
					        size="large"
					        className={classes.saveButton}
					        startIcon={<SaveIcon />}
					        onClick={postData}
					      >
					        Save
					      </Button>
					      
				    </div>
				    <div style={{float:'left', paddingTop: '5px'}}>
				    { showProgress ? <CircularProgress size={30} /> : null }
				    <Alert id="successalert" severity="success" style={{display:"none"}} >
              
              		</Alert>
				    </div>
			     </Box>
				

					
			    </form>
	      	</Box>
		</div>
	</div>
  	
  );
}

export default Profile;
