
import { useHistory } from 'react-router-dom';
const APIURL = process.env.REACT_APP_API_URL;

function Logout () {


  const userinfo  = localStorage.getItem('userinfo');

 
  if(userinfo==null){
    window.location.href = '/login';
  }
   let userjson = JSON.parse(userinfo);
    if(userjson==null){
      window.location.href = '/login';
    }
    async function postData(){
          let response = await fetch(APIURL+'user/logout',{
           "method": "POST",
           "headers": {
              "Accept": "application/json",
              "Content-type": "application/json"
            },
            "body" : JSON.stringify({
              email: userjson[0].email,
              userid: userjson[0].id
            })
          });

          const json = await response.json();
          //if(json.http_code=='200'){
            localStorage.clear();
            window.location.href = '/login'; //history.push('/login');
         // }
          
          

      }

      postData();
  return (
    ''
  );
}

export default Logout;