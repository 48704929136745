
import React,{useEffect,useState} from 'react';
import jQuery from 'jquery'; 
 
function Editor({match}) {
	const closeEditor = (event) => {
		jQuery('#grid_previewpan').animate({
            bottom: '-'+jQuery('#grid_previewpan').height()
        },1000, function(){
            jQuery('#crop_section').hide();
            jQuery('#facebook_crop').hide();
            jQuery('#twitter_crop').hide();
            jQuery('#pinterest_crop').hide();
            jQuery('#googlead_crop').hide();
        });
	}


	return (
<div id="grid_previewpan" style={{backgroundColor: '#424650'}} >
	<div class="preview_close" align="center">
    	<img onClick={closeEditor}  title="Close" src="https://files.stocky.ai/uploads/2020/11/arrow_down_3.png" />
    </div>
    
    
   
</div>
	);
}
export default Editor;
