import React,{useContext,useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import { DataGrid } from '@material-ui/data-grid';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Button, Box, Card, CardActions, CardContent, CardMedia, Typography, CardHeader, Avatar} from '@material-ui/core';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import FolderIcon from '@material-ui/icons/Folder';
import ImageIcon from '@material-ui/icons/Image';
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineElement, Title, CategoryScale } from 'chart.js';
import {BrowserRouter as Router,NavLink,Route,useHistory} from 'react-router-dom';

import { Pie,Line } from 'react-chartjs-2';
const APIURL = process.env.REACT_APP_API_URL;

ChartJS.register(ArcElement, Tooltip,CategoryScale,  LinearScale,  PointElement,  LineElement,  Title);
		
const useStyles = makeStyles(theme => ({
	channelsClass:{
		border:'3px solid #FFF',
		backgroundColor: '#324661', 
		padding:'10px !important',
		'& .MuiTypography-subtitle2': {
			color: '#FFF'
		},
		'& .MuiSvgIcon-root': {
			color: '#FFF'
		}
	}
}))




export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Assets per month',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];

export const dataa = {
  labels,
  datasets: [
    
    {
      label: 'Assets',
      data: [-101, 0, 357, 100, 800, 250, 950, 1500],
      borderColor: 'rgb(237, 12, 79)',
      backgroundColor: 'rgba(237, 12, 79, 0.5)',
    },
  ],
};

export default function Dashboard() {
		//let history = useHistory();
		 const history = useHistory();
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const classes = useStyles();
	const [deletechannelid, setChannelId] = useState(null);
	const [deletefolderid, setFolderId] = useState(null);
	const [channellisting, setChannelList] = useState([]);
	const [openchanneldeletedialog, setChannelDeleteDialog] = React.useState(false);
	const [openfolderdeletedialog, setFolderDeleteDialog] = React.useState(false);
	const [folderlisting, setFolderList] = useState([]);
	const [memberlist, setMemberList] = useState([]);
	const [stats, setStats] = useState({
		totalChannels: '',
		totalFolders: '',
		totalMembers: '',
		totalAssets: '',
		totalGifs: '',
		totalJpgs: '',
		totalPngs: '',
	});
	
	useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'user/permissions', requestOptions)
            .then(response => response.json())
            .then(response => {
                 // console.log(response.permission)
                  //setUserPermissions(response.permission)
                 if(response.permission.view_dashboard==false){
                 	history.push('/profile');
                 	//window.location.href = '/profile';
                 }
                  //response.permission.view_dashboard;

                  //this.state = { response };
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [history]);
	const deleteChannelAgree = () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
			body: JSON.stringify({ channelid: deletechannelid, teamid: teaminfo })
		};
		fetch(APIURL+'channel/delete', requestOptions)
		.then(response => response.json())
		.then(response => {
			console.log(response);
		})
		setChannelDeleteDialog(false);
	}

	const deleteFolderAgree = () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
			body: JSON.stringify({ folderid: deletefolderid, channelid: deletechannelid, teamid: teaminfo })
		};
		fetch(APIURL+'folder/delete', requestOptions)
		.then(response => response.json())
		.then(response => {
			console.log(response);
		})
		setFolderDeleteDialog(false);
	}

	const channelcolumns = [
	  { field: 'id', headerName: 'ID', width: 80, hide: true },
	  { field: 'name', headerName: 'Channel Name', width: 200 },
	  {
	    field: 'action',
	    headerName: 'Action',
	    width: 150,
	    sortable: false,
	    renderCell: (params) => {
	      const onDelete = (e) => {
	      	setChannelId(params.row.id);
	      	setChannelDeleteDialog(true);
			e.stopPropagation();
	      };

	      return <div><Button onClick={onDelete}> <Delete /> </ Button></div>;
	    },
	  }
	  
	];
	const foldercolumns = [
	  { field: 'id', headerName: 'ID', width: 80, hide: true },
	  { field: 'foldername', headerName: 'Folder Name', width: 200 },
	  { field: 'channelname', headerName: 'Channel Name', width: 200 },
	  {
	    field: 'action',
	    headerName: 'Action',
	    width: 150,
	    sortable: false,
	    renderCell: (params) => {
	  
	      const onDelete = (e) => {
	      	setChannelId(params.row.channelid);
	      	setFolderId(params.row.id);
	      	setFolderDeleteDialog(true);
	        e.stopPropagation();
	      };

	      return <div><Button onClick={onDelete}> <Delete /> </ Button></div>;
	    },
	  }
	  
	];


	const data = {
	  labels: ['Jpeg', 'Gif', 'Png'],
	  datasets: [
	    {
	      label: '# of Assets',
	      data: [stats.totalJpgs, stats.totalGifs, stats.totalPngs],
	      
	      backgroundColor: [
	        'Red', 'Blue', 'Green'
	      ],
	      borderColor: [
	        'Red', 'Blue', 'Green'
	      ],
	      borderWidth: 0,
	    },
	  ],
	};
	useEffect(() => {

	    // POST request using fetch inside useEffect React hook
	    const requestOptions = {
	        method: 'POST',
	        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
	        body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
	    };
	    fetch(APIURL+'channel/list', requestOptions)
	        .then(response => response.json())
	        .then(response => {
	              setChannelList(response.channels)
	        })
	}, []);




	 useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'folder/list', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setFolderList(response.folder)
                  //this.state = { response };
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

	/*useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, bucket: bucketId.bucketID  })
        };
        fetch(APIURL+'directory/list', requestOptions)
        .then(response => response.json())
        .then(response => {
              setDirectoryList(response.directories)
              //this.state = { response };
        })
       
   })*/

	useEffect(() => {

	    // POST request using fetch inside useEffect React hook
	    const requestOptions = {
	        method: 'POST',
	        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
	        body: JSON.stringify({ teamid: teaminfo  })
	    };
	    fetch(APIURL+'team/stats', requestOptions)
	        .then(response => response.json())
	        .then(response => {
	        	
	        		setStats({totalChannels:response.channels, totalFolders:response.folders, totalMembers:response.members, 
	        			totalAssets:response.assets,  totalGifs:response.gifs, totalJpgs:response.jpgs, totalPngs:response.pngs })
	             
	        })
	}, []);
  return (
  	<div>
		<div className="mainWrapper">
			<TopNav />
			<div className="App">
				<Box>
					<Box style={{paddingLeft: '8px'}} >
						<h1>Media Stats</h1>
					</Box>
					<Box p={2}  style={{maxHeight:window.innerHeight-158, overflow:'auto',  scrollbarWidth: 'thin', paddingTop: '10px'}}>
						<Grid container spacing={3}  >
							<Grid container style={{border:'0px solid red', padding:'0px !important'}}  xs={8}>
				                <Grid style={{border:'3px solid #FFF', backgroundColor: '#EEE', padding:'10px'}} item xs={6}>
									<div style={{backgroundColor:'rgb(63, 138, 255)', padding: '20px', color:'#FFF'}}>
										<div style={{float: 'left', paddingTop: '10px'}}>
											<div style={{fontSize: '28px'}}>{stats.totalChannels}</div>
											<div style={{fontSize: '12px'}}>{stats.totalChannels==1 ? 'Channel' : 'Channels'}</div>
										</div>
										<div style={{float: 'right'}}>
											<AlternateEmail  style={{fontSize: '91px', color: '#FFFFFF3D'}} />
										</div>
										<div style={{clear: 'both'}}></div>
									</div>
								</Grid>
								
								<Grid style={{border:'3px solid #FFF', backgroundColor: '#EEE', padding:'10px'}} item xs={6}>
									<div style={{backgroundColor:'#30435D', padding: '20px', color:'#FFF'}}>
										<div style={{float: 'left', paddingTop: '10px'}}>
											<div style={{fontSize: '28px'}}>{stats.totalFolders}</div>
											<div style={{fontSize: '12px'}}>{stats.totalFolders==1 ? 'Folder' : 'Folders'}</div>
										</div>
										<div style={{float: 'right'}}>
											<FolderIcon  style={{fontSize: '91px', color: '#FFFFFF3D'}} />
										</div>
										<div style={{clear: 'both'}}></div>
									</div>
								</Grid>
								<Grid style={{border:'3px solid #FFF', backgroundColor: '#EEE', padding:'10px'}} item xs={6}>
									<div style={{backgroundColor:'#2A61B1', padding: '20px', color:'#FFF'}}>
										<div style={{float: 'left', paddingTop: '10px'}}>
											<div style={{fontSize: '28px'}}>{stats.totalAssets}</div>
											<div style={{fontSize: '12px'}}>{stats.totalAssets==1 ? 'File' : 'Files'}</div>
										</div>
										<div style={{float: 'right'}}>
											<ImageIcon  style={{fontSize: '91px', color: '#FFFFFF3D'}} />
										</div>
										<div style={{clear: 'both'}}></div>
									</div>
								</Grid>
								
								<Grid style={{border:'3px solid #FFF', backgroundColor: '#EEE', padding:'10px'}} item xs={6}>
									<div style={{backgroundColor:'#5789cf', padding: '20px', color:'#FFF'}}>
										<div style={{float: 'left', paddingTop: '10px'}}>
											<div style={{fontSize: '28px'}}>{stats.totalMembers}</div>
											<div style={{fontSize: '12px'}}>{stats.totalMembers==1 ? 'Member' : 'Members'}</div>
										</div>
										<div style={{float: 'right'}}>
											<PersonIcon  style={{fontSize: '91px', color: '#FFFFFF3D'}} />
										</div>
										<div style={{clear: 'both'}}></div>
									</div>
								</Grid>
							</Grid>
							<Grid style={{border:'3px solid #FFF', backgroundColor: '#EEE'}} item xs={4}>
								<Typography><b>Media Breakdown by Type</b></Typography>
								<Pie data={data} style={{maxHeight:'250px'}} />
								
							</Grid>
							
							
						</Grid>
						
						<Grid container spacing={3} style={{padding: '25px 5px 0px 5px'}}   >
						
						</Grid>

						<Grid  container spacing={3}  >
							<Grid style={{border:'3px solid #FFF', backgroundColor: '#EEE', padding:'10px'}} item xs={5}>
							<Typography variant="h6" component="div" gutterBottom>Your Channels</Typography>
								<div style={{ height: 400, width: '100%' }}>
		    				      <DataGrid rows={channellisting} columns={channelcolumns} pageSize={5} checkboxSelection  />
		    				    </div>
							</Grid>
							<Grid style={{border:'3px solid #FFF', backgroundColor: '#EEE', padding:'10px'}} item xs={7}>
								<Typography variant="h6" component="div" gutterBottom>Your Folders</Typography>
								<div style={{ height: 400, width: '100%' }}>
		    				      <DataGrid rows={folderlisting} columns={foldercolumns} pageSize={5} checkboxSelection  />
		    				    </div>
							</Grid>
						</Grid>
						<Dialog
					        className="trashDialogClass"
					        open={openchanneldeletedialog}
					        onClose={() => setChannelDeleteDialog(false)}
					        aria-labelledby="responsive-dialog-title">
					        <DialogTitle id="responsive-dialog-title">Delete Channel</DialogTitle>
					        <DialogContent>
					          <DialogContentText>
					          	This action will permanently delete all objects of this channel. Are you sure you want to continue?
					          </DialogContentText>
					        </DialogContent>
					        <DialogActions>
					          <Button style={{color: 'white', backgroundColor: 'green'}} onClick={() => setChannelDeleteDialog(false)}>Cancel</Button>
					          <Button style={{color: 'white', backgroundColor: 'red'}} onClick={() => deleteChannelAgree()} autoFocus>Confirm</Button>
					          
					        </DialogActions>
					    </Dialog>
					    <Dialog
					        className="trashDialogClass"
					        open={openfolderdeletedialog}
					        onClose={() => setFolderDeleteDialog(false)}
					        aria-labelledby="responsive-dialog-title">
					        <DialogTitle id="responsive-dialog-title">Delete Folder</DialogTitle>
					        <DialogContent>
					          <DialogContentText>
					          	This action will permanently delete all objects of this folder. Are you sure you want to continue?
					          </DialogContentText>
					        </DialogContent>
					        <DialogActions>
					          <Button style={{color: 'white', backgroundColor: 'green'}} onClick={() => setFolderDeleteDialog(false)}>Cancel</Button>
					          <Button style={{color: 'white', backgroundColor: 'red'}} onClick={() => deleteFolderAgree()} autoFocus>Confirm</Button>
					          
					        </DialogActions>
					    </Dialog>
						<Line options={options} data={dataa} style={{display: 'none'}} />
						
					</Box>
					
				</Box>
	    	</div>
		</div>
	</div>
    
  )
}

//export default Dashboard;
