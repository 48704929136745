
import React,{useEffect,useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TextField,Box,Button, Typography  } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery'; 
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import { Form } from "react-bootstrap";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Delete from '@material-ui/icons/Delete';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Share from '@material-ui/icons/Share';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Pagination from '@material-ui/lab/Pagination';
import Chip from '@material-ui/core/Chip';
import { SRLWrapper } from "simple-react-lightbox";
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Folder from './Folder.js';
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({ 
	 media: {
	    height: 0,
	    paddingTop: '56.25%', // 16:9
	  },
	  menuItem: {
	  	fontSize:'14px'
	  },
	  menuchannel: {
	  	fontSize: '14px',
	  		'&:hover': {
	  			backgroundColor: '#464775',
	  			color: "#FFF"
	  		}
	  },
	  cirProgress: {
	    height: '27px !important',
	    width: '27px !important'
	  }, 
	  moveMenuItem: {
	    fontSize:'13px', 
	    fontWeight: 'bold',
	    '&:hover':{
	      backgroundColor:'#464775',
	      color: '#FFF'
	    }
	  }
}))

 
function Unsplash({match}) {
	let history = useHistory();
	const classes = useStyles();
	const [myArray, setMyArray] = React.useState([1,2,3]);
	//setMyArray([...myArray, 4]);
	//console.log(myArray);
// to update

	const [imageResults,setImageResults] = React.useState([]);
	const [imageMoreResults,setImageMoreResults] = useState([]);
	const [imageTotalPages,setTotalPages] = useState(null);
	const [showProgress, setShowProgress] = React.useState(false);
	const [imageTotalRecords,setTotalRecords] = useState(null);
	const [keywordsList,setKeywordList] = useState([]);
	const [channellisting, setChannelList] = useState([]);
	const [photoid, setPhotoID] = useState([]);

	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const [state, setState] = useState({
		searchunsplash: "",
	});
	const Progress = () => (
      <span><div style={{float: 'left'}}>
      <CircularProgress className={classes.cirProgress} /></div></span>
    );
	const notify = () => toast.dark("Photo downloaded to your channel", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
	const ITEM_HEIGHT = 48;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		console.log(event.currentTarget.value);
		setAnchorEl(event.currentTarget);
		setPhotoID(event.currentTarget.value);
	};
	const handleCloses = () => {
		setAnchorEl(null);
	};
	const handleInputChange = (event) => {
		if(event.which=='13'){
			postData(1);
		}
		const { name, value } = event.target;
		setState(prevState => ({
			...prevState,
			[name]: value
		}));
	};
	const handleClose = (event) => {
		setShowProgress(true);
	  	const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, photoid: photoid, channelid: event.currentTarget.value })
        };
        fetch(APIURL+'application/unsplash/download', requestOptions)
        .then(response => response.json())
        .then(response => setAnchorEl(null))
        .then(response => {
        	notify();
        	setShowProgress(false);
        })

	  	
	};
	useEffect(() => {
    	const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
        };
        fetch(APIURL+'application/keyword/list', requestOptions)
        .then(response => response.json())
        .then(response => {
              setKeywordList(response.keywords)
        })
    }, []);

	function increaseeMyArrayNUmber(newResponseArray){
		//console.log(newResponseArray);
			//setImageResults([...imageResults,newResponseArray]);
	}

    
    
	useEffect(() => {
			 //document.addEventListener('scroll', console.log('yahoo'));
		//jQuery(function(jQuery) {
			//jQuery('#unsplashPhotoDiv').on('scroll', function() {
				
				//if(jQuery('#unsplashPhotoDiv').scrollTop() + jQuery('#unsplashPhotoDiv').innerHeight() >= jQuery('#unsplashPhotoDiv')[0].scrollHeight) {

					setMyArray([...myArray, 444]);
					//setMyArray([...myArray, 444]);
					//console.log(myArray);

					const nextpagenumber = eval(jQuery('#pagenumber').val())+1;

					//fetch('https://api.unsplash.com/search/photos?per_page=8&page='+nextpagenumber+'&query='+jQuery('#searchunsplash').val()+'&client_id=uAqVhzwELHdldEVcRYSKyjQ4GsbJ6ECQEf9PqCPejOY')
			        //.then(response => response.json())
			        //.then(response => {console.log(response.results)})
			        
					
			        jQuery('#pagenumber').val(nextpagenumber);
				//}
			//})
		//});
		
        // POST request using fetch inside useEffect React hook

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'channel/list', requestOptions)
        .then(response => response.json())
        .then(response => {
              setChannelList(response.channels)
              //this.state = { response };
        })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    function handleScroll(event){
    	//jQuery('#unsplashPhotoDiv').css('background','rgba(0,0,0,0.6)').css('position','absolute');
    	const target = event.target;
    	if(jQuery('#pagenumber').val()=='NaN'){
    		return;
    	}
    	jQuery('#unsplash_loading').show();
    	const nextpagenumber = eval(jQuery('#pagenumber').val())+1;

    	if(target.scrollHeight - target.scrollTop === eval(target.clientHeight)){

    		fetch('https://api.unsplash.com/search/photos?per_page=16&page='+nextpagenumber+'&query='+jQuery('#searchunsplash').val()+'&client_id=uAqVhzwELHdldEVcRYSKyjQ4GsbJ6ECQEf9PqCPejOY')
	        .then(response => response.json())
	        .then(response => {
	        	if(nextpagenumber==(response.total_pages+1)){
	        		jQuery('#unsplashPhotoDiv').append('<div class="cardParentUnsplash" id="endofresults"><div style="" class="MuiPaper-root MuiCard-root MuiPaper-elevation1 MuiPaper-rounded"><h1 style="line-height: 160px;text-align: center;">End of Results</h1></div></div>');
	        	}
				const finalArray = imageResults.concat(response.results);
				setImageResults(finalArray);
				jQuery('#pagenumber').val(nextpagenumber);
				jQuery('#unsplashPhotoDiv').css('opacity','1');
	        })

    	}
    }
	
	const randomKeyword = ['tiger','peak','strawberry','buildings','editor','new york','football','rugby','plants'
	,'domestic','astonishing','driver','organ','development','provoke','adventure','marine','mail','scan','liberty','abstract','teacher']
	
	useEffect(() => {
		fetch('https://api.unsplash.com/search/photos?per_page=16&page=1&query='+randomKeyword[Math.floor(Math.random() * 22)]+'&client_id=uAqVhzwELHdldEVcRYSKyjQ4GsbJ6ECQEf9PqCPejOY')
        .then(response => response.json())
        .then(response => {
        	setImageResults(response.results)
        	//setTotalPages(response.total_pages)
        	setTotalRecords(response.total)
        })
	}, []);

	const handleKeyword = (event) => {
		//console.log(event.target.innerText);
		jQuery('#searchunsplash').val(event.target.innerText);		
		//setState({searchunsplash: event.target.innerText});
		postData(1);
		//console.log(state.searchunsplash);
		//jQuery('#'+event.target.innerText).delay(1000).click();
	}

	function postData(page){
		//console.log(page);
		if(page=='undefined'){
			page = 1;
		}
		jQuery('#endofresults').remove();
		jQuery('#pagenumber').val(1);
		//event.preventDefault();
		fetch('https://api.unsplash.com/search/photos?per_page=16&page='+page+'&query='+jQuery('#searchunsplash').val()+'&client_id=uAqVhzwELHdldEVcRYSKyjQ4GsbJ6ECQEf9PqCPejOY')
        .then(response => response.json())
        .then(response => {
        	setImageResults(response.results)
        	setTotalPages(response.total_pages)
        	setTotalRecords(response.total)
        })
       // .then(response => console.log(response.total_pages))
        //.then(response => setTotalRecords(response.total))
	}


	const options = {
		buttons: {
			showThumbnailsButton: false,
			showNextButton: false,
      		showPrevButton: false,
      		showAutoplayButton: false,
      		showDownloadButton: false,
	    },
	    thumbnails: {
	    	showThumbnails: false,
	    },
	};

	return (
		<div>
			<Box>
				<ToastContainer />

				<Grid container style={{marginTop: '-20px'}}>
				
				<br />
					<Grid item style={{paddingTop:'3px'}}>
						<img src="/unsplash.png" width="30" />
					</Grid>
					<Grid item style={{marginLeft:'10px'}}>
						<Typography style={{fontSize: '14px', fontWeight:'bold'}}>Unsplash</Typography>
						<Typography style={{fontSize:'12px', marginTop: '-4px'}}>Photos for everyone</Typography>
					</Grid>
					<Grid item xs={10} style={{marginLeft:'10px'}}>
						
						<div className="searchBar">
							<div style={{float:'left', width:'94%'}}>
								<input id="searchunsplash" autocomplete="off" onKeyUp={handleInputChange} onChange={handleInputChange} placeholder="Search free high resolution photos" type="text" name="searchunsplash" className="searchField" />
								<input type="hidden" id="pagenumber"  />
							</div>	
							<div style={{float:'left', width: '5%', textAlign: 'center', paddingTop:'4px'}}>
								<SearchIcon onClick={postData} className="searchIcon" />
							</div>
						</div>
						
						
					</Grid>
					<Grid item xs={2} style={{marginTop:'7px'}} className="desktop-only" >
					{ showProgress ? <Progress /> : null }
					</Grid>
					<Grid item xs={10} style={{marginTop:'7px'}} >
						{keywordsList.map((val,key) => {
							return <Chip className="searchKeywords" id={val.keyword} onClick={handleKeyword} value={val.keyword} variant="outlined" color="primary" clickable label={val.keyword} />
						})}
					</Grid>
					
					<Grid item xs={12} id="unsplashPhotoDiv"  onScroll={handleScroll}  style={{maxHeight:window.innerHeight-160, overflow:'auto',  scrollbarWidth: 'thin', marginTop: '5px'}}>
						{imageTotalRecords==0 ? <Typography className="notphotofoundmsg">No photo found matching your search</Typography> 
						: imageResults.map((val,key) => (
							<div className="cardParentUnsplash">
						    <Card className="imageMainCard" >

						      <CardHeader
						      	className="cardHeaderUnsplash" 
						        avatar={
						          <Avatar aria-label="recipe" className="imgAvatar">
						           {val.alt_description ? val.alt_description.substr(0,1) : 'P' }
						          </Avatar>
						        }
						        action={
									<Tooltip title="Save to channel">
										<IconButton aria-label="more"
										aria-controls={"long-menu"}
										aria-haspopup="true"
										value={val.urls.full+'|'+val.alt_description+'|'+val.id+'|'+val.user.name}
										onClick={handleClick}>
											<img src="/downloadicon.png" value={val.id}    style={{fill:'#FFF',fontSize:'30px', width: '36px'}} />
											
										</IconButton>
									</Tooltip>
						        }
						        title={<Typography className="headerTitle"> {val.alt_description ? val.alt_description.substr(0,16) : 'Photo by '+val.user.name } </Typography> }
						        subheader={<Typography className="headerDate">{val.created_at.substr(0,10)}</Typography>}
						      />
						      <SRLWrapper  options={options} >
								<a href={val.urls.full}>
									<img className="unsplashImage" style={{width:'100%', height: '200px', objectFit: 'cover', borderRadius: '5px'}} src={val.urls.thumb} title= {val.alt_description ? val.alt_description : 'Photo by '+val.user.name } />
						      
						      	</a>
						       </SRLWrapper>
						      
						      
						    </Card>
						    </div>
							
						))}
						
						<Menu
					        id={"long-menu"}
					        anchorEl={anchorEl}
					        onClose={handleCloses}
					        onClick={handleCloses}
					        keepMounted
					        open={open}
					        PaperProps={{
					          style: {
					            maxHeight: ITEM_HEIGHT * 4.5
					            
					          },
					        }}
					      >
					      	{channellisting.map((val,key) => {
					      		return <div><MenuItem className={classes.moveMenuItem} value={val.id} onClick={handleClose}>
					           {val.name}
					          </MenuItem>
					          <Folder photoID={photoid} bucketID={val.id} />
					          </div>
					      	})}
					      	
					        
					      </Menu>
							<div align="center" id="unsplash_loading" style={{display:'none'}}><img src={process.env.REACT_APP_BASE_URL+"loading.gif"} /></div>
					</Grid>
					{/* {imageTotalPages>0 ? <Grid item xs={12} className="pagingGrid">
						<Pagination className="photoPaging" hideNextButton="true" hidePrevButton="true" count={imageTotalPages} defaultPage={1} onChange={postData} boundaryCount={2} />
					</Grid> : ''} */}
				</Grid>
			</Box>
		</div>
	);
}
export default Unsplash;
