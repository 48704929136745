
import React,{useContext,useState,useEffect} from 'react';
import { Box, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import {BrowserRouter as Router,NavLink,Route,useHistory} from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import Add from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import ExtensionIcon from '@material-ui/icons/Extension';
import WidgetsIcon from '@material-ui/icons/Widgets';
import FolderIcon from '@material-ui/icons/Folder';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const APIURL = process.env.REACT_APP_API_URL;
//import { useResizeDetector } from 'react-resize-detector';

const useStyles = makeStyles(theme => ({
    root:{
        color:'#FFFFFF !important',
        '& .MuiListItemIcon-root': {
            color:'#FFFFFF !important'
        },
        '& .MuiSvgIcon-root': {
            paddingTop: 1,
        }
        
    },
    arrow: {
      color:'#FFFFFF !important',  
      cursor:'pointer'
    },
    userInfo: {
        paddingTop: 24,
        transition: 'all 0.1s ease',
        height: 75,
        cursor: "pointer",
        clear: "both",
        opacity: 1,
        '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
          height: 0,
          paddingTop: 0,
          opacity: 0,
          transition: 'all 0.3s ease',
        },
  },
  listItemText: {
    color: '#FFFFFF',
    minWidth: '80px'
  },
  listitem: {
       color: '#FFFFFF !important',
       minWidth: '30px'
  },
  userTitle: {
    color: "#FFFFFF",
    marginBottom:1,
    fontFamily: 'Normal',
    cursor:'pointer',
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "#FFFFFF",
    fontFamily: 'Normal'
  },
  popUpOver: {
    left:"130px !important",
    fontFamily: 'Normal'
  },
  popUpOverChannel: {
    left:"10px !important",
    fontFamily: 'Normal'
  }, 
  addchannelplus: {
    width: '22px',
    height: '22px',
    color: '#FFFFFF'
  },
  li: {
    fontFamily: 'Normal'
  },
  channellink: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '1px'
  }, 
  deleteChannelMainn: {
    width: '100%',
    paddingRight: '0px',
    backgroundColor:'#FFFFFF1A',
    fontSize: '17px',
    fontWeight: 'bold'
    ,'&:hover':{
      backgroundColor:'#FFFFFF !important',
    },'& a':{
      color: '#FFF'
    },'& a:hover':{
      color: '#000'
    }
  },deleteChannelMain: {
    padding: '0px',
    
  },
  
  channeldeleteicon: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '1px'
  },
  channelicon: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '4px'
  },
  listItemText:{
    '& span': {
      fontWeight: 'bold',
      color: '#FFFFFF'
    }
  }, 
  moveMenuItem: {
    fontSize:'13px', 
    '&:hover':{
      backgroundColor:'#464775',
      color: '#FFF'
    }
  }
}));

function SubFolderComponent(bucketId) {
    //console.log(props.FolderID);
    //const { width, height, ref } = useResizeDetector();
    const styles = theme => ({
        MenuItem:{
        fontFamily:'Normal',//Insert your required size
        }
    });
    const notify = () => toast.dark("Photo downloaded to your channel", {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    let history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [channelMenu, setChannelMenu] = React.useState(null);
    const [bucketSlugForUrl, setBucketSlug] = useState();
    const [directorySlugForUrl, setDirectorySlug] = useState();
    const [subdirectorylisting, setSubDirectoryList] = useState([]);
    const userinfoItem  = localStorage.getItem('userinfo'); ;
    if(userinfoItem==null){
      history.push('/login');
    }
      const movenotify = () => toast.success("Item moved to another channel", {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
     const userinfo  = JSON.parse(userinfoItem);
    useEffect(() => {
      
         const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, directory: bucketId.FolderID  })
        };
        fetch(APIURL+'subdirectory/list', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setSubDirectoryList(response.subdirectories)
                  setDirectorySlug(response.directorySlug)
                  setBucketSlug(response.bucketSlug)
                  //this.state = { response };
            })
       
   },[])
const handleClose = (event) => {
  //console.log(event.currentTarget.dataset.id);
  //console.log(event.currentTarget.value);

      const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, photoid: bucketId.photoID, channelid: event.currentTarget.value })
        };
        fetch(APIURL+'application/unsplash/download', requestOptions)
        .then(response => response.json())
        .then(response => setAnchorEl(null))
        .then(response => notify())


    //setAnchorEl(null);
  };
    
    const classes = useStyles();
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: 'rgb(51,52,74,0.6)',
        color: '#FFF',
        maxWidth: 220,
        fontSize: 11,
        border: '1px solid #464775',
      },
    }))(Tooltip);

    
      return (
        <div >
        
        {subdirectorylisting.map((value) => (
        <div><MenuItem onClick={handleClose}  value={value.id} className={classes.moveMenuItem} style={{paddingTop:'4px', paddingBottom: '4px', paddingLeft:'40px'}}>
         <FolderIcon style={{ fontSize: 15 }}  />&nbsp;{value.name}
        </MenuItem></div>
        ))}
        </div>
  )

}

export default SubFolderComponent;
