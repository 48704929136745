import {React,useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
 
  singUpContainer: {
    height: '100%',
    textAlign: "center",
    width:'100%'
  },
  singUpLogo: {
    height: 66,

  },
  singUpHeading: {
    fontSize: 40,
    fontWeight: '900',
    color:'#723FBA'
  },
  singUpTagLine: {
    fontSize: 16,
    color: '#371B58'
  },
  textFieldRoot: {
      width: '100%'
    },
    continueButton: {
      backgroundColor: '#723FBA',
      width: '100%',
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 18,
      textTransform: 'none'
    },
    marginAutoItem: {
    margin: '0px auto',
   },
  image: {
    backgroundImage: 'url('+process.env.REACT_APP_BACKGROUNDS+')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  links: {
    color:'#371B58'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

 
function ForgotPassword () {

  useEffect(() => {
        const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
        if(userinfo){
          window.location.href = '/dashboard';
        }
    }, []);

  const classes = useStyles();
  const [state, setState] = useState({
    email: "",
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  

    async function postData(event){
          event.preventDefault();
          let response = await fetch(APIURL+'user/forgotpassword',{
            "method": "POST",
           "headers": {
              "Accept": "application/json",
              "Content-type": "application/json"
            },
            "body" : JSON.stringify({
              email: state.email
            })
          });

          const json = await response.json();
          if(json.http_code==='400'){
            jQuery('#alert').show();
            jQuery('#alert .MuiAlert-message').html(json.message);
          } else if(json.http_code==='200'){
            jQuery('#alert').hide();
            jQuery('#successalert').show();
            jQuery('#successalert .MuiAlert-message').html(json.message);
            localStorage.setItem('usertoken','fajsasd977DSD7s87sahj3');
            localStorage.setItem('userinfo',JSON.stringify(json.userinfo));
            localStorage.setItem('teaminfo',JSON.stringify(json.teaminfo));
            window.setTimeout(() => {
              //window.location.href = json.redirect;
              //history.push('/'+json.redirect);
            }, 3000);
          }
          

      }


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          
          <Box  alignItems="center" className={classes.marginAutoItem}>
            <div className={classes.singUpContainer}>
               <img className={classes.singUpLogo} src="/large.png" />
              <div>
                <h1 className={classes.singUpHeading}>Forgot your password?</h1>
              <h3 className={classes.singUpTagLine}><b>We'll email you a link to reset your password</b>.</h3>
              </div>
               <Form onSubmit={postData} className="register-form" method="POST">
              <div>
                <TextField name="email" onChange={handleInputChange} className={classes.textFieldRoot} id="outlined-basic" label="Email" variant="outlined" />
              </div>
              <br />
              <div>
              </div>
                <Button onClick={postData} variant="contained" className={classes.continueButton} >
                Submit
                </Button>
                <br />
              <Link
                id="linkclicking" 
                to={{
                  pathname: "/verify",
                  state
                }}
              >&nbsp;
              </Link>
              </Form>
              <Alert id="alert" severity="error" style={{display:"none"}} >
              
              </Alert>
              <Alert id="successalert" severity="success" style={{display:"none"}} >
              
              </Alert>
              <Box alignItems="center" justifyContent="space-between" mt={1}>
              <Link to="/login" className={classes.links}><b>Sign in</b></Link> to an existing team.
              </Box>
            </div>
            </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;