
import React,{useContext,useState,useEffect} from 'react';
import { Box, Typography } from '@material-ui/core';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import {BrowserRouter as Router,NavLink,Route,useHistory} from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import Add from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon  from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import jQuery from 'jquery';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import ExtensionIcon from '@material-ui/icons/Extension';
import WidgetsIcon from '@material-ui/icons/Widgets';
import FolderIcon from '@material-ui/icons/Folder';
import FolderComponent from './FolderComponent.js';
import { Alert } from '@material-ui/lab';
import AssetCount from './AssetCount.js';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
const APIURL = process.env.REACT_APP_API_URL;
//import { useResizeDetector } from 'react-resize-detector';

const useStyles = makeStyles(theme => ({
    root:{
        color:'#FFFFFF !important',
        '& .MuiListItemIcon-root': {
            color:'#FFFFFF !important'
        },
        '& .MuiSvgIcon-root': {
            paddingTop: 1,
        }
        
    },
    arrow: {
      color:'#FFFFFF !important',  
      cursor:'pointer'
    },
    userInfo: {
        paddingTop: 24,
        transition: 'all 0.1s ease',
        height: 75,
        cursor: "pointer",
        clear: "both",
        opacity: 1,
        '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
          height: 0,
          paddingTop: 0,
          opacity: 0,
          transition: 'all 0.3s ease',
        },
  },
  listItemText: {
    color: '#FFFFFF',
    minWidth: '80px'
  },
  listitem: {
       color: '#FFFFFF !important',
       minWidth: '30px'
  },
  userTitle: {
    color: "#FFFFFF",
    marginBottom:1,
    fontFamily: 'Normal',
    cursor:'pointer',
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "#FFFFFF",
    fontFamily: 'Normal'
  },
  popUpOver: {
    left:"20px !important",
    top:"20px !important",
    fontFamily: 'Normal'
  },
  popUpOverChannel: {
    left:"10px !important",
    fontFamily: 'Normal'
  }, 
  addchannelplus: {
    width: '22px',
    height: '22px',
    color: '#FFFFFF'
  },
  li: {
    fontFamily: 'Normal'
  },
  channellink: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '1px'
  }, 
  deleteChannelMainn: {
    width: '100%',
    paddingRight: '0px',
    backgroundColor:'#FFFFFF1A',
    fontSize: '17px',
    fontWeight: 'bold'
    ,'&:hover':{
      backgroundColor:'#FFFFFF !important',
    },'& a':{
      color: '#FFF'
    },'& a:hover':{
      color: '#000'
    }
  },deleteChannelMain: {
    padding: '0px',
    
  },
  
  channeldeleteicon: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '1px'
  },
  channelicon: {
    float: 'left',
    lineHeight: '22px',
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: '4px'
  },
  listItemText:{
    '& span': {
      fontWeight: 'bold',
      color: '#FFFFFF'
    }
  }
}));

function SideBar(props) {
    //const { width, height, ref } = useResizeDetector();
    const styles = theme => ({
        MenuItem:{
        fontFamily:'Normal',//Insert your required size
        }
    });
    let history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [channelMenu, setChannelMenu] = React.useState(null);
    const [userPermissions, setUserPermissions] = useState([]);
    const [teamMenu, setTeamMenu] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [team, setTeam] = useState('');
    //console.log(team);
    const handleAddChannel = (event) => {
        setChannelMenu(event.currentTarget);
    };

    const handleAddChannelClose = () => {
        setChannelMenu(null);
    };

    const handleTeamMenu = (event) => {
        setTeamMenu(event.currentTarget);
    };

    const handleTeamMenuClose = () => {
        setTeamMenu(null);
    };


    const [channelopen, setOpen] = React.useState(true);
    const [appopen, setAppOpen] = React.useState(true);

    const handleCollapse = () => {
        setOpen(!channelopen);
    };

    const handleAppCollapse = () => {
        setAppOpen(!appopen);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const classes = useStyles();

    const userinfoItem  = localStorage.getItem('userinfo'); ;
    const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
    //console.log('teaminfo',teaminfo);
    if(userinfoItem==null){
      history.push('/login');
    }
    const userinfo  = JSON.parse(userinfoItem);
    const mediaImportNotify= () => {
      jQuery('.medianotify').hide();
      const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
      fetch(APIURL+'application/disablemedianotify', requestOptions)
            .then(response => response.json())
    }
   
    const [teamName, setTeamName] = useState(null);
    const [teamId, setTeamId] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [channellisting, setChannelList] = useState([]);
    const [userAppsListing, setUserAppsList] = useState([]);
    const [mediaNotify, setMediaNotify] = useState(null);
    //console.log(channellisting);
    useEffect(() => {
      return history.listen((location) => { 
         const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo, email: userinfo[0].email  })
        };
        fetch(APIURL+'user/logincheck', requestOptions)
            .then(response => response.json())
            .then(response => {
              if(response.http_code=='400'){
                history.push('/logout');
              } else if(response.http_code=='200'){
                if(response.userhashtoken=='XXXX-LOGGEDOUT-XXXX'){
                  history.push('/logout');
                }
              }
            })
        fetch(APIURL+'application/amazonmedianotify', requestOptions)
            .then(response => response.json())
            .then(response => {
              if(response.http_code=='400'){
                setMediaNotify(null);
              } else if(response.http_code=='200'){
                setMediaNotify('yes');
              }
            })
      }) 
   },[history])

    useEffect(() => {
        if(teaminfo){
          // POST request using fetch inside useEffect React hook
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
              body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
          };
          fetch(APIURL+'user/permissions', requestOptions)
          .then(response => response.json())
          .then(response => {
                console.log('teaminfo', teaminfo)
                setUserPermissions(response.permission)
                //this.state = { response };
          })
        }
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    useEffect(() => {
      return history.listen((location) => { 
         const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'channel/list', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setChannelList(response.channels)
                  //this.state = { response };
            })
      }) 
    },[history])

   useEffect(() => {
     return history.listen((location) => { 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                if(response.error_code=='404'){
                  localStorage.clear();
                  window.location.href = '/login';
                }
                  setUserAppsList(response.userapps)
                  //this.state = { response };
            })
      }) 
   },[history])
   
   useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'application/userapplist', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setUserAppsList(response.userapps)
                  //this.state = { response };
            })

            fetch(APIURL+'application/amazonmedianotify', requestOptions)
            .then(response => response.json())
            .then(response => {
              if(response.http_code=='400'){
                setMediaNotify(null);
              } else if(response.http_code=='200'){
                setMediaNotify('yes');
              }
            })
   },[])


    useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  if(response.error_code=='404'){
                    localStorage.clear();
                    window.location.href = '/login';
                  }
                  setTeamName(response.teamname)
                  setTeamId(response.teamid)
                  setUserRole(response.userrole)
                })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);
    const [defaultChannel, setDefaultChannel] = useState('');
    const changeTeam = (teamId) => {
        console.log('teamId',teamId);
       //localStorage.setItem('teaminfo','');
       localStorage.setItem('teaminfo',JSON.stringify(teamId));
       console.log(localStorage.getItem('teaminfo'));
       window.location.href = '/dashboard';
    }
    /*
    const handleChange = (event) => {
      setTeam(event.target.value);
     // console.log(event.target.value);
      localStorage.setItem('teaminfo',JSON.stringify(event.target.value));
      //console.log(JSON.parse(localStorage.getItem('teaminfo')));
        const trequestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ teamid: event.target.value  })
        };
        fetch(APIURL+'team/defaultchannel', trequestOptions)
          .then(response => response.json())
          .then(response => {
            setDefaultChannel(response.defaultchannel);
        })
      //localStorage.setItem('teaminfo',JSON.stringify(json.teaminfo));
    };*/
    const [userTeams, setUserTeams] = useState([]);
    useEffect(() => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
        body: JSON.stringify({ userid: userinfo[0].id  })
      };
      fetch(APIURL+'team/listing', requestOptions)
        .then(response => response.json())
        .then(response => {
          setUserTeams(response.teamlist);
      })
    }, []);

    useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'channel/list', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setChannelList(response.channels)
            })
    }, []);
    
    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: 'rgb(0,0,0,0.9)',
        color: '#FFF',
        maxWidth: 220,
        fontSize: 11,
        border: '1px solid #464775',
      },
    }))(Tooltip);

    //const teamnameDivPriv = <div><div style={{float: 'left'}}><PersonIcon /></div> <div className="teamnamepriv" onClick={handleTeamMenu} >{teamName}</div><div style={{float: 'left'}}><ArrowDropDownIcon /></div></div>
    //const teamnameDivNoPriv = <div><div style={{float: 'left'}}><PersonIcon /></div><div className="teamnamepriv" onClick={handleTeamMenu}>{teamName}</div><div style={{float: 'left'}}></div></div>
    const channelDiv = <div><List disablePadding>
                    <ListItem className={classes.listItemHead} style={{backgroundColor: '#3F8AFF', height: '40px'}}>
                        <ListItemIcon className={classes.listitem} >
                            {channelopen ? <ArrowDropDownIcon className={classes.arrow} button onClick={handleCollapse} /> : <ArrowRightIcon className={classes.arrow} button onClick={handleCollapse} />}
                        </ListItemIcon>
                        
                        <ListItemText  className={classes.listItemText} primary="Channels" />
                         {userPermissions.create_channel==true ? <span><Add style={{width: '22px', cursor:'pointer', backgroundColor: '#2A61B1', height:'22px', borderRadius: '3px'}} onClick={handleAddChannel} className={classes.addchannelplus} />
                         <Menu
                          id="simple-menu"
                          className={classes.popUpOverChannel}
                          anchorEl={channelMenu}
                          keepMounted
                          open={Boolean(channelMenu)}
                          onClose={handleAddChannelClose}
                          onClick={handleAddChannelClose}
                          >
                          <NavLink to="/add-channel"><MenuItem><AlternateEmail /> &nbsp; Add Channel</MenuItem></NavLink>
                          <NavLink to="/add-folder"><MenuItem><FolderIcon /> &nbsp; Add Folder</MenuItem></NavLink>
                        </Menu></span> : ''}
                    </ListItem>
                    <Collapse className="channelList " in={channelopen} timeout="auto"  unmountOnExit>
                        <List style={{marginLeft: '-11px' }} component="div" disablePadding>
                            {channellisting.map((value) => (
                              <div><ListItem button className={classes.nested} style={{paddingTop:'4px', paddingBottom: '4px'}}>
                                  <HtmlTooltip title={
                                            <React.Fragment>
                                            {value.name}
                                            </React.Fragment>
                                          } placement="top">
                                      <NavLink to={"/channel/"+value.slug} className="channelName" activeClassName="channel-active" >
                                        <div className={classes.channelicon}><AlternateEmail style={{ fontSize: 15 }}  /></div>
                                        <div className={classes.channellink}>{value.name}</div>
                                      </NavLink>
                                  </HtmlTooltip>
                              </ListItem>
                              </div>
                            ))}
                            {userRole=='2' ? <ListItem button className={classes.deleteChannelMain} disablePadding>
                                <NavLink to="/trash" className="deleteChannel" activeClassName="trash-channel-active" >
                                <div className={classes.channeldeleteicon}><Delete style={{ fontSize: 20 }}  /></div>
                                    <HtmlTooltip title={
                                          <React.Fragment>
                                          Trash Items
                                          </React.Fragment>
                                        } placement="top">
                                    <div className={classes.channellink}>Trash Items</div>
                                  </HtmlTooltip>

                                </NavLink>
                            </ListItem> : '' }
                              
                            
                        </List>
                    </Collapse>
                </List></div>
    const appsDiv = <div><List disablePadding >
                      <ListItem style={{backgroundColor: '#3F8AFF', height: '40px'}}>
                          <ListItemIcon className={classes.listitem} >
                              {appopen ? <ArrowDropDownIcon className={classes.arrow} button onClick={handleAppCollapse} /> : <ArrowRightIcon className={classes.arrow} button onClick={handleAppCollapse} />}
                          </ListItemIcon>
                          <ListItemText className={classes.listItemText} primary="Apps" />
                          {userRole=='2' ? <div><NavLink  to="/apps" className={classes.addchannel} >
                          <Add style={{width: '22px', backgroundColor: '#2A61B1', height:'22px', borderRadius: '3px'}} className={classes.addchannelplus} /></NavLink></div> : '' }
                      </ListItem>
                      <Collapse className="channelList " in={appopen} timeout="auto"  unmountOnExit>
                        <List style={{marginLeft: '-11px'}} component="div" disablePadding>
                           {userAppsListing.map((val,key) => {
                            const appSLug = val.slug;
                            const apppermission = userPermissions[appSLug];
                            return <ListItem button className={classes.nested}>
                                {userRole=='2' || apppermission==true ? 
                                <NavLink to={"/app/"+val.appid} className="channelName"  activeClassName="channel-active" >
                                <div className={classes.channelicon}><WidgetsIcon style={{ fontSize: 22 }}  /></div>
                                    <HtmlTooltip title={
                                          <React.Fragment>
                                          {val.name}
                                          </React.Fragment>
                                        } placement="top">
                                    <div className={classes.channellink} style={{paddingTop:'3px'}}>{val.name}</div>
                                  </HtmlTooltip>

                                </NavLink>
                               : null}
                            </ListItem>
                            })}
                            
                        </List>
                    </Collapse>
                    </List></div>
    return (
        
        
    <div  className={classes.root} className="sideBarWrapper" style={{maxHeight: window.innerHeight}}>
        <div className="sideBarContent" >
        	<div className="userProfileSection">
                <Box>
                    <Typography  className="userName"  onClick={handleClick} className={classes.userTitle} component="h3" variant="h6">
                    {teamName ? <div><div style={{float: 'left'}}><PersonIcon /></div> <div className="teamnamepriv" onClick={handleTeamMenu} >{teamName}</div><div style={{float: 'left'}}><ArrowDropDownIcon /></div></div> : ''}
                    </Typography>
                    

                          
                </Box>
        		
                    <Menu
                    id="simple-menu"
                    className={classes.popUpOver}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    onClick={handleClose}
                    >
                    {userTeams.map((val,key) => {
                    if(val.teamid==teaminfo){
                  return <div><MenuItem  disabled={true} style={{backgroundColor: '#000', color: '#FFF'}} ><GroupIcon />  &nbsp;  {val.name}</MenuItem></div>

                    } else {
                  return <div><MenuItem onClick={ () => changeTeam(val.teamid)}><GroupIcon />  &nbsp;  {val.name}</MenuItem></div>

                    }
                    })}
                    <NavLink to="/create-new-team"><MenuItem><GroupAddIcon /> &nbsp; Add new team</MenuItem></NavLink>
                     
                    {userRole=='2' ? <div><hr /><NavLink to="/team-settings"><MenuItem><SettingsIcon /> &nbsp; Settings</MenuItem></NavLink>
                    <NavLink to="/team-members"><MenuItem><PersonIcon  /> &nbsp; Members</MenuItem></NavLink></div> : '' }
                  </Menu>
                <div className="clearBoth"> </div>
        	</div>
          
        	<div className="channelListingWrapper" style={{height: window.innerHeight-50}}>
                
            {teamName ? channelDiv : '' }
            {teamName ? appsDiv : '' }
            
        	</div>
          {mediaNotify ? <Alert onClose={mediaImportNotify} severity="success" className="medianotify" >
            Your bucket media imported successfully.
          </Alert> : '' }
        </div>
    </div>
  )

}

export default SideBar;
