import React,{useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import { Form } from "react-bootstrap";
const APIURL = process.env.REACT_APP_API_URL;



const AcceptInvite = props => {
    //const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
    //console.log(props.location.state);
    const [emailaddress ,setEmailAddress] = useState();
    const [password ,setPassword] = useState();
    useEffect(() => {
        

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
          body: JSON.stringify({ token: props.match.params.hash })
        };
        fetch(APIURL+'user/emailbytoken', requestOptions)
          .then(response => response.json())
          .then(response => {
            if(response.email=='notfound'){
              window.location = process.env.REACT_APP_BASE_URL
            }
            setEmailAddress(response.email);
            setPassword(response.password);
            console.log(response.password);
            //return;
            if(response.password!=null ){
                const requestArg = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
                  body: JSON.stringify({ email: response.email })
                };
                fetch(APIURL+'user/acceptinvite', requestArg)
                  .then(uresponse => uresponse.json())
                  .then(uresponse => {
                    if(uresponse.http_code=='200'){
                      const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
                      if(userinfo){
                        window.location.href = process.env.REACT_APP_BASE_URL+'dashboard';
                      } else {
                        window.location = process.env.REACT_APP_BASE_URL+'login'
                      }
                      //console.log(uresponse.message);
                      
                    }
                    //setEmailAddress(response.email);
                    
                }) 
            }
            
        })

    }, []);

  

    
    ///console.log('emailaddress: '+emailaddress);

  return (
    <Grid>
    </Grid>
  );
}

export default AcceptInvite;