
import React,{useState,useEffect} from 'react';
import TopNav from './../Common/TopBar/TopNav.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Link} from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import { TextField,Box,Button, Typography,Checkbox  } from '@material-ui/core';
import ChannelsDropDown from './ChannelsDropDown.js';
const APIURL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(theme => ({
	root: {
    '& .MuiTypography-root':{
      fontFamily:'Normal !important'
    },
		'& .MuiTableContainer-root': {
			width: '95%'
		},
		'& .MuiTableCell-head': {
			fontWeight: '600'
		}, '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#464775'
    }
	},
	table: {
    minWidth: 650,
    marginRight: 10 
  },
   button: {
    backgroundColor: '#394E6D',
    color: '#FFFFFF'
  }, 
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    fontFamily: 'Normal',
  },
}))



function AddMember() {
  let history = useHistory();
	const classes = useStyles();
	const [state, setState] = useState({
    email: "",
    channels: "",
    create_channel: "",
  });

  const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
  const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
  const [teamName, setTeamName] = useState(null);
  const [selected,getSelected] = useState([]);
  const [channellisting, setChannelList] = useState([]);
  const [teamId, setTeamId] = useState(null);
  useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'team/details', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setTeamName(response.teamname)
                  setTeamId(response.teamid)
              })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    useEffect(() => {

        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo  })
        };
        fetch(APIURL+'channel/list', requestOptions)
            .then(response => response.json())
            .then(response => {
                  setChannelList(response.channels)
                  //this.state = { response };
            })
            //console.log(data.id);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);


  async function postData(event){
    event.preventDefault();
    let response = await fetch(APIURL+'user/addmember',{
      "method": "POST",
      "headers": {
                "Accept": "application/json",
                "Content-type": "application/json"
                },
      "body" : JSON.stringify({
        email: state.email,
        permission: jQuery('#permissions').val(),
        teamid: teaminfo,
        channels: jQuery('#channelsids').val()
      })
    });
    const json = await response.json();
    if(json.http_code=='400'){
      jQuery('#alert').show();
      jQuery('#successalert').hide();
      jQuery('#alert .MuiAlert-message').html(json.message);
    } else {
      jQuery('#alert').hide();
      jQuery('#successalert').show();
      jQuery('#successalert .MuiAlert-message').html(json.message);
    }
  }

  
const [checked, setChecked] = React.useState(false);
const selectAllChannels = (event) => {
  if(event.target.checked){
    jQuery('input[name="channels"]').prop('checked',true);
    jQuery.each(jQuery('input[name="channels"]'), function(){
       jQuery('#channelsids').val(jQuery('#channelsids').val()+","+jQuery(this).val());
    });
  } else {
    jQuery('#channelsids').val("");
    jQuery('input[name="channels"]').prop('checked',false);
  }
}
const selectChannel = (event) => {
  if(event.target.checked){
      jQuery('#channelsids').val(jQuery('#channelsids').val()+","+event.target.value);
    } else {
      const updatedval = jQuery('#channelsids').val().replace(","+event.target.value,'');
      jQuery('#channelsids').val(updatedval);
    }
}
const selectPermission = (event) => {
  if(event.target.checked){
      jQuery('#permissions').val(jQuery('#permissions').val()+","+event.target.value);
    } else {
      const permissionupdate = jQuery('#permissions').val().replace(","+event.target.value,'');
      jQuery('#permissions').val(permissionupdate);
    }
}
 const handleInputChange = (event) => {
    /*if(event.keyCode=='13'){
      postData(event);
    }*/
    const { name, value } = event.target;
    //if(event.target.checked){
    //  setChannelIds(value);  
   // }
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
  	<div>
  	<div className="mainWrapper">
			<TopNav />
			<Box  className={classes.root} pl={4} pr={4}  pt={4}  pb={3} >
				<Box >
					<Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
						<Link color="inherit" href="/" >
						{teamName}
						</Link>
						<Typography color="textPrimary">
						Invite Members
						</Typography>
					</Breadcrumbs>
				</Box>
        <Box>
        <Grid container spacing={0}>
          <Grid style={{border:'1px solid red;'}} item xs={6}>
            <Box>
              <Typography className={'invitememberheading'} variant="h4" gutterBottom>
              Invite Member
              </Typography>
            </Box>
          </Grid>
          
        </Grid>
        </Box>
    	   <Box>
          <form noValidate autoComplete="off">
          <div>
          <TextField onKeyUp={handleInputChange} onChange={handleInputChange} name="email" className={'invitememberfield'}  id="outlined-multiline-static" label="Email" rows={4} fullWidth defaultValue="" variant="outlined"/>
          <input type="hidden"  id="channelsids" />
          <input type="hidden" size="100"  id="permissions" />
          </div>
          <br />  
          <div style={{clear:'both'}}>&nbsp;</div>
            <Box>
              <Typography variant="h6" gutterBottom>
              Grant Channels
              </Typography>
            </Box> 
          <div>
            <Grid container spacing={0}>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">All Channels
              <input type="checkbox" onChange={selectAllChannels} />
              <span className="checkmark"></span>
            </label></div>
            </Grid>
            
            
            
            {channellisting.map((val,key) => {
              return <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">{val.name}
              <input type="checkbox" name="channels" value={val.id} onChange={selectChannel}  />
              <span className="checkmark"></span>
              </label></div>
            </Grid>

            })}
            </Grid>
          

          
          
          <div style={{clear:'both'}}>&nbsp;</div>
            <Box>
              <Typography variant="h6" gutterBottom>
              Grant Permissions
              </Typography>
            </Box>
          <Grid container spacing={0}>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">View Dashboard
              <input type="checkbox" onChange={selectPermission} value="view_dashboard" id="view_dashboard" name="view_dashboard" />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Create Channel
              <input type="checkbox" onChange={selectPermission} value="create_channel" id="create_channel" name="create_channel" />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Delete Channel
              <input type="checkbox" onChange={selectPermission} value="delete_channel" />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Edit Media
              <input type="checkbox" onChange={selectPermission} value="edit_media" />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Delete Media
              <input type="checkbox" onChange={selectPermission} value="delete_media" />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Share Media
              <input type="checkbox" onChange={selectPermission} value="share_media" />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Download Media
              <input type="checkbox" onChange={selectPermission} value="download_media" />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}   lg={2} >
              <div ><label className="container">Upload Media
              <input type="checkbox"  onChange={selectPermission} value="upload_media"  />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Move Media
              <input type="checkbox" onChange={selectPermission} value="move_media"  />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Google Vision
              <input type="checkbox" onChange={selectPermission} value="google_vision"  />
              <span className="checkmark"></span>
              </label></div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}  lg={2} >
              <div ><label className="container">Google Vision
              <input type="checkbox" onChange={selectPermission} value="google_vision"  />
              <span className="checkmark"></span>
              </label></div>
            </Grid>

          </Grid>
          <div style={{clear:'both'}}>&nbsp;</div>
            <Box>
              <Typography variant="h6" gutterBottom>
              Grant Apps
              </Typography>
            </Box>
            <Grid container spacing={0}>
              <Grid item xs={6} sm={3} md={3}  lg={2} >
                <div ><label className="container">Unsplash
                <input type="checkbox" onChange={selectPermission} value="unsplash_app"  />
                <span className="checkmark"></span>
                </label></div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}  lg={2} >
                <div ><label className="container">Amazon S3
                <input type="checkbox" onChange={selectPermission} value="amazon_app"  />
                <span className="checkmark"></span>
                </label></div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}  lg={2} >
                <div ><label className="container">FEED
                <input type="checkbox" onChange={selectPermission} value="feed_app"  />
                <span className="checkmark"></span>
                </label></div>
              </Grid>

            </Grid>
          <div style={{display: 'none'}}>
            <div style={{float: 'left', marginRight: '8px'}}><label className="container">Create Channel
              <input type="checkbox" onChange={selectPermission} value="create_channel" id="create_channel" name="create_channel" />
              <span className="checkmark"></span>
            </label></div>

            <div style={{float: 'left', marginRight: '8px'}}><label className="container">Delete Channel
              <input type="checkbox" onChange={selectPermission} value="delete_channel" />
              <span className="checkmark"></span>
            </label></div>

            <div style={{float: 'left', marginRight: '8px'}}>

            <label className="container">Edit Media
              <input type="checkbox" onChange={selectPermission} value="edit_media" />
              <span className="checkmark"></span>
            </label></div>

            <div style={{float: 'left', marginRight: '8px'}}>

            <label className="container">Delete Media
              <input type="checkbox" onChange={selectPermission} value="delete_media" />
              <span className="checkmark"></span>
            </label></div>
            <div style={{clear:'both'}}>&nbsp;</div>
            <div style={{float: 'left', marginRight: '8px'}}>

            <label className="container">Share Media
              <input type="checkbox" onChange={selectPermission} value="share_media" />
              <span className="checkmark"></span>
            </label></div>

            <div style={{float: 'left', marginRight: '8px'}}>

            <label className="container">Download Media
              <input type="checkbox" onChange={selectPermission} value="download_media" />
              <span className="checkmark"></span>
            </label></div>

            <div style={{float: 'left', marginRight: '8px'}}>

            <label className="container">Upload Media
              <input type="checkbox"  onChange={selectPermission} value="upload_media"  />
              <span className="checkmark"></span>
            </label></div>

            <div style={{float: 'left', marginRight: '8px'}}>

            <label className="container">Move Media
              <input type="checkbox" onChange={selectPermission} value="move_media"  />
              <span className="checkmark"></span>
            </label></div>

            <div style={{float: 'left', marginRight: '8px'}}>

            <label className="container">Google Vision
              <input type="checkbox" onChange={selectPermission} value="google_vision"  />
              <span className="checkmark"></span>
            </label></div>

            
          </div>
          <div style={{clear:'both'}}>&nbsp;</div>
          <Button
           onClick={postData}
          variant="contained"
          color="secondary"
          size="large"
          className={classes.button}
          startIcon={<SendIcon />}
          >
          Invite
          </Button>
          </div>
          </form>
          <br />
        <Alert id="alert" severity="error" style={{display:"none",'width':'420px'}} >

        </Alert>
        <Alert id="successalert" severity="success" style={{display:"none",'width':'420px'}} >

        </Alert>
         </Box>			
	   </Box>
		</div>
	</div>
  	
  );
}

export default AddMember;
