
import React,{useEffect,useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import { TextField,Box,Button, Typography, Checkbox, FormControlLabel, Radio, RadioGroup   } from '@material-ui/core';
import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppService from '../../../services/AppService.js';
import { Alert } from '@material-ui/lab';
import jQuery from 'jquery'; 
import axios from "axios";
import qs from 'qs';
import CircularProgress from '@material-ui/core/CircularProgress';

const APIURL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(theme => ({ 
	saveButton: {
		backgroundColor: '#33344A',
		width: '200px',
		color: '#FFFFFF',
	}
	
}))

 
function PIM({match}) {
 	const Progress = () => (     <div><div>        <CircularProgress />      </div> </div>   );
  	const [showProgress, setShowProgress] = useState(false);
  	const [showFilterProgress, setShowFilterProgress] = useState(false);
	const userinfo  = JSON.parse(localStorage.getItem('userinfo'));
	const teaminfo  = JSON.parse(localStorage.getItem('teaminfo'));
	const [productOwners, setPIMProdOwner] = useState([]);
	const [productTypes, setPIMProdType] = useState([]);
	const [productStatus, setPIMProdStatus] = useState([]);
	const [productGroups, setPIMProdGroup] = useState([]);

	const [checkedItemsOwner, setCheckedItemsOwner] = useState([]);
	const [checkedItemsStatus, setCheckedItemsStatus] = useState([]);
	const [checkedItemsType, setCheckedItemsType] = useState([]);
	const [checkedItemsGroup, setCheckedItemsGroup] = useState([]);
	
	
	const classes = useStyles();
	const [pimcredentials, setPIMCred] = useState({
	    api_username: "",
	    api_password: "",
	    api_url: ""
	    
	  });

	const [isOwnerChecked, setIsOwnerChecked] = useState(false);
	const [isAllProductChecked, setIsAllProductChecked] = useState(false);
	const [isStatusChecked, setIsStatusChecked] = useState(false);
	const [isTypeChecked, setIsTypeChecked] = useState(false);
	const [isGroupChecked, setIsGroupChecked] = useState(false);
	const [pimAccessToken, setPimAccessToken] = useState(null);
	const [filterAvailable, setFilterAvailable] = useState(false);
	const [allProductFilter, setAllProductFilter] = useState(0);
	//console.log("isStatusChecked", isOwnerChecked, isStatusChecked, checkedItemsType, checkedItemsGroup);
	

	const notify = () => toast.dark("FEED credentials saved!", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const pimcrederror = () => toast.error("FEED credentials are not valid!", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const pimvalidsuccess = () => toast.success("FEED credentials validated successfully!", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	const pimfilterssaved = () => toast.success("FEED filters saved successfully!", {
		position: "bottom-left",
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});


	const handleInputChange = (event) => {
		//console.log(event.keyCode);
		if(event.keyCode=='13'){
	      //postData(event);
	    }
		const { name, value } = event.target;
	    setPIMCred(prevState => ({
	      ...prevState,
	      [name]: value
	    }));
	    
	}

	const handleButtonClick = () => {
	    
		setShowFilterProgress(true);
		jQuery('#successalert').hide();
		const ownerValues = checkedItemsOwner; 
		const statusValues = checkedItemsStatus; 
		const typeValues = checkedItemsType; 
		const groupValues = checkedItemsGroup; 
		//console.log(groupValues);
	    const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ allproduct:allProductFilter, userid: userinfo[0].id, teamid: teaminfo, owner: ownerValues, status: statusValues , type: typeValues, group: groupValues  })
        };

        fetch(APIURL+'application/savepimfilters', requestOptions)
        .then(response => response.json())
        .then(response => {
        	//console.log(response);
        	pimfilterssaved();
        	setShowFilterProgress(false);
        	jQuery('#successalert').show();
      		jQuery('#successalert .MuiAlert-message').html(response.response);
        	
        })

	  };

	

	const handleFilterChange = (event) => {
		//console.log(event.target.value);
		if(event.target.value=='allproducts' && event.target.checked===true){
			console.log("setAllProductFilterTTT");
			setAllProductFilter(1);
			setIsAllProductChecked(true);
			setIsOwnerChecked(false)
			setIsStatusChecked(false)
			setIsTypeChecked(false)
			setIsGroupChecked(false)
			setCheckedItemsOwner([]);
			setCheckedItemsStatus([]);
			setCheckedItemsType([]);
			setCheckedItemsGroup([]);
		} else {
			setIsAllProductChecked(false);
			console.log("setAllProductFilterFFFFFFF");
			setAllProductFilter(0);
		}
		
		console.log("aaaaaaaaaa", event.target.value);
		if(event.target.value=='owner'){
			setCheckedItemsOwner([]);
			setIsOwnerChecked(event.target.checked)
		}
		if(event.target.value=='status'){
			setCheckedItemsStatus([]);
			setIsStatusChecked(event.target.checked)
		}
		if(event.target.value=='type'){
			setCheckedItemsType([]);
			setIsTypeChecked(event.target.checked)
		}
		if(event.target.value=='group'){
			setCheckedItemsGroup([]);
			setIsGroupChecked(event.target.checked)
		}

		
	};
	 useEffect(() => {
    	// POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'application/getpimcredentials', requestOptions)
        .then(response => response.json())
        .then(response => {
        	setPIMCred(response.creddetails[0])
        	if(response.creddetails[0].valid==1){
        		setFilterAvailable(true);
        	} 
        	//console.log(response.creddetails[0].valid);
        })

        const requestOptionsF = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
        };
        fetch(APIURL+'application/getpimfilters', requestOptionsF)
        .then(response => response.json())
        .then(response => {
        	//console.log(JSON.parse(response[0].owner));
        	setCheckedItemsOwner(JSON.parse(response[0].owner));
        	setCheckedItemsStatus(JSON.parse(response[0].status));
        	setCheckedItemsType(JSON.parse(response[0].type));
        	setCheckedItemsGroup(JSON.parse(response[0].group));
        	{response[0].all_products=='1' ? setIsAllProductChecked(true) : setIsAllProductChecked(false) }
        	{JSON.parse(response[0].owner).length==0 ? setIsOwnerChecked(false) : setIsOwnerChecked(true);   }
        	{JSON.parse(response[0].group).length==0 ? setIsGroupChecked(false) : setIsGroupChecked(true);  }
        	{JSON.parse(response[0].status).length==0 ? setIsStatusChecked(false) : setIsStatusChecked(true);}
        	{JSON.parse(response[0].type).length==0 ? setIsTypeChecked(false) : setIsTypeChecked(true); }
        	
        
        })


		const requestOptionss = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
		};
		fetch(APIURL+'application/pimproductowners', requestOptionss)
		.then(response => response.json())
		.then(response => {
			setPIMProdOwner(response.productOwners)
			console.log(response.productOwners);
		})

		
		fetch(APIURL+'application/pimproducttypes', requestOptionss)
		.then(response => response.json())
		.then(response => {
			setPIMProdType(response.productTypes)
			//console.log(response.productOwners);
		})

		fetch(APIURL+'application/pimproductstatus', requestOptionss)
		.then(response => response.json())
		.then(response => {
			setPIMProdStatus(response.productStatus)
			//console.log(response.productOwners);
		})

		fetch(APIURL+'application/pimproductgroups', requestOptionss)
		.then(response => response.json())
		.then(response => {
			setPIMProdGroup(response.productGroups)
			//console.log(response.productOwners);
		})

    }, []);

	 const validatePIMCredentail = () => {
	 	const requestOpt = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
            body: JSON.stringify({  teamid: teaminfo, pimuser: pimcredentials.api_username, pimpassword: pimcredentials.api_password, pimurl: pimcredentials.api_url })
        };
	 	fetch(APIURL+'application/validatepimcred', requestOpt)
		.then(response => response.json())
		.then(response => {
			if(response.code=='200'){
				setPimAccessToken(response.access_token);
				setFilterAvailable(true);
				pimvalidsuccess();
				console.log('11111111');
				const requestOptionss = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json',"Accept": "application/json" },
		            body: JSON.stringify({ userid: userinfo[0].id, teamid: teaminfo })
				};
				fetch(APIURL+'application/pimproductowners', requestOptionss)
				.then(response => response.json())
				.then(response => {
					setPIMProdOwner(response.productOwners)
					console.log('2222', response.productOwners);
				})

				fetch(APIURL+'application/pimproducttypes', requestOptionss)
				.then(response => response.json())
				.then(response => {
					setPIMProdType(response.productTypes)
					//console.log(response.productOwners);
				})

				fetch(APIURL+'application/pimproductstatus', requestOptionss)
				.then(response => response.json())
				.then(response => {
					setPIMProdStatus(response.productStatus)
					//console.log(response.productOwners);
				})

				fetch(APIURL+'application/pimproductgroups', requestOptionss)
				.then(response => response.json())
				.then(response => {
					setPIMProdGroup(response.productGroups)
					//console.log(response.productOwners);
				})

		
			} else {
				setFilterAvailable(false);
				pimcrederror();
			}
			
		})

	 }
	 
	 function inArray(needle, haystack) {
	    if(haystack!==null){
		    var length = haystack.length;
		    for(var i = 0; i < length; i++) {
		        if(haystack[i] == needle){
		        	return true;
		        }
		    }
		}
	    //return false;
	}

	async function postData(event){
      event.preventDefault();
      setShowProgress(true);
      let response = await fetch(APIURL+'application/savepimcredentials',{
       "method": "POST",
       "headers": {
          "Accept": "application/json",
          "Content-type": "application/json"
        },
        "body" : JSON.stringify({
          userid: userinfo[0].id,
          teamid: teaminfo,
          apiusername: pimcredentials.api_username,
          apipassword: pimcredentials.api_password,
          apiurl: pimcredentials.api_url,
        })
      });

      const json = await response.json();
      setShowProgress(false);
      notify();
      validatePIMCredentail();
    }

	const handleCheckboxChangeOwner = (event, id) => {
		
			if (event.target.checked) {
				if(id==0){
					setCheckedItemsOwner([0]);
				} else {
					setCheckedItemsOwner([...checkedItemsOwner, id]);
				}
			} else {
				setCheckedItemsOwner(checkedItemsOwner.filter((item) => item !== id));
			}	
		
		
	};
	const handleCheckboxChangeStatus = (event, id) => {
		if (event.target.checked) {
			if(id==0){
				setCheckedItemsStatus([0]);
			} else {
				setCheckedItemsStatus([...checkedItemsStatus, id]);
			}
			
		} else {
			setCheckedItemsStatus(checkedItemsStatus.filter((item) => item !== id));
		}
	};
	const handleCheckboxChangeType = (event, id) => {
		if (event.target.checked) {
			if(id==0){
				setCheckedItemsType([0]);
			} else {
				setCheckedItemsType([...checkedItemsType, id]);
			}
			
		} else {
			setCheckedItemsType(checkedItemsType.filter((item) => item !== id));
		}
	};
	const handleCheckboxChangeGroup = (event, id) => {
		if (event.target.checked) {
			if(id==0){
				setCheckedItemsGroup([0]);
			} else {
				setCheckedItemsGroup([...checkedItemsGroup, id]);
			}
			
		} else {
			setCheckedItemsGroup(checkedItemsGroup.filter((item) => item !== id));
		}
	};
	

	return (
		<div>
			<Box>
				<ToastContainer />
				<h1>FEED</h1>
					<Box>
					
					<Grid container spacing={0}>
						<Grid style={{border:'0px solid red'}} item lg={6} sm={6}>
							<div>
								<TextField
						          id="outlined-multiline-static"
						          label="FEED API URL"
						          rows={4}
						          fullWidth
						          style={{width:'100%'}}
						          variant="outlined"
						          value={pimcredentials.api_url}
						          defaultValue=" "
						          name="api_url"
						          placeholder="https://xxxxxxxxxxxx-feed.isysnet.no/"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	<br />
					       	<div>
								<TextField
						          id="outlined-multiline-static"
						          label="FEED API Username"
						          rows={4}
						          fullWidth
						          style={{width:'100%'}}
						          variant="outlined"
						          value={pimcredentials.api_username}
						          defaultValue=" "
						          name="api_username"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						        />
					       	</div>
					       	<br />
					       	<div>
						    	<TextField
						    		type="password"
						          id="outlined-multiline-static"
						          label="FEED API Password"
						          rows={4}
						          fullWidth
						          style={{width:'100%'}}
						          variant="outlined"
						          value={pimcredentials.api_password}
						           defaultValue=" "
						           name="api_password"
						          onChange={handleInputChange} 
						          onKeyUp={handleInputChange}
						          InputLabelProps={{
						            shrink: true,
						          }}
						          InputProps={{
							          startAdornment: (
							            <InputAdornment position="start">
							              <VpnKeyIcon />
							            </InputAdornment>
							          ),
							        }}
						        />
					       	</div>
					       	<Box>
					    		<div style={{float:'left', paddingTop: '5px'}}>
						    		<Button
							        variant="contained"
							        color="secondary"
							        size="large"
							        className={classes.saveButton}
							        startIcon={<SaveIcon />}
							        onClick={postData}
							      >
							        Save & VALIDATE
							      </Button>
							    </div>
							    <div style={{float:'left', paddingTop: '5px', marginLeft: '10px'}}>
							      { showProgress ? <Progress /> : null }
							      &nbsp;
							      <Button
							      	style={{display:'none'}}
							        variant="contained"
							        color="secondary"
							        size="large"
							        className={classes.saveButton}
							        startIcon={<VerifiedUserIcon />}
							        onClick={validatePIMCredentail}
							      >
							        VALIDATE
							      </Button>
							      
						    	</div>
					    	</Box>
				       	</Grid>
				    </Grid>
				    <Grid>
				    <div>
				      

				      
				    </div>
				    </Grid>
				    {filterAvailable ? 
				    <>
					<Grid container spacing={0} >
				    	<Grid style={{border:'0px solid red'}} item  lg={6} sm={6}>
				    		<Box>
							<h3>Media Filter Mechanism</h3>
									
							</Box>
							<div style={{float:'left', marginRight: '10px'}}>
								<FormControlLabel value="allproducts" label="All Products" control={<Checkbox className="checkbox-class" checked={isAllProductChecked} onChange={handleFilterChange} color="primary"  />} /> {isAllProductChecked}
							</div>
					       	<div style={{float:'left', marginRight: '10px'}}>
								<FormControlLabel value="owner" label="Product Owner" control={<Checkbox className="checkbox-class " checked={isOwnerChecked} onChange={handleFilterChange} color="primary"  />} />
							</div>
					       	<div style={{float:'left', marginRight: '10px'}}>
					       		<FormControlLabel value="status" label="Status" control={<Checkbox className="checkbox-class" checked={isStatusChecked} onChange={handleFilterChange} color="primary"  />} />
							</div>
						    <div style={{float:'left', marginRight: '10px'}}>
					       		<FormControlLabel value="type" label="Product Type" control={<Checkbox className="checkbox-class" checked={isTypeChecked} onChange={handleFilterChange} color="primary"  />} />
							
						    </div>
					       	<div style={{float:'left', marginRight: '10px'}}>
					       		<FormControlLabel value="group" label="Product Group" control={<Checkbox className="checkbox-class" checked={isGroupChecked} onChange={handleFilterChange} color="primary"  />} />
							
						    </div>
						    
						    <div style={{clear:'both'}}></div>
						    
						    {isOwnerChecked ? 
							<>
						    <fieldset>
	    						<legend>Product Owners</legend>
						       	<div id="product_onwers">
						       		<div style={{float:'left', marginRight: '10px'}}>
						       			
						       			<FormControlLabel value="0" label="All" checked={checkedItemsOwner.includes(0)} control={<Checkbox onChange={(event) => handleCheckboxChangeOwner(event, 0)} className="checkbox-class owner-checkbox" color="primary"  />} />
						       			
									</div>
									{productOwners.map((item, index) => (
										<div key={index} style={{float:'left', marginRight: '10px'}}>
											<>
									          <Checkbox
									          	color="primary"
									            checked={checkedItemsOwner.includes(item.id)}
									            onChange={(event) => handleCheckboxChangeOwner(event, item.id)}
									          />
									          {item.name}
									        </>	
								       </div> 
								      ))}
						       	
						       	</div>
					       	</fieldset>
					       	</>
					       	: ''}

					       	{isStatusChecked ? 
							<>
							<fieldset>
	    						<legend>Product Status</legend>
						       	<div id="product_types">
						       		<div style={{float:'left', marginRight: '10px'}}>
						       		
						       			<FormControlLabel value="0" label="All" checked={checkedItemsStatus.includes(0)} control={<Checkbox  onChange={(event) => handleCheckboxChangeStatus(event, 0)}  className="checkbox-class status-checkbox" color="primary"  />} />
									</div>
						       	{productStatus.map((item, index) => (
						       		<div key={index} style={{float:'left', marginRight: '10px'}}>
					       				<>
								          <Checkbox
								          	color="primary"
								            checked={checkedItemsStatus.includes(item.id)}
								            onChange={(event) => handleCheckboxChangeStatus(event, item.id)}
								          />
								          {item.name}
								        </>	
									</div>
						       		
						       	))}
						       	</div>
					       	</fieldset>

						    
							</>
					       	: ''}

					       	{isTypeChecked ? 
							<>
						    <fieldset>
	    						<legend>Product Types</legend>
						       	<div id="product_types">
						       		<div style={{float:'left', marginRight: '10px'}}>
						       		
						       			<FormControlLabel value="0" label="All" checked={checkedItemsType.includes(0)} control={<Checkbox  onChange={(event) => handleCheckboxChangeType(event, 0)}  className="checkbox-class type-checkbox" color="primary"  />} />
									</div>
						       	{productTypes.map((item, index) => (
						       		<div key={index} style={{float:'left', marginRight: '10px'}}>
						       			<>
								          <Checkbox
								          	color="primary"
								            checked={checkedItemsType.includes(item.id)}
								            onChange={(event) => handleCheckboxChangeType(event, item.id)}
								          />
								          {item.name}
								         </>	
									</div>
						       		
						       	))}
						       	</div>
					       	</fieldset>
							</>
					       	: ''}

					       	{isGroupChecked ? 
							<>
						    <fieldset>
	    						<legend>Product Groups</legend>
						       	<div id="product_types">
						       		<div style={{float:'left', marginRight: '10px'}}>
						       		
						       			<FormControlLabel value="0" label="All" checked={checkedItemsGroup.includes(0)} control={<Checkbox onChange={(event) => handleCheckboxChangeGroup(event, 0)} className="checkbox-class group-checkbox"  color="primary"  />} />
									</div>
						       	{productGroups.map((item, index) => (
						       		<div key={index} style={{float:'left', marginRight: '10px'}}>
						       			<>
								          <Checkbox
								          	color="primary"
								            checked={checkedItemsGroup.includes(item.id)}
								            onChange={(event) => handleCheckboxChangeGroup(event, item.id)}
								          />
								          {item.name}
								         </>
									</div>
						       		
						       	))}
						       	</div>
					       	</fieldset>
					       	</>
					       	: ''}

					       	<Box>
					    		<div style={{float:'left', paddingTop: '5px'}}>
						    	<Button
							        variant="contained"
							        color="secondary"
							        size="large"
							        className={classes.saveButton}
							        startIcon={<SaveIcon />}
							        onClick={handleButtonClick}
							      >
							        Save Filters
							      </Button>
							    </div>
				    			<div style={{float:'left', paddingTop: '5px', marginLeft: '10px'}}>
							      { showFilterProgress ? <Progress /> : null }
							      <Alert id="successalert" severity="success" style={{display: 'none'}} ></Alert>
						    	</div>
					    	</Box> 
					    </Grid>
				        
			    	</Grid>
			    	</>
			    	: '' }
			    	</Box> 
			    	
			</Box>
		</div>
	);
}

export default PIM;
